import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import './styles.scss';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	minWidth: 500,
	bgcolor: 'background.paper',
	boxShadow: 24,
    outline: 'none',
    borderRadius:'6px',
    // maxHeight: '100%',
    maxHeight: 'calc(100vh - 100px)',
    maxWidth: '90%',
    overflow: 'auto',
    // minHeight: '500px',
  };

const MMOdal = (props) => {

    const customStyle = props?.sx;
    
    return <Modal  {...props}  className={props?.className ? `${props.className} common-modal` : `common-modal`}>
        <Box sx={{...style, ...customStyle}}>
            { props.label && 
                <Box className="header d-flex justify-content-between border">
                    <Box className="label">{props.label}</Box>
                    {props.closeButton && <Box className="close" onClick={props.closeButton}><CloseIcon /></Box>}
            </Box>}
            <Box className="body">
                {props.children}
            </Box>
            {!props?.hidefooter && <Box className="footer d-flex justify-content-center">
                {props.closeButton &&<Button onClick={props.closeButton}>{props?.closeLabel ? props?.closeLabel : "Close"}</Button>}
                {props.okButton && <Button onClick={props.okButton} disabled={props?.okButtonDisabled ? true : false}>{props?.buttonLabel ? props?.buttonLabel : "OK"}</Button>}
            </Box>}
        </Box>
    </Modal>
};

export default MMOdal;