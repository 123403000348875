import CryptoJS from 'crypto-js';

const securityKey = 'jbgjbfgjkdfxmv jbvjfddm vv jkdhgdkfgndfv mfbgdflslkgfdn jkhgifhdfknsvfjf jbgjbfgjkdfxmv jbvjfddm vv jkdhgdkfgndfv mfbgdflslkgfdn jkhgifhdfknsvfjf';

export const setItem = (data, key) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), securityKey).toString();
    localStorage.setItem(key, ciphertext)
}

export const getItem = (key) => {
    if(typeof(localStorage.getItem(key)) !='undefined'){
        const ciphertext = localStorage.getItem(key);
        if(!ciphertext) return null;
        const bytes  = CryptoJS.AES.decrypt(ciphertext, securityKey);
        const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        return decryptedData;
    }
    return null;
}