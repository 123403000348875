import {
  GET_MATERIAL,
  GET_MATERIAL_EXPORT,
  GET_INDENT,
  GET_INDENT_DETAIL,
  GET_INDENT_FILTER_PROJECT,
  GET_INDENT_FILTER_ITEM,
  GET_INDENT_FOR_QUOTATION,
  GET_INDENT_ITEM_FOR_QUOTATION,
  GET_QUOTATION,
  GET_QUOTATION_DETAIL,
  GET_QUOTATION_FILTER_PROJECT,
  GET_QUOTATION_FILTER_ITEM,
  GET_QUOTATION_FILTER_VENDOR,
  GET_BRAND,
  GET_PURCHASE_ORDER_CHARGE_MASTER,
  GET_PROJECT_ADDRESS,
  GET_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_DETAIL,
  GET_PURCHASE_ORDER_FILTER_PROJECT,
  GET_PURCHASE_ORDER_FILTER_ENTITY,
  GET_PURCHASE_ORDER_FILTER_VENDOR,
  GET_PURCHASE_ORDER_FOR_GRN,
  GET_PURCHASE_ORDER_ITEM_FOR_GRN,
  GET_GRN,
  GET_GRN_DETAIL,
  GET_MATERIAL_DETAIL,
  GET_MATERIAL_BRAND,
  GET_CREDIT_DURATION,
  GET_GRN_EXPORT,
  GET_PURCHASE_ORDER_EXPORT,
  GET_INDENT_EXPORT,
  GET_QUOTATION_EXPORT,
  GET_INDENT_APPROVAL,
  GET_QUOTATION_APPROVAL,
  GET_QUOTATION_FOR_PURCHASE_ORDER,
  GET_PURCHASE_ORDER_APPROVAL,
  GET_PROJECT_MATERIAL_STOCK_EXPORT,
  GET_PROJECT_MATERIAL_STOCK,
  GET_CHECK_POINT,
  GET_GRN_CHECK_POINT_MATERIAL,
  GET_INDENT_FILTER_REQUESTED_BY,
  GET_PRC_GRN_CHECK_POINT_MATERIAL,
  GET_PROJECT_MATERIAL_REQUEST,
  GET_MATERIAL_REQUEST_INDENT_INFO,
  GET_STOCK_TRANSFER_MATERIAL,
  GET_PROJECT_MATERIAL_REQUEST_INDENT,
  GET_PROJECT_MATERIAL_REQUEST_DETAIL,
  GET_PROJECT_MATERIAL_REQUEST_FILTER_PROJECT,
  GET_PROJECT_MATERIAL_REQUEST_FILTER_PROCESS,
  GET_STOCK_TRANSFER,
  GET_PROJECT_MATERIAL_STOCK_TRANSFER,
  GET_DESTINATION_PROJECT,
  GET_SOURCE_PROJECT,
  GET_PROJECT_MATERIAL_STOCK_TRANSFER_DETAIL,
  GET_STOCK_TRANSFER_SOURCE_PROJECT_FILTER,
  GET_STOCK_TRANSFER_DESTINATION_PROJECT_FILTER,
  GET_STOCK_TRANSFER_MATERIAL_PROJECT_FILTER,
  GET_STOCK_TRANSFER_DESTINATION_PROJECT,
  GET_PROJECT_MATERIAL_STOCK_DESTINATION,
  GET_STOCK_TRANSFER_EXPORT,
  GET_PROJECT_MATERIAL_REQUEST_FILTER_MATERIAL,
  GET_PROJECT_MATERIAL_REQUEST_FILTER_TASK,
  GET_PURCHASE_ORDER_FOR_INVOICE,
  GET_GRN_FOR_PURCHASE_INVOICE,
} from "./types";

const initialStateChart = {
  labels: [],
  datasets: [],
};

const initialState = {
  getMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMaterialExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndent: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentFilterItem: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentForQuotation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentItemForQuotation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getQuotation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getQuotationDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getQuotationFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getQuotationFilterItem: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getQuotationFilterVendor: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBrand: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderChargeMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectAddress: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrder: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderFilterEntity: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderFilterVendor: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderForGrn: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderItemForGrn: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getGrn: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getGrnDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMaterialDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getMaterialBrand: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCreditDuration: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getPurchaseOrderExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getGrnExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getQuotationExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentApproval: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getQuotationApproval: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getQuotationForPurchaseOrder: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderApproval: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMaterialStock: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMaterialStockExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcGrnCheckPoint: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getPrcGrnCheckPointMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderApproval: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcGrnCheckPointMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentFilterRequestedBy: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcProjectMaterialRequest: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMaterialRequestIndentInfo: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockTransferMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcProjectMaterialRequestIndent: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcProjectMaterialRequestDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcProjectMaterialRequestFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcProjectMaterialRequestFilterProcessPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockTransfer: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMaterialStockQuantiy: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSourceProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDestinationProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockTransferDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockTransferSourceProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockTransferDestinationProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockTransferMaterialFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockTransferMaterialStockDestination: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockTransferExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcProjectMaterialRequestFilterMaterial:{
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPrcProjectMaterialRequestFilterTaskPlan:{
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseOrderForInvoice: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getGrnForPurchaseInvoice: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
};

const Procurement_reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_MATERIAL:
      return Object.assign({}, state, { getMaterial: payload });
    case GET_MATERIAL_EXPORT:
      return Object.assign({}, state, { getMaterialExport: payload });
    case GET_INDENT:
      return Object.assign({}, state, { getIndent: payload });
    case GET_INDENT_DETAIL:
      return Object.assign({}, state, { getIndentDetail: payload });
    case GET_INDENT_FILTER_PROJECT:
      return Object.assign({}, state, { getIndentFilterProject: payload });
    case GET_INDENT_FILTER_ITEM:
      return Object.assign({}, state, { getIndentFilterItem: payload });
    case GET_INDENT_FOR_QUOTATION:
      return Object.assign({}, state, { getIndentForQuotation: payload });
    case GET_INDENT_ITEM_FOR_QUOTATION:
      return Object.assign({}, state, { getIndentItemForQuotation: payload });
    case GET_QUOTATION:
      return Object.assign({}, state, { getQuotation: payload });
    case GET_QUOTATION_DETAIL:
      return Object.assign({}, state, { getQuotationDetail: payload });
    case GET_QUOTATION_FILTER_PROJECT:
      return Object.assign({}, state, { getQuotationFilterProject: payload });
    case GET_QUOTATION_FILTER_ITEM:
      return Object.assign({}, state, { getQuotationFilterItem: payload });
    case GET_QUOTATION_FILTER_VENDOR:
      return Object.assign({}, state, { getQuotationFilterVendor: payload });
    case GET_BRAND:
      return Object.assign({}, state, { getBrand: payload });
    case GET_PURCHASE_ORDER_CHARGE_MASTER:
      return Object.assign({}, state, {
        getPurchaseOrderChargeMaster: payload,
      });
    case GET_PROJECT_ADDRESS:
      return Object.assign({}, state, { getProjectAddress: payload });
    case GET_PURCHASE_ORDER:
      return Object.assign({}, state, { getPurchaseOrder: payload });
    case GET_PURCHASE_ORDER_DETAIL:
      return Object.assign({}, state, { getPurchaseOrderDetail: payload });
    case GET_PURCHASE_ORDER_FILTER_PROJECT:
      return Object.assign({}, state, {
        getPurchaseOrderFilterProject: payload,
      });
    case GET_PURCHASE_ORDER_FILTER_ENTITY:
      return Object.assign({}, state, {
        getPurchaseOrderFilterEntity: payload,
      });
    case GET_PURCHASE_ORDER_FILTER_VENDOR:
      return Object.assign({}, state, {
        getPurchaseOrderFilterVendor: payload,
      });
    case GET_PURCHASE_ORDER_FOR_GRN:
      return Object.assign({}, state, { getPurchaseOrderForGrn: payload });
    case GET_PURCHASE_ORDER_ITEM_FOR_GRN:
      return Object.assign({}, state, { getPurchaseOrderItemForGrn: payload });
    case GET_GRN:
      return Object.assign({}, state, { getGrn: payload });
    case GET_GRN_DETAIL:
      return Object.assign({}, state, { getGrnDetail: payload });
    case GET_MATERIAL_DETAIL:
      return Object.assign({}, state, { getMaterialDetail: payload });
    case GET_MATERIAL_BRAND:
      return Object.assign({}, state, { getMaterialBrand: payload });
    case GET_CREDIT_DURATION:
      return Object.assign({}, state, { getCreditDuration: payload });

    case GET_PURCHASE_ORDER_EXPORT:
      return Object.assign({}, state, { getPurchaseOrderExport: payload });
    case GET_GRN_EXPORT:
      return Object.assign({}, state, { getGrnExport: payload });
    case GET_INDENT_EXPORT:
      return Object.assign({}, state, { getIndentExport: payload });
    case GET_QUOTATION_EXPORT:
      return Object.assign({}, state, { getQuotationExport: payload });
    case GET_INDENT_APPROVAL:
      return Object.assign({}, state, { getIndentApproval: payload });
    case GET_QUOTATION_APPROVAL:
      return Object.assign({}, state, { getQuotationApproval: payload });
    case GET_QUOTATION_FOR_PURCHASE_ORDER:
      return Object.assign({}, state, {
        getQuotationForPurchaseOrder: payload,
      });
    case GET_PURCHASE_ORDER_APPROVAL:
      return Object.assign({}, state, { getPurchaseOrderApproval: payload });
    case GET_PROJECT_MATERIAL_STOCK:
      return Object.assign({}, state, { getProjectMaterialStock: payload });
    case GET_PROJECT_MATERIAL_STOCK_EXPORT:
      return Object.assign({}, state, {
        getProjectMaterialStockExport: payload,
      });
    case GET_CHECK_POINT:
      return Object.assign({}, state, { getPrcGrnCheckPoint: payload });
    case GET_GRN_CHECK_POINT_MATERIAL:
      return Object.assign({}, state, { getPrcGrnCheckPointMaterial: payload });
    case GET_PRC_GRN_CHECK_POINT_MATERIAL:
      return Object.assign({}, state, { getPrcGrnCheckPointMaterial: payload });
    case GET_INDENT_FILTER_REQUESTED_BY:
      return Object.assign({}, state, { getIndentFilterRequestedBy: payload });
    case GET_MATERIAL_REQUEST_INDENT_INFO:
      return Object.assign({}, state, {
        getMaterialRequestIndentInfo: payload,
      });
    case GET_STOCK_TRANSFER_MATERIAL:
      return Object.assign({}, state, { getStockTransferMaterial: payload });
    case GET_PROJECT_MATERIAL_REQUEST_INDENT:
      return Object.assign({}, state, {
        getPrcProjectMaterialRequestIndent: payload,
      });
    case GET_PROJECT_MATERIAL_REQUEST_DETAIL:
      return Object.assign({}, state, {
        getPrcProjectMaterialRequestDetail: payload,
      });
    case GET_PROJECT_MATERIAL_REQUEST_FILTER_PROJECT:
      return Object.assign({}, state, {
        getPrcProjectMaterialRequestFilterProject: payload,
      });
    case GET_PROJECT_MATERIAL_REQUEST_FILTER_PROCESS:
      return Object.assign({}, state, {
        getPrcProjectMaterialRequestFilterProcessPlan: payload,
      });
    case GET_STOCK_TRANSFER:
      return Object.assign({}, state, { getStockTransfer: payload });
    case GET_PROJECT_MATERIAL_STOCK_TRANSFER:
      return Object.assign({}, state, {
        getProjectMaterialStockQuantiy: payload,
      });

    case GET_SOURCE_PROJECT:
      return Object.assign({}, state, { getSourceProject: payload });
    case GET_DESTINATION_PROJECT:
      return Object.assign({}, state, { getDestinationProject: payload });

    case GET_PROJECT_MATERIAL_STOCK_TRANSFER_DETAIL:
      return Object.assign({}, state, { getStockTransferDetail: payload });
    //filter
    case GET_STOCK_TRANSFER_SOURCE_PROJECT_FILTER:
      return Object.assign({}, state, {
        getStockTransferSourceProjectFilter: payload,
      });
    case GET_STOCK_TRANSFER_DESTINATION_PROJECT_FILTER:
      return Object.assign({}, state, {
        getStockTransferDestinationProjectFilter: payload,
      });
    case GET_STOCK_TRANSFER_MATERIAL_PROJECT_FILTER:
      return Object.assign({}, state, {
        getStockTransferMaterialFilter: payload,
      });
    case GET_PROJECT_MATERIAL_STOCK_DESTINATION:
      return Object.assign({}, state, {
        getStockTransferMaterialStockDestination: payload,
      });

    case GET_STOCK_TRANSFER_EXPORT:
      return Object.assign({}, state, { getStockTransferExport: payload });
    case GET_PROJECT_MATERIAL_REQUEST_FILTER_MATERIAL:
      return Object.assign({}, state, {
        getPrcProjectMaterialRequestFilterMaterial: payload,
      });
    case GET_PROJECT_MATERIAL_REQUEST_FILTER_TASK:
      return Object.assign({}, state, {
        getPrcProjectMaterialRequestFilterTaskPlan: payload,
      });
    case GET_PROJECT_MATERIAL_REQUEST:
        return Object.assign({}, state, {
          getPrcProjectMaterialRequest: payload,
        });
        case GET_PURCHASE_ORDER_FOR_INVOICE:
          return Object.assign({}, state, { getPurchaseOrderForInvoice: payload });
          case GET_GRN_FOR_PURCHASE_INVOICE:
            return Object.assign({}, state, { getGrnForPurchaseInvoice: payload });
    default:
      return state;
  }
};

export default Procurement_reducer;
