import { ErpApi, ErpApiService,FileUploadService } from '../../utils/ApiService';
import {
    GET_BD_USER, GET_PROJECT_LIST, GET_PROJECT_AUTHORITY, ADD_TASK, GET_TASK_CATEGORY, GET_TASK, GET_TASK_EXPORT,
    GET_FEASIBILITY_REPORT_DETAIL, GET_TASK_DETAIL,
    GET_TASK_PROJECT_FILTER, GET_TASK_DEPARTMENT_FILTER, GET_TASK_CATEGORY_FILTER, GET_TASK_OWNER_FILTER,
    GET_USER_DESIGNATION, GET_USER_ROLE_FUNCTIONALITY, CREATE_USER_ROLE_FUNCTIONALITY, GET_PUBLIC_HOLIDAY_LIST,GET_YEAR,GET_SALARY_ADVANCE_DEDUCTION_HISTORY,GET_SALARY_ADVANCE_EXPORT,
    GET_SALARY_ADVANCE,GET_OUT_PASS,GET_EMPLOYEE,GET_OUT_PASS_EXPORT,GET_DEPARTMENT,GET_EMPLOYEE_ATTENDANCE,GET_LEAVE_TYPE,GET_EMPLOYEE_CHECKIN,GET_PETTY_CASH,GET_PETTY_CASH_MASTER_EXPORT,
    GET_LEAVE_SUMMARY,GET_COMP_OFF_LEAVE_SUMMARY,GET_VEHICLE_SERVICE_REQUEST,GET_VECHICLE_TYPE,GET_SERVICE_TYPE,GET_EMPLOYEE_ATTENDANCE_EXPORT,GET_VEHICLE_SERVICE_REQUEST_EXPORT,
    GET_VEHICLE_NUMBER,GET_GST,GET_ASSEST_TYPE,GET_DESIGNATION,GET_DESIGNATION_EXPORT,GET_DEPARTMENT_MASTER,GET_DEPARTMENT_EXPORT,GET_LEVEL,GET_LEVEL_EXPORT,GET_JOB_ROLE, GET_DEPARTMENT_DESIGNATION,
    GET_TASK_ACCOUNTABLE_FILTER,
    GET_APPRAISAL_QUESTION_EXPORT,
    GET_APPRAISAL_QUESTION_OPTION,
    GET_APPRAISAL_QUESTION_OPTION_EXPORT,
    GET_EMPLOYEE_APPRAISAL_QUESTION,
    GET_EMPLOYEE_APPRAISAL,
    GET_EMPLOYEE_APPRAISAL_EXPORT,
    GET_APPRAISAL_QUESTION,
    GET_APPRAISAL,
    GET_APPRAISAL_CATEGORY,
    GET_APPRAISAL_RATING,
    GET_QUESTION,
    GET_QUESTION_VALUE,
    GET_QUESTION_VALUE_SET,
    GET_APPRAISAL_QUESTION_PRINT,
    GET_HR_LEAVE_TYPE,
    GET_EMPLOYEE_APPRAISAL_QUESTION_PRINT,
    GET_TO_DO_CATEGORY,
    GET_HR_APPRAISAL_QUESTION_DESIGNATION_FOR_UPDATE,
    GET_HR_APPRAISAL_QUESTION_DESIGNATION,
    GET_APPRAISAL_RATING_EXPORT,
    GET_APPRAISAL_QUESTION_CATEGORY_FILTER,
    GET_APPRAISAL_QUESTION_DEPARTMENT_FILTER,
    GET_APPRAISAL_QUESTION_DESIGNATION_FILTER,
    GET_HR_APPRAISAL_QUESTION_OPTION,
    GET_HR_APPRAISAL_QUESTION_OPTION_DETAIL,
    GET_EMPLOYEE1,
    GET_EMPLOYEE_FOR_APPRAISAL,
    GET_EMPLOYEE_APPRAISAL_FILTER_EMPLOYEE,
    GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL,
    GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL1,
    GET_EMPLOYEE_DEPARTMENT_FOR_APPRAISAL,
    GET_EMPLOYEE_APPRAISAL_FILTER_DESGINATION,
    GET_EMPLOYEE_APPRAISAL_FILTER_DEPARTMENT
} from './types';
import { mainLoadingTrue, mainLoadingFalse, displayMessage } from '../User/action';
import { getItem } from '../../utils/crypto';
const userLocal = getItem('enuseloc');
const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['user_id'] : null;
export const getProjectAuthority_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "authorityID": "",
        "authorityStatus": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_AUTHORITY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getProjectAuthoritySuccess(data.projectAuthority))
            }
            else {
                dispatch(getProjectAuthoritySuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getProjectAuthoritySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getProjectAuthoritySuccess = (data) => {
    return {
        type: GET_PROJECT_AUTHORITY,
        payload: data
    }
}
export const getBdUser_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.BD_USER, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getBdSuccess(data.user))
            }
            else {
                dispatch(getBdSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getBdSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getBdSuccess = (data) => {
    return {
        type: GET_BD_USER,
        payload: data
    }
}
export const getProjectList_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "ownershipID":"",
        "projectPhaseID": "",
        "planningAuthorityID": "",
        "bdOwnerID": "",
        "legalOwnerID": "",
        "civilOwnerID": "",
        "projectStatus": "Active",
        "projectZoneID": "",
        "generalSearch": "",
        "sortOrder": "",
        "liaisonOwnerID":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.PROJECT_LIST, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getProjectListSuccess(data))
            }
            else {
                dispatch(getProjectListSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getProjectListSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getProjectListSuccess = (data) => {
    return {
        type: GET_PROJECT_LIST,
        payload: data
    }
}
export const createTask_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskTitle": "",
        "taskDescription": "",
        "departmentID": "",
        "categoryID": "",
        "projectID": "",
        "assignedToID": "",
        "assignedByID": "",
        "plannedStartDate":"",
        "plannedEndDate": "",
        "accountableID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.ADD_TASK, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}
export const createTaskSuccess = (data) => {
    return {
        type: ADD_TASK,
        payload: data
    }
}
export const getTask_action = (data) => {
    const initialPayload = {
      "userID": LoginuserId,
      "departmentID" : "",
      "accountableID":"",
      "projectID" : "",
      "categoryID" : "",
      "assignedToID" : "",
      "assignedByID":"",
      "statusID" : "",
      "generalSearch" : "",
      "sortOrder" :"" ,
      "iDisplayStart" : 0,
      "iDisplayLength" : -1,
      ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TASK, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            console.log("gettask" ,data);
            if (!data.error) {
                dispatch(getTaskSuccess(data))
            }
            else {
                dispatch(getTaskSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getTaskSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getTaskSuccess = (data) => {
    return {
        type: GET_TASK,
        payload: data
    }
}
export const getTaskCategory_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID" : "",
        "statusID" :"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TASK_CATEGORY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getTaskCategorySuccess(data))
            }
            else {
                dispatch(getTaskCategorySuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getTaskCategorySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getTaskCategorySuccess = (data) => {
    return {
        type: GET_TASK_CATEGORY,
        payload: data
    }
}
export const getTaskExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID" : "",
        "projectID" : "",
        "categoryID" : 0,
        "assignedToID" : "",
        "assignedByID":"",
        "statusID" : 0,
        "ownerType":0,
        "generalSearch" : "",
        "sortOrder" :"" ,
        "iDisplayStart" : 0,
        "iDisplayLength" : -1,
        ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_TASK, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getTaskExportSuccess(data));
          } else {
            dispatch(getTaskExportSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getTaskExportSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getTaskExportSuccess = (data) => {
    return {
      type: GET_TASK_EXPORT,
      payload: data,
    };
  };
  export const getFeasibilityReportDetail_action = (data) => {
    const initialPayload = {
        "userID":LoginuserId,
        "projectID":"",
        "feasibilityID":"",
        ...data 
    };
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_FEASIBILITY_REPORT_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getFeasibilityReportDetail_success(data))
            }
            else{
                dispatch(getFeasibilityReportDetail_success({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getFeasibilityReportDetail_success([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getFeasibilityReportDetail_success = (data) => {
    return {
        type : GET_FEASIBILITY_REPORT_DETAIL,
        payload : data
    }
}
export const updateGeneralTaskAssignedTo_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskID":"",
        "ownerID":"",
        "changeRemark":"",
        "ownerType":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_TASK_OWNER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}

export const updateTaskStart_action = (data) => {
  const initialPayload = {
      "userID": LoginuserId,
      ...data
  }
  return dispatch => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_TASK_START, initialPayload).then(response => {
          const {data}  = response;
          dispatch(mainLoadingFalse());
          if(!data.error)
          {
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
          else{
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
      })
      .catch(err => {
          dispatch(mainLoadingFalse());
      })
  }
}
export const updateTaskCompleted_action = (data) => {
    let initialPayload;
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        for(var i = 0 ; i < data.documents.length; i++) {
            initialPayload.append('documentFile'+data.documents[i].documentType+i, data.documents[i].documentFile);
        }
        initialPayload.append('taskDocuments', JSON.stringify(data.documents));
        initialPayload.append('documentRemark', data?.documentRemark);
        initialPayload.append('taskID', data.taskID);
    const config =  data?.documentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_TASK_COMPLETED, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const getTaskDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "taskID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TASK_DETAIL, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch({
                    type : GET_TASK_DETAIL,
                    payload : data.data
                })
            }
            else{
                dispatch({
                    type : GET_TASK_DETAIL,
                    payload : []
                })
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch({
                type : GET_TASK_DETAIL,
                payload : []
            })
            dispatch(mainLoadingFalse());
        })
    }
}
export const getDepartment_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID":"",
        "departmentID": "",
        "categoryID":"",
        "assignedToID":"",
        "statusID":"",
        // "generalSearch": "",
        // "sortOrder": "",
        // "iDisplayStart": "1",
        // "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TASK_DEPARTMENT_FILTER, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDepartmentSuccess(data))
            }
            else {
                dispatch(getDepartmentSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDepartmentSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getDepartmentSuccess = (data) => {
    return {
        type: GET_TASK_DEPARTMENT_FILTER,
        payload: data
    }
}
export const getTaskProjectFilter_action = (data) => {
    const initialPayload = {
      "userID": LoginuserId,
      "departmentID":"",
      "accountableID":"",
      "projectID":"",
      "categoryID":"",
      "assignedToID":"",
      "statusID":"",
      ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TASK_PROJECT_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getTaskProjectFilterSuccess(data))
            }
            else{
                dispatch(getTaskProjectFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getTaskProjectFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getTaskProjectFilterSuccess = (data) => {
    return {
        type : GET_TASK_PROJECT_FILTER,
        payload : data
    }
}
export const getTaskCategoryFilter_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "departmentID":"",
        "projectID":"",
        "categoryID":"",
        "assignedToID":"",
        "statusID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TASK_CATEGORY_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getTaskCategoryFilterSuccess(data))
            }
            else{
                dispatch(getTaskCategoryFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getTaskCategoryFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getTaskCategoryFilterSuccess = (data) => {
    return {
        type : GET_TASK_CATEGORY_FILTER,
        payload : data
    }
}
export const getTaskOwnerFilter_action = (data) => {
    const initialPayload = {
      "userID": LoginuserId,
      "departmentID":"",
      "projectID":"",
      "categoryID":"",
      "assignedToID":"",
      "accountableID":"",
      "statusID":"",
      ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_TASK_OWNER_FILTER, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error){
                dispatch(getTaskOwnerFilterSuccess(data))
            }
            else{
                dispatch(getTaskOwnerFilterSuccess({}))
            }
        })
        .catch(err => {
            console.log("err", err)
            dispatch(getTaskOwnerFilterSuccess([]))
            dispatch(mainLoadingFalse());
        })
    }
}
export const getTaskOwnerFilterSuccess = (data) => {
    return {
        type : GET_TASK_OWNER_FILTER,
        payload : data
    }
}
export const getUserDesignation_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_USER_DESIGNATION, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getUserDesignationSuccess(data))
            }
            else {
                dispatch(getUserDesignationSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getUserDesignationSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getUserDesignationSuccess = (data) => {
    return {
        type: GET_USER_DESIGNATION,
        payload: data
    }
}
export const getUserRoleFunctionality_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "roleID": "",
        "status": "",
        "generalSearch": "",
        "sortOrder": "",
        "liaisonOwnerID":"",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_USER_ROLE_FUNCTIONALITY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getUserRoleFunctionalitySuccess(data))
            }
            else {
                dispatch(getUserRoleFunctionalitySuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getUserRoleFunctionalitySuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getUserRoleFunctionalitySuccess = (data) => {
    return {
        type: GET_USER_ROLE_FUNCTIONALITY,
        payload: data
    }
}
export const updateUserRoleFunctionality_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "status":"",
        "roleFunctionID":"",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_USER_ROLE_FUNCTIONALITY, initialPayload).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
}
export const createUserRoleFunctionality_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "roleID": "",
        "functionalityID": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_USER_ROLE_FUNCTIONALITY, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                // toast.success(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "success"
                }));
            }
            else {
                // toast.error(data.message);
                dispatch(displayMessage({
                    text: data.message,
                    type: "error"
                }));
            }
        })
            .catch(err => {
                dispatch(mainLoadingFalse());
            })
    }
}
export const createUserRoleFunctionalitySuccess = (data) => {
    return {
        type: CREATE_USER_ROLE_FUNCTIONALITY,
        payload: data
    }
}
export const getPublicHolidayList_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        statusID: "",
        year:"",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PUBLIC_HOLIDAY_LIST, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_PUBLIC_HOLIDAY_LIST,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_PUBLIC_HOLIDAY_LIST,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_PUBLIC_HOLIDAY_LIST,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};
export const getPublicHolidayListSuccess = (data) => {
    return {
        type: GET_PUBLIC_HOLIDAY_LIST,
        payload: data
    }
}
export const createPublicHolidayList_action = (data) => {
    let initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_PUBLIC_HOLIDAY_LIST, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(
                        displayMessage({
                            text: data.message,
                            type: "success",
                        })
                    );
                } else {
                    dispatch(
                        displayMessage({
                            text: data.message,
                            type: "success",
                        })
                    );
                }
            })
            .catch((err) => {
                dispatch(mainLoadingFalse());
            });
    };
};
export const updatePublicHolidayList_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_PUBLIC_HOLIDAY_LIST, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(
                        displayMessage({
                            text: data.message,
                            type: "success",
                        })
                    );
                } else {
                    dispatch(
                        displayMessage({
                            text: data.message,
                            type: "success",
                        })
                    );
                }
            })
            .catch((err) => {
                dispatch(mainLoadingFalse());
            });
    };
};


export const getYear_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_YEAR, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_YEAR,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_YEAR,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_YEAR,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};


export const getSalaryAdvanceMaster_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_SALARY_ADVANCE, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_SALARY_ADVANCE,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_SALARY_ADVANCE,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_SALARY_ADVANCE,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};

export const CreateSalaryAdvanceMaster_action  = (data) => {
    const initialPayload = {
      userID: LoginuserId,
  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.CREATE_SALARY_ADVANCE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            // toast.success(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            // toast.error(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };
  
  export const getSalaryAdvanceHistory_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_SALARY_ADVANCE_DEDUCTION_HISTORY, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_SALARY_ADVANCE_DEDUCTION_HISTORY,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_SALARY_ADVANCE_DEDUCTION_HISTORY,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_SALARY_ADVANCE_DEDUCTION_HISTORY,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};


export const getSalaryAdvanceExportMaster_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_SALARY_ADVANCE, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_SALARY_ADVANCE_EXPORT,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_SALARY_ADVANCE_EXPORT,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_SALARY_ADVANCE_EXPORT,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};

export const getOutPassHr_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_OUT_PASS, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getOutPassSuccess(data));
          } else {
            dispatch(getOutPassSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getOutPassSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getOutPassSuccess = (data) => {
    return {
      type: GET_OUT_PASS,
      payload: data,
    };
  };
  
  export const CreateOutPassHr_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.CREATE_OUT_PASS, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            // toast.success(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            // toast.error(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };
  
  export const UpdateOutPassHrApproval_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_OUT_PASS_APPROVAL, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            // toast.success(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            // toast.error(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };
  
  export const getEmployee_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      departmentID: "",
      generalSearch: "",
      sortOrder: "",
      employeeStatus:"",
      iDisplayStart: "0",
      iDisplayLength: "-1",
  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_EMPLOYEE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getEmployeeNames(data));
          } else {
            dispatch(getEmployeeNames({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getEmployeeNames([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getEmployeeNames = (data) => {
    return {
      type: GET_EMPLOYEE,
      payload: data,
    };
  };

  export const getOutPassHrExportMaster_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_OUT_PASS, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getOutPassSuccessExport(data));
          } else {
            dispatch(getOutPassSuccessExport({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getOutPassSuccessExport([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getOutPassSuccessExport = (data) => {
    return {
      type: GET_OUT_PASS_EXPORT,
      payload: data,
    };
  };

  export const getHrDepartment_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
    
  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_DEPARTMENT, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getHrDepartment(data));
          } else {
            dispatch(getHrDepartment({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getHrDepartment([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getHrDepartment = (data) => {
    return {
      type: GET_DEPARTMENT,
      payload: data,
    };
  };

  export const getEmployeeAttendance_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      employeeID:"",
      startDate:"",
      endDate:"",
      generalSearch:"",
      sortOrder:"",
      iDisplayStart:"",
      iDisplayLength:"",
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_EMPLOYEE_ATTENDANCE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getEmployeeAttendance(data));
          } else {
            dispatch(getEmployeeAttendance({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getEmployeeAttendance([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getEmployeeAttendance = (data) => {
    return {
      type: GET_EMPLOYEE_ATTENDANCE,
      payload: data,
    };
  };

  export const getLeaveType_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      generalSearch:"",
      sortOrder:"",
      iDisplayStart:0,
      iDisplayLength:-1,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_LEAVE_TYPE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getLeaveType(data));
          } else {
            dispatch(getLeaveType({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getLeaveType([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getLeaveType = (data) => {
    return {
      type: GET_LEAVE_TYPE,
      payload: data,
    };
  };

  export const getEmployeeCheckin_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_EMPLOYEE_CHECKIN, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_EMPLOYEE_CHECKIN,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_EMPLOYEE_CHECKIN,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_EMPLOYEE_CHECKIN,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};
export const UpdateSalaryAdvanceStatusMaster_action  = (data) => {
    const initialPayload = {
      userID: LoginuserId,
  
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_SALARY_ADVANCE_STATUS, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            // toast.success(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            // toast.error(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };

  export const getPettyCashList_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PETTY_CASH, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_PETTY_CASH,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_PETTY_CASH,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_PETTY_CASH,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};


export const getPettyCashListMasterExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_PETTY_CASH, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_PETTY_CASH_MASTER_EXPORT,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_PETTY_CASH_MASTER_EXPORT,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_PETTY_CASH_MASTER_EXPORT,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};

export const getEmployeeDepartment_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_DEPARTMENT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_DEPARTMENT,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_DEPARTMENT,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_DEPARTMENT,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};

export const getLeaveSummary_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_LEAVE_SUMMARY, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_LEAVE_SUMMARY,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_LEAVE_SUMMARY,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_LEAVE_SUMMARY,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCompOffLeaveSummary_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.GET_COMP_OFF_LEAVE_SUMMARY, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch({
                        type: GET_COMP_OFF_LEAVE_SUMMARY,
                        payload: data,
                    });
                } else {
                    dispatch({
                        type: GET_COMP_OFF_LEAVE_SUMMARY,
                        payload: [],
                    });
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch({
                    type: GET_COMP_OFF_LEAVE_SUMMARY,
                    payload: [],
                });
                dispatch(mainLoadingFalse());
            });
    };
};

export const getVehicleServiceRequest_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      startDate:"",
      endDate:"",
      vehicleType:"",
      requestedBy:"",
      statusID:"",
      generalSearch:"",
      sortOrder:"",
      iDisplayStart:0,
      iDisplayLength:-1,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_VEHICLE_SERVICE_REQUEST, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getVehicleServiceRequest(data));
          } else {
            dispatch(getVehicleServiceRequest({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getVehicleServiceRequest([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getVehicleServiceRequest = (data) => {
    return {
      type: GET_VEHICLE_SERVICE_REQUEST,
      payload: data,
    };
  };

  export const getVehicleType_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      generalSearch:"",
      sortOrder:"",
      iDisplayStart:0,
      iDisplayLength:-1,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_VECHICLE_TYPE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getVehicleType(data));
          } else {
            dispatch(getVehicleType({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getVehicleType([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getVehicleType = (data) => {
    return {
      type: GET_VECHICLE_TYPE,
      payload: data,
    };
  };

  export const getServiceType_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      status:"",
      generalSearch:"",
      sortOrder:"",
      iDisplayStart:0,
      iDisplayLength:-1,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_SERVICE_TYPE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getServiceType(data));
          } else {
            dispatch(getServiceType({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getServiceType([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getServiceType = (data) => {
    return {
      type: GET_SERVICE_TYPE,
      payload: data,
    };
  };

  export const getEmployeeAttendanceExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        employeeID:"",
        attendanceTypeID:"",
        startDate:"",
        endDate:"",
        generalSearch:"",
        sortOrder:"",
        "iDisplayStart" : 0,
        "iDisplayLength" : -1,
        ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_EMPLOYEE_ATTENDANCE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getEmployeeAttendanceExportSuccess(data));
          } else {
            dispatch(getEmployeeAttendanceExportSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getEmployeeAttendanceExportSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getEmployeeAttendanceExportSuccess = (data) => {
    return {
      type: GET_EMPLOYEE_ATTENDANCE_EXPORT,
      payload: data,
    };
  };



  export const getVehicleServiceRequestExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        startDate:"",
        endDate:"",
        vehicleType:"",
        requestedBy:"",
        statusID:"",
        generalSearch:"",
        sortOrder:"",
        "iDisplayStart" : 0,
        "iDisplayLength" : -1,
        ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_VEHICLE_SERVICE_REQUEST, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getVehicleServiceRequestExportSuccess(data));
          } else {
            dispatch(getVehicleServiceRequestExportSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getVehicleServiceRequestExportSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getVehicleServiceRequestExportSuccess = (data) => {
    return {
      type: GET_VEHICLE_SERVICE_REQUEST_EXPORT,
      payload: data,
    };
  };

  export const getVehicleNumber_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        "ownerType":"Employee",
        "assetTypeID":"",
        "ownerID": "",
        "status":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_VEHICLE_NUMBER, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getVehicleNumberSuccess(data));
          } else {
            dispatch(getVehicleNumberSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getVehicleNumberSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getVehicleNumberSuccess = (data) => {
    return {
      type: GET_VEHICLE_NUMBER,
      payload: data,
    };
  };

  export const createVehicleServiceRequest_action = (data) => {
    let initialPayload;
        initialPayload = new FormData();
        initialPayload.append('userID', LoginuserId);
        initialPayload.append('vehicleOwnerType', data.vehicleOwnerType);
        initialPayload.append('assetType', data.assetType);
        initialPayload.append('requestRemark', data.requestRemark);
        initialPayload.append('vehicleID', data.vehicleID);
        initialPayload.append('odometerReading', data.odometerReading);
        initialPayload.append('requestType', data.requestType);
        initialPayload.append('projectID', data.projectID);
        initialPayload.append('issueMode', data?.issueMode );
        initialPayload.append('actualAmount', data?.actualAmount);
        initialPayload.append('estimatedAmount', data?.estimatedAmount );
        initialPayload.append('issueCardID', data?.issueCardID);
    const config =  data?.panDocumentFile ? {
        headers: { 'content-type': 'multipart/form-data' }
    } : {};
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.CREATE_VEHICLE_SERVICE_REQUEST, initialPayload, config).then(response => {
            const {data}  = response;
            dispatch(mainLoadingFalse());
            if(!data.error)
            {
                // dispatch(getInsertedLandBrokerId(data.data));
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
            else{
                dispatch(displayMessage({
                    text : data.message,
                    type : "success"
                }));
            }
        })
        .catch(err => {
            dispatch(mainLoadingFalse());
        })
    }
  }


  export const getGst_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_GST, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getGstSuccess(data));
          } else {
            dispatch(getGstSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getGstSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getGstSuccess = (data) => {
    return {
      type: GET_GST,
      payload: data,
    };
  };



  export const getAssetType_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        "status":"",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_ASSEST_TYPE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getAssetTypeSuccess(data));
          } else {
            dispatch(getAssetTypeSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getAssetTypeSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getAssetTypeSuccess = (data) => {
    return {
      type: GET_ASSEST_TYPE,
      payload: data,
    };
  };


  export const getDesignation_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      status: "",
      departmentID: "",
      generalSearch: "",
      sortOrder: "",
      iDisplayStart: 0,
      iDisplayLength: -1,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_DESIGNATION, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch({
              type: GET_DESIGNATION,
              payload: data,
            });
          } else {
            dispatch({
              type: GET_DESIGNATION,
              payload: [],
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch({
            type: GET_DESIGNATION,
            payload: [],
          });
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getDesignationSuccess = (data) => {
    return {
      type: GET_DESIGNATION,
      payload: data
    }
  }
  
  export const getDesignationExport_action = (data) => {
    const initialPayload = {
      "userID": LoginuserId,
      "departmentID": "",
      "status": "",
      "generalSearch": "",
      "sortOrder": "",
      "iDisplayStart": 0,
      "iDisplayLength": -1,
      ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_DESIGNATION, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getDesignationExportSuccess(data));
          } else {
            dispatch(getDesignationExportSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getDesignationExportSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getDesignationExportSuccess = (data) => {
    return {
      type: GET_DESIGNATION_EXPORT,
      payload: data,
    };
  };
  
  export const getDepartmentMaster_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      status: "",
      generalSearch: "",
      sortOrder: "",
      iDisplayStart: 0,
      iDisplayLength: -1,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_DEPARTMENT_MASTER, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch({
              type: GET_DEPARTMENT_MASTER,
              payload: data,
            });
          } else {
            dispatch({
              type: GET_DEPARTMENT_MASTER,
              payload: [],
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch({
            type: GET_DEPARTMENT_MASTER,
            payload: [],
          });
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getDepartmentMasterSuccess = (data) => {
    return {
      type: GET_DEPARTMENT_MASTER,
      payload: data
    }
  }
  
  export const createDesignation_action = (data) => {

    let initialPayload = new FormData();
    initialPayload.append("userID", LoginuserId);
    initialPayload.append("designationName", data?.designationName);
    initialPayload.append("departmentID", data?.departmentID);
    initialPayload.append("levelID", data?.levelID);
    initialPayload.append("documentFile", data?.documentFile);
    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.CREATE_DESIGNATION, initialPayload, config)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };
  
  export const updateDesignation_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      designationID: "",
      designationName : "",
      departmentID: "",
      status: "",
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_DESIGNATION, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            // toast.success(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            // toast.error(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };
  
  export const createDepartment_action = (data) => {
    let initialPayload = {
      userID: LoginuserId,
      departmentName: "",
     
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.CREATE_DEPARTMENT, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };
  
  export const updateDepartment_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      departmentID: "",
      departmentName: "",
        status: "",
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_DEPARTMENT, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            // toast.success(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            // toast.error(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };

    export const getDepartmentExport_action = (data) => {
    const initialPayload = {
      "userID": LoginuserId,
      "status": "",
      "generalSearch": "",
      "sortOrder": "",
      "iDisplayStart": 0,
      "iDisplayLength": -1,
      ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_DEPARTMENT_MASTER, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getDepartmentExportSuccess(data));
          } else {
            dispatch(getDepartmentExportSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getDepartmentExportSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getDepartmentExportSuccess = (data) => {
    return {
      type: GET_DEPARTMENT_EXPORT,
      payload: data,
    };
  };
  export const getLevel_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      status: "",
      generalSearch: "",
      sortOrder: "",
      iDisplayStart: 0,
      iDisplayLength: -1,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_LEVEL, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch({
              type: GET_LEVEL,
              payload: data,
            });
          } else {
            dispatch({
              type: GET_LEVEL,
              payload: [],
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch({
            type: GET_LEVEL,
            payload: [],
          });
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getLevelSuccess = (data) => {
    return {
      type: GET_LEVEL,
      payload: data
    }
  }
  
  export const createLevel_action = (data) => {
    let initialPayload = {
      userID: LoginuserId,
      levelName: "",
      levelOrder: "",
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.CREATE_LEVEL, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };
  
  export const updateLevel_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      levelID: "",
      levelName: "",
      levelOrder: "",
      status: "",
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_LEVEL, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            // toast.success(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "success",
              })
            );
          } else {
            // toast.error(data.message);
            dispatch(
              displayMessage({
                text: data.message,
                type: "error",
              })
            );
          }
        })
        .catch((err) => {
          dispatch(mainLoadingFalse());
        });
    };
  };
  
  export const getLevelExport_action = (data) => {
    const initialPayload = {
      "userID": LoginuserId,
      "status": "",
      "generalSearch": "",
      "sortOrder": "",
      "iDisplayStart": 0,
      "iDisplayLength": -1,
      ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_LEVEL, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getLevelExportSuccess(data));
          } else {
            dispatch(getLevelExportSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getLevelExportSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getLevelExportSuccess = (data) => {
    return {
      type: GET_LEVEL_EXPORT,
      payload: data,
    };
  };
  
  export const uploadJobRole_action = (data) => {
  
    let initialPayload = new FormData();
  
  
    initialPayload.append('userID', LoginuserId);
    // initialPayload.append('documentType',  data.documentType);
  
    initialPayload.append('designationID', data?.designationID);
    initialPayload.append("documentFile", data?.documentFile);
    const config = {
      headers: { 'content-type': 'multipart/form-data' }
    }
    return dispatch => {
      dispatch(mainLoadingTrue());
      return FileUploadService.post(ErpApi.UPLOAD_JOB_ROLE, initialPayload, config).then(response => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(displayMessage({
            text: data.message,
            type: "success"
          }));
        }
        else {
          dispatch(displayMessage({
            text: data.message,
            type: "success"
          }));
        }
      })
        .catch(err => {
          dispatch(mainLoadingFalse());
        })
    }
  }
  
  export const getJobRole_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,
      designationID: "",
      generalSearch: "",
      sortOrder: "",
      iDisplayStart: 0,
      iDisplayLength: -1,
      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_JOB_ROLE, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch({
              type: GET_JOB_ROLE,
              payload: data,
            });
          } else {
            dispatch({
              type: GET_JOB_ROLE,
              payload: [],
            });
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch({
            type: GET_JOB_ROLE,
            payload: [],
          });
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getJobRoleSuccess = (data) => {
    return {
      type: GET_JOB_ROLE,
      payload: data
    }
  }
  export const getDepartmentDesignation_action = (data) => {
    const initialPayload = {
      "userID": LoginuserId,
      "departmentID": "",
      "status": "",
      "generalSearch": "",
      "sortOrder": "",
      "iDisplayStart": 0,
      "iDisplayLength": -1,
      ...data
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_DEPARTMENT_DESIGNATION, initialPayload)
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getDepartmentDesignationSuccess(data));
          } else {
            dispatch(getDepartmentDesignationSuccess({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getDepartmentDesignationSuccess([]));
          dispatch(mainLoadingFalse());
        });
    };
  };
  export const getDepartmentDesignationSuccess = (data) => {
    return {
      type: GET_DEPARTMENT_DESIGNATION,
      payload: data,
    };
  };

  export const updateTaskStatus_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        taskID: "",
        statusID: "",
        updateRemark: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ErpApiService.post(ErpApi.UPDATE_TASK_STATUS, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(
                        displayMessage({
                            text: data.message,
                            type: "success",
                        })
                    );
                } else {
                    dispatch(
                        displayMessage({
                            text: data.message,
                            type: "success",
                        })
                    );
                }
            })
            .catch((err) => {
                dispatch(mainLoadingFalse());
            });
    };
};


export const updateTask_action = (data) => {
  const initialPayload = {
      "userID":LoginuserId,
      ...data
  }
  return dispatch => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_TASK, initialPayload).then(response => {
          const {data}  = response;
          dispatch(mainLoadingFalse());
          if(!data.error)
          {
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
          else{
              dispatch(displayMessage({
                  text : data.message,
                  type : "error"
              }));
          }
      })
      .catch(err => {
          dispatch(mainLoadingFalse());
      })
  }
}
export const getTaskAccountableFilter_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "projectID":"",
    "departmentID": "",
    "categoryID":"",
    "assignedToID": "",
    "accountableID": "",
    "statusID": "",
    "iDisplayStart": 0,
    "iDisplayLength": -1,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_TASK_ACCOUNTABLE_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getTaskAccountableFilterSuccess(data));
        } else {
          dispatch(getTaskAccountableFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getTaskAccountableFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getTaskAccountableFilterSuccess = (data) => {
  return {
    type: GET_TASK_ACCOUNTABLE_FILTER,
    payload: data,
  };
};

export const getAppraisalQuestion_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "status": "",
    "generalSearch": "",
    "sortOrder": "",
    "iDisplayStart": "0",
    "iDisplayLength": "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_QUESTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalQuestionSuccess(data));
        } else {
          dispatch(getAppraisalQuestionSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalQuestionSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalQuestionSuccess = (data) => {
  return {
    type: GET_APPRAISAL_QUESTION,
    payload: data,
  };
};
export const getAppraisalQuestionExport_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "status": "",
    "generalSearch": "",
    "sortOrder": "",
    "iDisplayStart": "0",
    "iDisplayLength": "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_QUESTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalQuestionExportSuccess(data));
        } else {
          dispatch(getAppraisalQuestionExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalQuestionExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalQuestionExportSuccess = (data) => {
  return {
    type: GET_APPRAISAL_QUESTION_EXPORT,
    payload: data,
  };
};


export const createAppraisalQuestion_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    displayOrder:"",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_APPRAISAL_QUESTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};





export const updateAppraisalQuestion_action = (data) => {
  const initialPayload = {
      "userID": LoginuserId,
      "appraisalID":"",
      "questionTitle":"",
      "description":"",
      "displayOrder":"",
      "status":"",
  
      ...data
  }
  return dispatch => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_APPRAISAL_QUESTION, initialPayload).then(response => {
          const {data}  = response;
          dispatch(mainLoadingFalse());
          if(!data.error)
          {
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
          else{
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
      })
      .catch(err => {
          dispatch(mainLoadingFalse());
      })
  }
}




export const getAppraisalQuestionOption_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "status": "",
    "generalSearch": "",
    "sortOrder": "",
    "iDisplayStart": "0",
    "iDisplayLength": "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_QUESTION_OPTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalQuestionOptionSuccess(data));
        } else {
          dispatch(getAppraisalQuestionOptionSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalQuestionOptionSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalQuestionOptionSuccess = (data) => {
  return {
    type: GET_APPRAISAL_QUESTION_OPTION,
    payload: data,
  };
};

export const getAppraisalQuestionOptionExport_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "status": "",
    "generalSearch": "",
    "sortOrder": "",
    "iDisplayStart": "0",
    "iDisplayLength": "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_QUESTION_OPTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalQuestionOptionExportSuccess(data));
        } else {
          dispatch(getAppraisalQuestionOptionExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalQuestionOptionExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalQuestionOptionExportSuccess = (data) => {
  return {
    type: GET_APPRAISAL_QUESTION_OPTION_EXPORT,
    payload: data,
  };
};


export const createAppraisalQuestionOption_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    displayOrder:"",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_APPRAISAL_QUESTION_OPTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};





export const updateAppraisalQuestionOption_action = (data) => {
  const initialPayload = {
      "userID": LoginuserId,
      "appraisalID":"",
      "questionTitle":"",
      "description":"",
      "displayOrder":"",
      "status":"",
  
      ...data
  }
  return dispatch => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_APPRAISAL_QUESTION_OPTION, initialPayload).then(response => {
          const {data}  = response;
          dispatch(mainLoadingFalse());
          if(!data.error)
          {
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
          else{
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
      })
      .catch(err => {
          dispatch(mainLoadingFalse());
      })
  }
}

export const getEmployeeAppraisalQuestion_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_APPRAISAL_QUESTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeAppraisalQuestionSuccess(data));
        } else {
          dispatch(getEmployeeAppraisalQuestionSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeAppraisalQuestionSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalQuestionSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_APPRAISAL_QUESTION,
    payload: data,
  };
};








export const getEmployeeAppraisal_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "employeeID":"",
    "departmentID":"",
    "designationID":"",
    "status": "",
    "generalSearch": "",
    "sortOrder": "",
    "iDisplayStart": "0",
    "iDisplayLength": "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_APPRAISAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeAppraisalSuccess(data));
        } else {
          dispatch(getEmployeeAppraisalSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeAppraisalSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_APPRAISAL,
    payload: data,
  };
};


export const getEmployeeAppraisalExport_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "employeeID":"",
    "departmentID":"",
    "designationID":"",
    "status": "",
    "generalSearch": "",
    "sortOrder": "",
    "iDisplayStart": "0",
    "iDisplayLength": "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_APPRAISAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeAppraisalExportSuccess(data));
        } else {
          dispatch(getEmployeeAppraisalExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeAppraisalExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalExportSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_APPRAISAL_EXPORT,
    payload: data,
  };
};
export const getAppraisal_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "status": "",
    "generalSearch": "",
    "sortOrder": "",
    "iDisplayStart": "0",
    "iDisplayLength": "10",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalSuccess(data));
        } else {
          dispatch(getAppraisalSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalSuccess = (data) => {
  return {
    type: GET_APPRAISAL,
    payload: data,
  };
};


export const createAppraisal_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    title:"",
    year:"",
    startDate:"",
    endDate:"",
    remarks:"",
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_APPRAISAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};


export const updateAppraisal_action = (data) => {
  const initialPayload = {
      "userID": LoginuserId,
      "appraisalID":"",
      "title":"",
      "year":"",
      "startDate":"",
      "endDate":"",
      "remarks":"",
      "status":"",
  
      ...data
  }
  return dispatch => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_APPRAISAL, initialPayload).then(response => {
          const {data}  = response;
          dispatch(mainLoadingFalse());
          if(!data.error)
          {
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
          else{
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
      })
      .catch(err => {
          dispatch(mainLoadingFalse());
      })
  }
}




export const createEmployeeAppraisal_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_EMPLOYEE_APPRAISAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};


export const updateEmployeeAppraisalQuestionAnswer_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_EMPLOYEE_APPRAISAL_QUESTION_ANSWER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateEmployeeAppraisalFinalSubmit_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_EMPLOYEE_APPRASIAL_FINAL_SUBMIT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};



export const createBooking_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_BOOKING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getAppraisalCategory_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
 
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalCategorySuccess(data));
        } else {
          dispatch(getAppraisalCategorySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalCategorySuccess = (data) => {
  return {
    type: GET_APPRAISAL_CATEGORY,
    payload: data,
  };
};



export const createAppraisalCategory_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_APPRAISAL_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};


export const updateAppraisalCategory_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APPRAISAL_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};





export const getAppraisalRating_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
 
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_RATING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalRatingSuccess(data));
        } else {
          dispatch(getAppraisalRatingSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalRatingSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalRatingSuccess = (data) => {
  return {
    type: GET_APPRAISAL_RATING,
    payload: data,
  };
};



export const createAppraisalRating_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_APPRAISAL_RATING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};




export const updateAppraisalRating_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_APPRAISAL_RATING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFeedbackQuestion_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
 
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_QUESTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getFeedbackQuestionSuccess(data));
        } else {
          dispatch(getFeedbackQuestionSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getFeedbackQuestionSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFeedbackQuestionSuccess = (data) => {
  return {
    type: GET_QUESTION,
    payload: data,
  };
};

export const createFeedbackQuestion_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_QUESTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateFeedbackQuestion_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_QUESTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFeedbackQuestionOptionValue_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
 
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_QUESTION_VALUE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getFeedbackQuestionOptionValueSuccess(data));
        } else {
          dispatch(getFeedbackQuestionOptionValueSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getFeedbackQuestionOptionValueSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFeedbackQuestionOptionValueSuccess = (data) => {
  return {
    type: GET_QUESTION_VALUE,
    payload: data,
  };
};

export const createFeedbackQuestionOptionValue_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_QUESTION_VALUE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateFeedbackQuestionOptionValue_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_QUESTION_VALUE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getFeedbackQuestionOptionValueSet_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
 
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_QUESTION_VALUE_SET, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getFeedbackQuestionOptionValueSetSuccess(data));
        } else {
          dispatch(getFeedbackQuestionOptionValueSetSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getFeedbackQuestionOptionValueSetSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getFeedbackQuestionOptionValueSetSuccess = (data) => {
  return {
    type: GET_QUESTION_VALUE_SET,
    payload: data,
  };
};

export const createFeedbackQuestionOptionValueSet_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_QUESTION_VALUE_SET, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const updateFeedbackQuestionOptionValueSet_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_QUESTION_VALUE_SET, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};

export const getAppraisalQuestionPrint_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
 
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_QUESTION_PRINT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalQuestionPrintSuccess(data));
        } else {
          dispatch(getAppraisalQuestionPrintSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalQuestionPrintSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalQuestionPrintSuccess = (data) => {
  return {
    type: GET_APPRAISAL_QUESTION_PRINT,
    payload: data,
  };
};


export const getHrLeaveType_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
 
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_HR_LEAVE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getHrLeaveTypeSuccess(data));
        } else {
          dispatch(getHrLeaveTypeSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getHrLeaveTypeSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getHrLeaveTypeSuccess = (data) => {
  return {
    type: GET_HR_LEAVE_TYPE,
    payload: data,
  };
};


export const createHrLeaveType_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_HR_LEAVE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};


export const updateHrLeaveType_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_HR_LEAVE_TYPE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalQuestionPrint_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_APPRAISAL_QUESTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeAppraisalQuestionPrintSuccess(data));
        } else {
          dispatch(getEmployeeAppraisalQuestionPrintSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeAppraisalQuestionPrintSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalQuestionPrintSuccess = (data) => {
  return {
    type: GET_EMPLOYEE_APPRAISAL_QUESTION_PRINT,
    payload: data,
  };
};

export const getToDoCategory_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
 
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_TO_DO_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getToDoCategorySuccess(data));
        } else {
          dispatch(getToDoCategorySuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getToDoCategorySuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getToDoCategorySuccess = (data) => {
  return {
    type: GET_TO_DO_CATEGORY,
    payload: data,
  };
};


export const createToDoCategory_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.CREATE_TO_DO_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};


export const updateToDoCategory_action  = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.UPDATE_TO_DO_CATEGORY, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          // toast.success(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "success",
            })
          );
        } else {
          // toast.error(data.message);
          dispatch(
            displayMessage({
              text: data.message,
              type: "error",
            })
          );
        }
      })
      .catch((err) => {
        dispatch(mainLoadingFalse());
      });
  };
};



export const updateAppraisalQuestionDesignation_action = (data) => {
  const initialPayload = {
      "userID": LoginuserId,

  
      ...data
  }
  return dispatch => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.UPDATE_APPRAISAL_QUESTION_DESIGNATION, initialPayload).then(response => {
          const {data}  = response;
          dispatch(mainLoadingFalse());
          if(!data.error)
          {
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
          else{
              dispatch(displayMessage({
                  text : data.message,
                  type : "success"
              }));
          }
      })
      .catch(err => {
          dispatch(mainLoadingFalse());
      })
  }
}



export const getHrAppraisalQuestionDesignationForUpdate_action = (data) => {
  const initialPayload = {
      "userID": LoginuserId,
      "appraisalQuestionID": "",
      ...data
  }
  return dispatch => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_HR_APPRAISAL_QUESTION_DESIGNATION_FOR_UPDATE, initialPayload).then(response => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
              dispatch(getHrAppraisalQuestionDesignationForUpdateSuccess(data))
          }
          else {
              dispatch(getHrAppraisalQuestionDesignationForUpdateSuccess({}))
          }
      })
          .catch(err => {
              console.log("err", err)
              dispatch(getHrAppraisalQuestionDesignationForUpdateSuccess([]))
              dispatch(mainLoadingFalse());
          })
  }
}
export const getHrAppraisalQuestionDesignationForUpdateSuccess = (data) => {
  return {
      type: GET_HR_APPRAISAL_QUESTION_DESIGNATION_FOR_UPDATE,
      payload: data
  }
}


export const getHrAppraisalQuestionDesignation_action = (data) => {
  const initialPayload = {
      "userID": LoginuserId,
      "appraisalQuestionID": "",
      ...data
  }
  return dispatch => {
      dispatch(mainLoadingTrue());
      return ErpApiService.post(ErpApi.GET_HR_APPRAISAL_QUESTION_DESIGNATION, initialPayload).then(response => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
              dispatch(getHrAppraisalQuestionDesignationSuccess(data))
          }
          else {
              dispatch(getHrAppraisalQuestionDesignationSuccess({}))
          }
      })
          .catch(err => {
              console.log("err", err)
              dispatch(getHrAppraisalQuestionDesignationSuccess([]))
              dispatch(mainLoadingFalse());
          })
  }
}
export const getHrAppraisalQuestionDesignationSuccess = (data) => {
  return {
      type: GET_HR_APPRAISAL_QUESTION_DESIGNATION,
      payload: data
  }
}


export const getAppraisalRatingExport_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "status": "",
    "generalSearch": "",
    "sortOrder": "",
    "iDisplayStart": "0",
    "iDisplayLength": "-1",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_RATING, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalRatingExportSuccess(data));
        } else {
          dispatch(getAppraisalRatingExportSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalRatingExportSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalRatingExportSuccess = (data) => {
  return {
    type: GET_APPRAISAL_RATING_EXPORT,
    payload: data,
  };
};

export const getAppraisalQuestionCategoryFilter_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "categoryID":"",
    "departmentID":"",
    "designationID":"",
    "status":"",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_QUESTION_CATEGORY_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalQuestionCategoryFilterSuccess(data));
        } else {
          dispatch(getAppraisalQuestionCategoryFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalQuestionCategoryFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalQuestionCategoryFilterSuccess = (data) => {
  return {
    type: GET_APPRAISAL_QUESTION_CATEGORY_FILTER,
    payload: data,
  };
};

export const getAppraisalQuestionDepartmentFilter_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "categoryID":"",
    "departmentID":"",
    "designationID":"",
    "status":"",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_QUESTION_DEPARTMENT_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalQuestionDepartmentFilterSuccess(data));
        } else {
          dispatch(getAppraisalQuestionDepartmentFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalQuestionDepartmentFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalQuestionDepartmentFilterSuccess = (data) => {
  return {
    type: GET_APPRAISAL_QUESTION_DEPARTMENT_FILTER,
    payload: data,
  };
};


export const getAppraisalQuestionDesignationFilter_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "categoryID":"",
    "departmentID":"",
    "designationID":"",
    "status":"",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_APPRAISAL_QUESTION_DESIGNATION_FILTER, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getAppraisalQuestionDesignationFilterSuccess(data));
        } else {
          dispatch(getAppraisalQuestionDesignationFilterSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getAppraisalQuestionDesignationFilterSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getAppraisalQuestionDesignationFilterSuccess = (data) => {
  return {
    type: GET_APPRAISAL_QUESTION_DESIGNATION_FILTER,
    payload: data,
  };
};


export const getHrAppraisalQuestionOption_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "appraisalQuestionID":"",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_HR_APPRAISAL_QUESTION_OPTION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getHrAppraisalQuestionOptionSuccess(data));
        } else {
          dispatch(getHrAppraisalQuestionOptionSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getHrAppraisalQuestionOptionSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getHrAppraisalQuestionOptionSuccess = (data) => {
  return {
    type: GET_HR_APPRAISAL_QUESTION_OPTION,
    payload: data,
  };
};


export const getHrAppraisalQuestionOptionDetail_action = (data) => {
  const initialPayload = {
    "userID": LoginuserId,
    "optionID":"",
    ...data
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_HR_APPRAISAL_QUESTION_OPTION_DETAIL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getHrAppraisalQuestionOptionDetailSuccess(data));
        } else {
          dispatch(getHrAppraisalQuestionOptionDetailSuccess({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getHrAppraisalQuestionOptionDetailSuccess([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getHrAppraisalQuestionOptionDetailSuccess = (data) => {
  return {
    type: GET_HR_APPRAISAL_QUESTION_OPTION_DETAIL,
    payload: data,
  };
};




  
export const getEmployeeForAppraisal_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_FOR_APPRAISAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeForAppraisal(data));
        } else {
          dispatch(getEmployeeForAppraisal({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeForAppraisal([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeForAppraisal = (data) => {
  return {
    type: GET_EMPLOYEE_FOR_APPRAISAL,
    payload: data,
  };
};

export const getEmployeeAppraisalFilterEmployee_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_APPRAISAL_FILTER_EMPLOYEE, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeAppraisalFilterEmployee(data));
        } else {
          dispatch(getEmployeeAppraisalFilterEmployee({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeAppraisalFilterEmployee([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalFilterEmployee = (data) => {
  return {
    type: GET_EMPLOYEE_APPRAISAL_FILTER_EMPLOYEE,
    payload: data,
  };
};

export const getEmployeeAppraisalFilterAppraisal_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeAppraisalFilterAppraisal(data));
        } else {
          dispatch(getEmployeeAppraisalFilterAppraisal({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeAppraisalFilterAppraisal([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalFilterAppraisal = (data) => {
  return {
    type: GET_EMPLOYEE_APPRAISAL_FILTER_APPRAISAL,
    payload: data,
  };
};



export const getEmployeeDepartmentForAppraisal_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_DEPARTMENT_FOR_APPRAISAL, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeDepartmentForAppraisal(data));
        } else {
          dispatch(getEmployeeDepartmentForAppraisal({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeDepartmentForAppraisal([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeDepartmentForAppraisal = (data) => {
  return {
    type: GET_EMPLOYEE_DEPARTMENT_FOR_APPRAISAL,
    payload: data,
  };
};








export const getEmployeeAppraisalFilterDepartment_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_APPRAISAL_FILTER_DEPARTMENT, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeAppraisalFilterDepartment(data));
        } else {
          dispatch(getEmployeeAppraisalFilterDepartment({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeAppraisalFilterDepartment([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalFilterDepartment = (data) => {
  return {
    type: GET_EMPLOYEE_APPRAISAL_FILTER_DEPARTMENT,
    payload: data,
  };
};


export const getEmployeeAppraisalFilterDesgination_action = (data) => {
  const initialPayload = {
    userID: LoginuserId,
    ...data,
  };
  return (dispatch) => {
    dispatch(mainLoadingTrue());
    return ErpApiService.post(ErpApi.GET_EMPLOYEE_APPRAISAL_FILTER_DESGINATION, initialPayload)
      .then((response) => {
        const { data } = response;
        dispatch(mainLoadingFalse());
        if (!data.error) {
          dispatch(getEmployeeAppraisalFilterDesgination(data));
        } else {
          dispatch(getEmployeeAppraisalFilterDesgination({}));
        }
      })
      .catch((err) => {
        console.log("err", err);
        dispatch(getEmployeeAppraisalFilterDesgination([]));
        dispatch(mainLoadingFalse());
      });
  };
};
export const getEmployeeAppraisalFilterDesgination = (data) => {
  return {
    type: GET_EMPLOYEE_APPRAISAL_FILTER_DESGINATION,
    payload: data,
  };
};