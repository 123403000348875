//sadnand_gadwal

import {
    GET_BOOKING,
    GET_CUSTOMER_ORGANIZATION,
    GET_CUSTOMER_WORK_EXPERIENCE,
    GET_CUSTOMER_WORK_SECTOR,
    GET_CUSTOMER_INCOME,
    GET_CUSTOMER_WORK_FUNCTION,
    GET_ENQUIRY_SOURCE,
    GET_ENQUIRY_SOURCE_NEWSPAPER,
    GET_ENQUIRY_SOURCE_SOCIAL_MEDIA,
    GET_CUSTOMER_EMPLOYMENT_TYPE,
    GET_CUSTOMER_CURRENT_LIVING,
    GET_CUSTOMER_CRITICAL_FACTOR,
    GET_CUSTOMER_SUITABLE_TIME,
} from './types';
const initialStateChart = {
    labels: [],
    datasets: [],
};
const initialState = {

    getBooking: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCustomerOrganization: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCustomerWorkExperience: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCustomerWorkSector: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCustomerIncome: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCustomerWorkFunction: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    enquirySourceList: {
        error: false,
        message: "",
        data: [],
    },
    getEnquirySourceNewsPaper: [],
    getEnquirySourceSocialMedia: [],
    getCustomerEmploymentType: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCustomerCurrentLiving: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
    getCustomerCriticalFactor: [],
    getCustomerSuitableTime: {
        "error": false,
        "message": "",
        "data": [],
        "totalCount": 0
    },
}
const Sales_reducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_BOOKING:
            return Object.assign({}, state, { getBooking: payload });

        case GET_CUSTOMER_ORGANIZATION:
            return Object.assign({}, state, { getCustomerOrganization: payload });

        case GET_CUSTOMER_WORK_EXPERIENCE:
            return Object.assign({}, state, { getCustomerWorkExperience: payload });

        case GET_CUSTOMER_WORK_SECTOR:
            return Object.assign({}, state, { getCustomerWorkSector: payload });

        case GET_CUSTOMER_INCOME:
            return Object.assign({}, state, { getCustomerIncome: payload });

        case GET_CUSTOMER_WORK_FUNCTION:
            return Object.assign({}, state, { getCustomerWorkFunction: payload });

        case GET_ENQUIRY_SOURCE:
            return Object.assign({}, state, { enquirySourceList: payload });
        case GET_ENQUIRY_SOURCE_NEWSPAPER:
            return Object.assign({}, state, { getEnquirySourceNewsPaper: payload });
        case GET_ENQUIRY_SOURCE_SOCIAL_MEDIA:
            return Object.assign({}, state, { getEnquirySourceSocialMedia: payload });
        case GET_CUSTOMER_EMPLOYMENT_TYPE:
            return Object.assign({}, state, { getCustomerEmploymentType: payload });
        case GET_CUSTOMER_CURRENT_LIVING:
            return Object.assign({}, state, { getCustomerCurrentLiving: payload });
        case GET_CUSTOMER_CRITICAL_FACTOR:
            return Object.assign({}, state, { getCustomerCriticalFactor: payload });
        case GET_CUSTOMER_SUITABLE_TIME:
            return Object.assign({}, state, { getCustomerSuitableTime: payload });
        default:
            return state;
    }
}
export default Sales_reducer;