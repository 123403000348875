import React, {useState, useEffect} from 'react'
import logo from './logo.svg';
import './App.scss';
import './common.scss';
import MainRouter from './Components/Routing/MainRouter'
import {useSelector, useDispatch} from 'react-redux'
import {displayMessage} from './Redux/User'
import Snackbar from './Components/Alert'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { fetchToken, onMessageListener } from './Pages/firebase';

import { getItem, setItem } from "../src/utils/crypto";

import { getMyWebNotification_action} from "../src/Redux/BD/action";
import { Tooltip } from 'react-tooltip';
import SessionTimeout from './utils/SessionTimeout';
const userLocal = getItem("enuseloc");
const LoginuserId =
  userLocal && Object.keys(userLocal).length > 0 ? userLocal["user_id"] : null;

// console.log("LoginuserIdFromAppJs", LoginuserId);







function App() {
  const [alertText, setAlertText]= useState({text : null, type : "info"});
  const [loading, setLoading]= useState(true);
  const messageInfo = useSelector(state => state.user.messageInfo)
  const main_loader = useSelector(state => state.user.main_loader)
  const dispatch = useDispatch();

  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  // fetchToken(setTokenFound);




  useEffect(() => {
    
    if(LoginuserId){
      let data;
      async function tokenFunc() {
        data = await fetchToken(LoginuserId);
        if (data) {
          console.log("Token is", data);
        }
        return data;
      }
      tokenFunc();
    }
  }, [setTokenFound,LoginuserId]);




  onMessageListener().then(payload => {
    setNotification({title: payload.notification.title, body: payload.notification.body})
    setShow(true);
    console.log("msgListener",payload);
    setAlertText({text : payload?.notification?.title, type : "info"})
    dispatch(getMyWebNotification_action())
  }).catch(err => console.log('failed: ', err));

  const onShowNotificationClicked = () => {
    setNotification({title: "Notification", body: "This is a test notification"})
    setShow(true);
  }

  useEffect(()=>{
    if(messageInfo['text']){
        setAlertText(messageInfo)
    }else{
      setAlertText({text : null, type : "info"})
    }
  },[messageInfo])

  useEffect(()=>{
    if(alertText.text){
      setTimeout(()=>{
        dispatch(displayMessage({text : null, type : "info"}))
        setAlertText({text : null, type : "info"})
      }, 2000)
    }
  }, [alertText.text])

  useEffect(()=>{
    setTimeout(()=>{
      setLoading(main_loader)
    }, 1000)
  },[main_loader])

  const [isAuthenticated, setAuth] = useState(false);


  const handleClick = () => {
    setAuth(!isAuthenticated);
  }


  useEffect(()=>{
    if(LoginuserId){
      handleClick();
    }

  return () => {
    // dispatch({
    //   type: "SET_AUTH_USER",
    //   payload: "false",
    // });
    // setAuth(false);
    // setAuth(!isAuthenticated);
  }
  },[LoginuserId])


  return (
    <div className="App">
      {
        !!alertText.text && <Snackbar open={!!alertText.text} alertText={alertText}/>
      }
      {/* <Snackbar open={true} alertText={{text : "Material UI is a Material Design library made for React.It’s a set of React components that have Material Design styles. this article, we’ll look at how to add snack bars with Material UI.", type : "info"}}/> */}

      {loading &&  <div className={"loader"}>
            <CircularProgress  color="secondary" />
          </div>
      }


      <SessionTimeout isAuthenticated={isAuthenticated} logOut={handleClick} />
      <section>
        <MainRouter />
      </section>
      <Tooltip id="my-tooltip" place="right-end" maxWidth="20px" style={{width:'auto',maxWidth:'40%',zIndex:'100000000' }} offset={10}/>
    </div>
  );
}

export default App;
