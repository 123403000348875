
//sadnand_gadwal
export const GET_BOOKING = 'GET_BOOKING';
export const GET_CUSTOMER_ORGANIZATION="GET_CUSTOMER_ORGANIZATION";
export const GET_CUSTOMER_WORK_EXPERIENCE="GET_CUSTOMER_WORK_EXPERIENCE";
export const GET_CUSTOMER_WORK_SECTOR="GET_CUSTOMER_WORK_SECTOR";
export const GET_CUSTOMER_INCOME="GET_CUSTOMER_INCOME";
export const GET_CUSTOMER_WORK_FUNCTION="GET_CUSTOMER_WORK_FUNCTION";
export const GET_ENQUIRY_SOURCE = "GET_ENQUIRY_SOURCE";
export const GET_ENQUIRY_SOURCE_NEWSPAPER = "GET_ENQUIRY_SOURCE_NEWSPAPER";
export const GET_ENQUIRY_SOURCE_SOCIAL_MEDIA = "GET_ENQUIRY_SOURCE_SOCIAL_MEDIA";
export const GET_CUSTOMER_EMPLOYMENT_TYPE="GET_CUSTOMER_EMPLOYMENT_TYPE";
export const GET_CUSTOMER_CURRENT_LIVING="GET_CUSTOMER_CURRENT_LIVING";
export const GET_CUSTOMER_CRITICAL_FACTOR="GET_CUSTOMER_CRITICAL_FACTOR";
export const GET_CUSTOMER_SUITABLE_TIME="GET_CUSTOMER_SUITABLE_TIME";