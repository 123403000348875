import React, { useEffect } from 'react'
import Grid from '@mui/material/Grid'
import './styles.scss'
import CloseIcon from '@mui/icons-material/Close';
import { useDispatch, useSelector } from 'react-redux';
import { getMyWebNotification_action
        } from '../../Redux/BD/action';
import moment from 'moment';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Component = ({onClose}) => {
    const dispatch = useDispatch();
    const elementRef = React.createRef();
    const notificationList = useSelector((state) => state.bd.notificationList);
    // console.log("notificationList", notificationList);
    useEffect(()=>{
        dispatch(getMyWebNotification_action())
    }, [])

    // const handleScroll = () => {
    //     const position = window.pageYOffset;
    //     // setSrollPosition(position);
    //     console.log("position", position);
    // };


    // $("#chatBody").on("scroll", async()=> {
    //     if($("#chatBody").scrollTop() + 200 > $("#chatBody").height()) {
    //         console.log("can fetch the data")
    //         console.log(this.state.ticketfallowups.length, "> 0 && ",this.state.iDisplayStart ,"<", ticketfallowupsCount)
    //         if(this.state.ticketfallowups.length > 0 && this.state.iDisplayStart <= ticketfallowupsCount){
    //            await this.setState({iDisplayStart : this.state.iDisplayStart + 10 }, ()=>{ this.callInitialAPI(this.props.detailsData['customer_query_id'], this.state.iDisplayStart) })
    //         }
    //     }
    // });
    
    // useEffect(() => {
    //     debugger;
    //     var closeIcons = document.getElementsByClassName('scroll')
    //     closeIcons.addEventListener('scroll', handleScroll, { passive: true });
    // });




    const navigate = useNavigate();
    const redirectTo = (item) => {


        

        // _________________Task Plan_____________

        if(item?.notification_type === 'BD_TaskAssigned'){
            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for, notificationID:item?.notification_id}})

        }else if(item?.notification_type === 'BD_TaskCompleted'){
            navigate('/business-development/survey-no/approvals',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})

        }else if(item?.notification_type === 'BD_TaskApproved'){
            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})

        }else if(item?.notification_type === 'BD_TaskRejected'){
            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})
        }
        // _____________Pending__________________
        else if(item?.notification_type === 'BD_DependentTaskCompleted'){
            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})
        }

        
        // _____________Liaison Process Plan_______________

        else if(item?.notification_type === 'LIAISON_ProcessAssigned'){
            navigate('/business-development/liaison-process-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})
        }else if(item?.notification_type === 'LIAISON_ProcessCompleted'){
            navigate('/business-development/liaison-process-plan-approval/view',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})
        }else if(item?.notification_type === 'LIAISON_ProcessApproved'){
            navigate('/business-development/liaison-process-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})
        }else if(item?.notification_type === 'LIAISON_ProcessRejected'){
            navigate('/business-development/liaison-process-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})
        }
        // _____________Pending__________________
        
        else if(item?.notification_type === 'LIAISON_DependentTaskCompleted'){
            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})
        }

        
        // _____________Liaison Documentation_______________
        
        else if(item?.notification_type === 'DOCUMENT_TaskAssigned'){
            navigate('/business-development/liason-documentation/view',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})
        }else if(item?.notification_type === 'DOCUMENT_TaskCompleted'){
            navigate('/business-development/liason-documentation-approval/approval',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})
        }else if(item?.notification_type === 'DOCUMENT_TaskApproved'){
            navigate('/business-development/liason-documentation/view',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})
        }else if(item?.notification_type === 'DOCUMENT_TaskRejected'){
            navigate('/business-development/liason-documentation/view',{state:{fromNotification:true, notificationTaskPlanId:item?.notification_for,notificationID:item?.notification_id}})
        }
        // _____________Pending__________________
        else if(item?.notification_type === 'DOCUMENT_DependentTaskCompleted'){
            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})
        }



        //_____________ APF Query_______________
        
        else if(item?.notification_type === 'APF_TaskAssigned'){

            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})

        }else if(item?.notification_type === 'APF_Query_Assigned'){

            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})

        }else if(item?.notification_type === 'APF_Query_Replied'){

            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})

        }else if(item?.notification_type === 'APF_Query_Status'){

            navigate('/business-development/task-plan/view',{state:{fromNotification:true, notificationTaskPlanId:item?.task_plan_id,notificationID:item?.notification_id}})

        }
        
    
        onClose(false)
            
    }
    



  return (
    <div className='notification'>
        <div className='header d-flex justify-content-between align-items-end'>
            <h3 className='name'>Notification</h3>
            <div className='close' onClick={() => onClose(false)}><CloseIcon style={{cursor:'pointer'}} /></div>
        </div>
        <Grid container>
            <ul className='scroll' ref={elementRef}>
                {
                    notificationList?.data?.map(item =>{
                        return <>
                        <li key={item.notification_id}>
                            <div className='title'> {item.notification_title} </div>
                            <div className='noti-content'>{item.notification_description}</div>
                            <span><div className='withButton'>
                            <Button size="small" sx={{height:'23px',backgroundColor:'#505c67',textTransform:'none'}} variant="contained" endIcon={<OpenInNewIcon/>}  onClick={() => redirectTo(item)}  >Detail</Button>    
                            <div className='time'>{moment(item?.notification_on).fromNow()}</div></div></span>
                            
                        </li>
                        </>
                    })
                }
                
            </ul>
        </Grid>
    </div>
  )
}

export default Component;