import {
  GET_CIVIL_RESOURCE_PLANNING,
  GET_CIVIL_PROJECT_NAMES,
  GET_CIVIL_PROCESS_NAMES,
  GET_CIVIL_TASK_NAMES,
  GET_CIVIL_RESOURCE_NAMES,
  GET_CIVIL_RESOURCE_TYPE_PLANNING,
  GET_CIVIL_BUDGET_NAMES,
  GET_CIVIL_PROJECT,
  GET_CIVIL_PROJECT_PLANNIG_STAGE,
  GET_CIVIL_PROJECT_TEAM_ROLE,
  GET_CIVIL_PROJECT_PLAN_LIST,
  GET_CIVIL_PROJECT_PLAN_TEAM,
  GET_CIVIL_PROJECT_SITE_LOCATION,
  GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT,
  GET_CIVIL_WBS,
  GET_CIVIL_PROJECT_PROCESS_PLAN,
  GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS,
  GET_CIVIL_PROJECT_PROCESS_TASK,
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK,
  GET_TASK_DEPENDENCY_TYPE,
  GET_CIVIL_PROJECT_ROLE,
  GET_CIVIL_PROJECT_PLAN_FILTER_STAGE,
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN,
  GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY,
  GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY,
  GET_CIVIL_MATERIAL,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL,
  GET_CIVIL_PROJECT_ACTIVITY_MASTER,
  GET_CIVIL_PROJECT_TASK_UOM,
  GET_CIVIL_PROJECT_WBS,
  GET_CIVIL_PROJECT_TASK,
  GET_CIVIL_PROCESS_M,
  GET_CIVIL_RESOURCE,
  GET_CIVIL_RESOURCE_CATEGORY,
  GET_CIVIL_PROJECT_RESOURCE_1,
  GET_CIVIL_OBJECT_PRODUCTIVITY,
  GET_CIVIL_RESOURCE_ALL,
  GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE,
  GET_CIVIL_PROJECT_ACTIVITY_DETAIL,
  GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY,
  GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY,
  GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE,
  GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY,
  GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE,
  GET_CIVIL_PROJECT_RESOURCE_EXPORT,
  COPY_CIVIL_PROJECT_RESOURCE,
  GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT,
  COPY_CIVIL_PROJECT_PRODUCTIVITY,
  GET_CIVIL_RESOURCE_EXPORT,
  GET_CIVIL_PROJECT_PLAN_LIST_EXPORT,
  GET_CIVIL_PROJECT_TASK_UOM_EXPORT,
  GET_CIVIL_PROCESS_M_EXPORT,
  GET_CIVIL_PROJECT_TASK_EXPORT,
  GET_CIVIL_PROJECT_ROLE_EXPORT,
  GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT,
  GET_CIVIL_PROJECT_ACTIVITY_EXPORT,
  GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT,
  GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT,
  GET_CIVIL_PROJECT_BUDGET,
  GET_CIVIL_PROJECT_WBS_DETAIL,
  GET_CIVIL_PROJECT_PLAN_PENDING,
  GET_CIVIL_PROJECT_SCHEDULE,
  GET_CIVIL_RESOURCE_IDENTIFICATION,
  GET_CIVIL_MATERIAL_FOR_ACTIVITY,
  GET_CIVIL_PROJECT_ACTIVITY_GANTT_CHART,
  GET_CIVIL_PROJECT_PLAN_DETAIL,
  GET_CIVIL_PROJECT_PLAN_FROM_TASK_PLAN_DETAIL,
  GET_WORK_ORDER_PROCESS,
  GET_WORK_ORDER_PROCESS_EXPORT,
  GET_WORK_ORDER_TASK,
  GET_WORK_ORDER_TASK_EXPORT,
  GET_PROFITABILITY_REPORT,
  GET_WORK_ORDER_PROJECT,
  GET_COMPANY_PROJECT_MAP,
  GET_VENDOR_TYPE,
  GET_WORK_ORDER_FILTER_VENDOR,
  GET_WORK_ORDER,
  GET_WORK_ORDER_TERMS,
  GET_WORK_ORDER_DETAIL,
  GET_WORK_ORDER_PROJECT_WBS,
  GET_WORK_ORDER_PROJECT_ACTIVITY,
  GET_WORK_ORDER_TASK_FOR_ITEM,
  GET_WORK_ORDER_ITEM,
  GET_WORK_ORDER_DETAIL_PRINT,
  GET_WORK_ORDER_FILTER_PROJECT,
  GET_WORK_ORDER_EXPORT,
  GET_WORK_ORDER_CATEGORY,
  GET_WORK_ORDER_CATEGORY_EXPORT,
  GET_CIVIL_PROJECT_ACTIVITY_PROGRESS,
  GET_VENDOR_MACHINE,
  GET_VENDOR_MACHINE_EXPORT,
  GET_VENDOR_MACHINE_DETAIL,
  GET_PROJECT_MACHINE_VENDOR_MAP,
  GET_PROJECT_MACHINE_VENDOR_MAP_EXPORT,
  GET_PROJECT_MACHINE_VENDOE_MAP_DETAIL,
  GET_WORK_ORDER_TASK_PLAN_RESOURCE,
  GET_ACTUAL_ENTRY_TASK_PLAN_RESOURCE,
  GET_ACTUAL_ENTRY_VENDOR_MACHINE,
  GET_CIVIL_ACTUAL_ENTRY_MACHINE,
  GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL,
  GET_ACTIVITY_QUALITY_CHECK_POINT,
  GET_ACTIVITY_QUALITY_CHECK_POINT_EXPORT,
  GET_ACTUAL_REJECTION_REASON,
  GET_ACTUAL_REJECTION_REASON_EXPORT,
  GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR,
  GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE,
  GET_ACTUAL_ENTRY_VENDOR_LABOUR,
  GET_CIVIL_ACTUAL_ENTRY_LABOUR,
  GET_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL,
  GET_ACTUAL_ENTRY_VENDOR_CONTRACT,
  GET_CIVIL_ACTUAL_ENTRY_CONTRACT,
  GET_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL,
  GET_GST,
  GET_TDS,
  GET_WORK_ORDER_FILTER_COMPANY,
  GET_VENDOR_MACHINE_FILTER_VENDOR,
  GET_VENDOR_MACHINE_FILTER_MACHINE_TYPE,
  GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_VENDOR,
  GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_MACHINE_TYPE,
  GET_ITEM_GROUP,
  GET_ITEM_GROUP_EXPORT,
  GET_MATERIAL,
  GET_ASSET_TYPE,
  GET_STOCK_UOM,
  GET_ASSET_GROUP,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROJECT,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROCESS,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_TASK,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_RESOURCE,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROJECT,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROCESS,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_TASK,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_RESOURCE,
  GET_CIVIL_PROJECT_DRAWING,
  GET_CIVIL_PROJECT_DRAWING_FILTER,
  GET_PROJECT_DOCUMENT_TYPE,
  GET_CIVIL_WORK_INSPECTION,
  GET_CIVIL_WORK_INSPECTION_EXPORT,
  GET_CIVIL_WORK_INSPECTION_TASK_PLAN_FILTER_TASK,
  GET_CIVIL_WORK_INSPECTION_FILTER_PROCESS,
  GET_CIVIL_WORK_INSPECTION_FILTER_PROJECT,
  GET_CIVIL_WORK_INSPECTION_DETAIL,
  GET_CIVIL_QC_TRAINING,
  GET_CIVIL_QC_TRAINING_EXPORT,
  GET_CIVIL_QC_TRAINING_ATTENDEE,
  GET_CIVIL_QC_TRAINING_DETAIL,
  GET_CIVIL_QC_TRAINING_FILTER_PROJECT,
  GET_CIVIL_QC_TRAINING_FILTER_PROCESS,
  GET_CIVIL_QC_TRAINING_FILTER_TASK,
  GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAILS,
  GET_CIVIL_PROJECT_APPROVAL,
  GET_CIVIL_PROJECT_BILL,
  GET_CIVIL_PROJECT_BILL_DETAIL,
  GET_CIVIL_PROJECT_BILL_FILTER_PROJECT,
  GET_CIVIL_PROJECT_BILL_FILTER_COMPANY,
  GET_CIVIL_PROJECT_BILL_FILTER_VENDOR,
  GET_CIVIL_PROJECT_BILL_FILTER_CATEGORY,
  GET_CIVIL_PROJECT_PLAN_FILTER_PROJECTS1,
  GET_CIVIL_ACTUAL_ENTRY_FILTER_PROJECT,
  GET_CIVIL_ACTUAL_ENTRY_FILTER_WBS,
  GET_CIVIL_ACTUAL_ENTRY_FILTER_ACTIVITY,
  GET_CIVIL_PROJECT_ACTIVITY_FOR_ACTUAL_ENTRY,
  GET_CIVIL_PROJECT_WBS_FOR_ACTUAL_ENTRY,
  GET_CIVIL_PROJECT_FOR_ACTUAL_ENTRY,
  GET_CIVIL_SETTING,
  GET_CIVIL_SETTING_EXPORT,
  GET_CIVIL_PROJECT_SETTING,
  GET_CIVIL_PROJECT_SETTING_FILTER,
  GET_CIVIL_PROJECT_SETTING_DETAILS,
  GET_CIVIL_PROJECT_SETTING_EXPORT,
  GET_CIVIL_PROJECT_SETTING_FILTER_PROJECT,
  GET_CIVIL_PROJECT_SETTING_FILTER_CIVIL,
  GET_CIVIL_PROJECT_BILL_EXPORT,
} from "./types";

const initialStateChart = {
  labels: [],
  datasets: [],
};
const initialState = {
  civilResourcePlanning: initialStateChart,
  civilResourceTypePlanning: initialStateChart,
  civilBudgetPlanning: initialStateChart,
  getProjectNames: [],
  getProcessNames: [],
  getTaskNames: [],
  getResourceNames: [],
  // courtCasePendingReminderCount : 0,
  // pendingLiaisonReports : [],
  // pendingLiaisonReportsCount : 0,
  getCivilProjects: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectPlanningStage: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTeamRole: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectPlanList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectPlanTeam: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectSiteLocation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectPlanFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilWbs: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectProcessPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectProcessPlanFilterProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectProcessTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTaskDependencyType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectRole: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  createCivilProjectRole: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  updateCivilProjectRole: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectPlanFilterStage: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectProcessTaskPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectProcessTaskPlanLocationQuantity: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskMaterialCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectActivityMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskUOM: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectWbs: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResourceCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectResourceProductivity: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResourceAll: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectActivityDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectDependableActivity: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getCivilProjectResourceFilterResourceCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectResourceFilterResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectResourceProductivityFilterResourceCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectResourceProductivityFilterResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectResourceExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  copyCivilProjectResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectResourceProductivityExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  copyCivilResourceProductivity: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResourceExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectPlanListExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskUOMExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProcesslistExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectRoleExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanResourceExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectActivityExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanQuantityExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanCivilResourceExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectActivityBudget: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectWbsDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectPlanPending: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectSchedule: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResourceIdentification: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilMaterialForActivity: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectActivityGanttChart: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectPlanFromTaskPlanDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  doRefreshCivilActivityPage: false,
  deselectRowSelection: false,
  getCivilWorkOrderProcess: {
    error: false,
    message: "",
    data: [],
  },
  getCivilWorkOrderProcessExport: {
    error: false,
    message: "",
    data: [],
  },
  getCivilWorkOrderTask: {
    error: false,
    message: "",
    data: [],
  },
  getCivilWorkOrderTaskExport: {
    error: false,
    message: "",
    data: [],
  },
  getProfitabilityReport: {
    error: false,
    message: "",
    data: [],
  },
  //civil-work-order
  getWorkOrderProject: {
    error: false,
    message: "",
    data: [],
  },
  getCompanyProjectMap: {
    error: false,
    message: "",
    data: [],
  },
  getVendorType: {
    error: false,
    message: "",
    data: [],
  },
  getWorkOrderFilterVendor: {
    error: false,
    message: "",
    data: [],
  },
  getWorkOrder: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getWorkOrderTerms: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderProjectWbs: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderProjectActivity: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderTaskForItem: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderItem: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getCivilWorkOrderCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilWorkOrderCategoryExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectActivityProgress: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorMachine: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorMachineExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorMachineDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMachineVendorMap: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMachineVendorMapExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMachineVendorMapDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderTaskPlanResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getActualEntryTaskPlanResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getActualEntryVendorMachine: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getActualEntryMachine: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryMachineDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 1,
  },
  getActivityQualityCheckPoint: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getActivityQualityCheckPointExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getActualRejectionReason: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getActualRejectionReasonExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryFilterVendor: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryFilterMachine: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getActualEntryVendorLabour: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryLabour: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryLabourDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getActualEntryVendorContract: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryContract: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryContractDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getGst: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTds: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getWorkOrderFilterCompany: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorMachineFilterVendor: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorMachineFilterMachineType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMachineVendorMapFilterVendor: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMachineVendorMapFilterMachineType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getItemGroup: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getItemGroupExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAssetType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockUom: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAssetGroup: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanMaterialSchedule: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanMaterialFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getCivilProjectTaskPlanMaterialFilterProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getCivilProjectTaskPlanMaterialFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanMaterialFilterResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanMaterialScheduleFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanMaterialScheduleFilterProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanMaterialScheduleFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectTaskPlanMaterialScheduleFilterResource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectDrawing: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectsDrawing: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDrawingFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilWorkInspection: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilWorkInspectionExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectWorkInspectionFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilWorkInspectionProcessPlanFilterProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilWorkInspectionTaskPlanFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilWorkInspectionDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilQcTraining: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilQcTrainingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilQcTrainingAttendee: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilQcTrainingDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilQcTrainingFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilQcTrainingFilterProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilQcTrainingFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectApproval: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectBill: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectBillExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getcivilprojectbilldetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectBillFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectBillFilterCompany: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectBillFilterVendor: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectBillFilterCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryFilterWbs: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilActualEntryFilterActivity: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectForActualEntry: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectWbsForActualEntry: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectActivityForActualEntry: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilSetting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilSettingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectSetting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectSettingFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectSettingDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectSettingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectSettingDetails:{
    error: false,
    message: "",
    data: [],
    totalCount: 0,

  },
  getCivilProjectSettingFilterProject:{
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectSettingFilterCivilSetting:{
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
};

const Report_reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CIVIL_RESOURCE_PLANNING:
      return Object.assign({}, state, { civilResourcePlanning: payload });
    case GET_CIVIL_RESOURCE_TYPE_PLANNING:
      return Object.assign({}, state, { civilResourceTypePlanning: payload });
    case GET_CIVIL_PROJECT_NAMES:
      return Object.assign({}, state, { getProjectNames: payload });
    case GET_CIVIL_PROCESS_NAMES:
      return Object.assign({}, state, { getProcessNames: payload });
    case GET_CIVIL_TASK_NAMES:
      return Object.assign({}, state, { getTaskNames: payload });
    case GET_CIVIL_RESOURCE_NAMES:
      return Object.assign({}, state, { getResourceNames: payload });
    case GET_CIVIL_BUDGET_NAMES:
      return Object.assign({}, state, { civilBudgetPlanning: payload });
    case GET_CIVIL_PROJECT:
      return Object.assign({}, state, { getCivilProjects: payload });
    case GET_CIVIL_PROJECT_PLANNIG_STAGE:
      return Object.assign({}, state, {
        getCivilProjectPlanningStage: payload,
      });
    case GET_CIVIL_PROJECT_TEAM_ROLE:
      return Object.assign({}, state, { getCivilProjectTeamRole: payload });
    case GET_CIVIL_PROJECT_PLAN_LIST:
      return Object.assign({}, state, { getCivilProjectPlanList: payload });
    case GET_CIVIL_PROJECT_PLAN_TEAM:
      return Object.assign({}, state, { getCivilProjectPlanTeam: payload });
    case GET_CIVIL_PROJECT_SITE_LOCATION:
      return Object.assign({}, state, { getCivilProjectSiteLocation: payload });
    case GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCivilProjectPlanFilterProject: payload,
      });
    case GET_CIVIL_WBS:
      return Object.assign({}, state, { getCivilWbs: payload });
    case GET_CIVIL_PROJECT_PROCESS_PLAN:
      return Object.assign({}, state, { getCivilProjectProcessPlan: payload });
    case GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS:
      return Object.assign({}, state, {
        getCivilProjectProcessPlanFilterProcess: payload,
      });
    case GET_CIVIL_PROJECT_PROCESS_TASK:
      return Object.assign({}, state, { getCivilProjectProcessTask: payload });
    case GET_TASK_DEPENDENCY_TYPE:
      return Object.assign({}, state, { getTaskDependencyType: payload });
    case GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanFilterTask: payload,
      });
    case GET_CIVIL_PROJECT_ROLE:
      return Object.assign({}, state, { getCivilProjectRole: payload });
    case GET_CIVIL_PROJECT_PLAN_FILTER_STAGE:
      return Object.assign({}, state, {
        getCivilProjectPlanFilterStage: payload,
      });
    case GET_CIVIL_PROJECT_PROCESS_TASK_PLAN:
      return Object.assign({}, state, {
        getCivilProjectProcessTaskPlan: payload,
      });
    case GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY:
      return Object.assign({}, state, {
        getCivilProjectProcessTaskPlanLocationQuantity: payload,
      });
    case GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY:
      return Object.assign({}, state, {
        getCivilProjectTaskMaterialCategory: payload,
      });
    case GET_CIVIL_MATERIAL:
      return Object.assign({}, state, { getCivilMaterial: payload });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterial: payload,
      });
    case GET_CIVIL_PROJECT_ACTIVITY_MASTER:
      return Object.assign({}, state, {
        getCivilProjectActivityMaster: payload,
      });
    case GET_CIVIL_PROJECT_TASK_UOM:
      return Object.assign({}, state, { getCivilProjectTaskUOM: payload });
    case GET_CIVIL_PROJECT_WBS:
      return Object.assign({}, state, { getCivilProjectWbs: payload });
    case GET_CIVIL_PROJECT_TASK:
      return Object.assign({}, state, { getCivilProjectTask: payload });
    case GET_CIVIL_PROCESS_M:
      return Object.assign({}, state, { getCivilProcess: payload });
    case GET_CIVIL_RESOURCE:
      return Object.assign({}, state, { getCivilResource: payload });
    case GET_CIVIL_RESOURCE_CATEGORY:
      return Object.assign({}, state, { getCivilResourceCategory: payload });
    case GET_CIVIL_PROJECT_RESOURCE_1:
      return Object.assign({}, state, { getCivilProjectResource: payload });
    case GET_CIVIL_OBJECT_PRODUCTIVITY:
      return Object.assign({}, state, {
        getCivilProjectResourceProductivity: payload,
      });
    case GET_CIVIL_RESOURCE_ALL:
      return Object.assign({}, state, { getCivilResourceAll: payload });
    case GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanResource: payload,
      });
    case GET_CIVIL_PROJECT_ACTIVITY_DETAIL:
      return Object.assign({}, state, {
        getCivilProjectActivityDetail: payload,
      });
    case GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY:
      return Object.assign({}, state, {
        getCivilProjectDependableActivity: payload,
      });

    case GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY:
      return Object.assign({}, state, {
        getCivilProjectResourceFilterResourceCategory: payload,
      });
    case GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE:
      return Object.assign({}, state, {
        getCivilProjectResourceFilterResource: payload,
      });
    case GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY:
      return Object.assign({}, state, {
        getCivilProjectResourceProductivityFilterResourceCategory: payload,
      });
    case GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE:
      return Object.assign({}, state, {
        getCivilProjectResourceProductivityFilterResource: payload,
      });
    case GET_CIVIL_PROJECT_RESOURCE_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectResourceExport: payload,
      });
    case COPY_CIVIL_PROJECT_RESOURCE:
      return Object.assign({}, state, { copyCivilProjectResource: payload });
    case GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectResourceProductivityExport: payload,
      });
    case COPY_CIVIL_PROJECT_PRODUCTIVITY:
      return Object.assign({}, state, {
        copyCivilResourceProductivity: payload,
      });
    case GET_CIVIL_RESOURCE_EXPORT:
      return Object.assign({}, state, { getCivilResourceExport: payload });
    case GET_CIVIL_PROJECT_PLAN_LIST_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectPlanListExport: payload,
      });
    case GET_CIVIL_PROJECT_TASK_UOM_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectTaskUOMExport: payload,
      });
    case GET_CIVIL_PROCESS_M_EXPORT:
      return Object.assign({}, state, { getCivilProcesslistExport: payload });
    case GET_CIVIL_PROJECT_TASK_EXPORT:
      return Object.assign({}, state, { getCivilProjectTaskExport: payload });
    case GET_CIVIL_PROJECT_ROLE_EXPORT:
      return Object.assign({}, state, { getCivilProjectRoleExport: payload });
    case GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanResourceExport: payload,
      });
    case GET_CIVIL_PROJECT_ACTIVITY_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectActivityExport: payload,
      });
    case GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanQuantityExport: payload,
      });
    case GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanCivilResourceExport: payload,
      });
    case GET_CIVIL_PROJECT_BUDGET:
      return Object.assign({}, state, {
        getCivilProjectActivityBudget: payload,
      });
    case GET_CIVIL_PROJECT_WBS_DETAIL:
      return Object.assign({}, state, { getCivilProjectWbsDetail: payload });
    case GET_CIVIL_PROJECT_PLAN_PENDING:
      return Object.assign({}, state, { getCivilProjectPlanPending: payload });
    case GET_CIVIL_PROJECT_SCHEDULE:
      return Object.assign({}, state, { getCivilProjectSchedule: payload });
    case GET_CIVIL_RESOURCE_IDENTIFICATION:
      return Object.assign({}, state, {
        getCivilResourceIdentification: payload,
      });
    case GET_CIVIL_MATERIAL_FOR_ACTIVITY:
      return Object.assign({}, state, { getCivilMaterialForActivity: payload });
    case GET_CIVIL_PROJECT_ACTIVITY_GANTT_CHART:
      return Object.assign({}, state, {
        getCivilProjectActivityGanttChart: payload,
      });
    case GET_CIVIL_PROJECT_PLAN_DETAIL:
      return Object.assign({}, state, { getCivilProjectPlanDetail: payload });
    case GET_CIVIL_PROJECT_PLAN_FROM_TASK_PLAN_DETAIL:
      return Object.assign({}, state, {
        getCivilProjectPlanFromTaskPlanDetail: payload,
      });
    case "REFRESH_CIVIL_PROJECT_ACTIVITY":
      return Object.assign({}, state, {
        doRefreshCivilActivityPage: state?.doRefreshCivilActivityPage
          ? false
          : true,
      });
    case "DESELECT_ROW_CIVIL_PROJECT_ACTIVITY":
      return Object.assign({}, state, {
        deselectRowSelection: state?.deselectRowSelection ? false : true,
      });
    case GET_WORK_ORDER_PROCESS:
      return Object.assign({}, state, { getCivilWorkOrderProcess: payload });
    case GET_WORK_ORDER_PROCESS_EXPORT:
      return Object.assign({}, state, {
        getCivilWorkOrderProcessExport: payload,
      });
    case GET_WORK_ORDER_TASK:
      return Object.assign({}, state, { getCivilWorkOrderTask: payload });
    case GET_WORK_ORDER_TASK_EXPORT:
      return Object.assign({}, state, { getCivilWorkOrderTaskExport: payload });
    case GET_PROFITABILITY_REPORT:
      return Object.assign({}, state, { getProfitabilityReport: payload });
    //civil-work-order
    case GET_WORK_ORDER_PROJECT:
      return Object.assign({}, state, { getWorkOrderProject: payload });
    case GET_COMPANY_PROJECT_MAP:
      return Object.assign({}, state, { getCompanyProjectMap: payload });
    case GET_VENDOR_TYPE:
      return Object.assign({}, state, { getVendorType: payload });
    case GET_WORK_ORDER_FILTER_VENDOR:
      return Object.assign({}, state, { getWorkOrderFilterVendor: payload });
    case GET_WORK_ORDER:
      return Object.assign({}, state, { getWorkOrder: payload });
    case GET_WORK_ORDER_TERMS:
      return Object.assign({}, state, { getWorkOrderTerms: payload });
    case GET_WORK_ORDER_DETAIL:
      return Object.assign({}, state, { getWorkOrderDetail: payload });
    case GET_WORK_ORDER_PROJECT_WBS:
      return Object.assign({}, state, { getWorkOrderProjectWbs: payload });
    case GET_WORK_ORDER_PROJECT_ACTIVITY:
      return Object.assign({}, state, { getWorkOrderProjectActivity: payload });
    case GET_WORK_ORDER_TASK_FOR_ITEM:
      return Object.assign({}, state, { getWorkOrderTaskForItem: payload });
    case GET_WORK_ORDER_ITEM:
      return Object.assign({}, state, { getWorkOrderItem: payload });
    case GET_WORK_ORDER_DETAIL_PRINT:
      return Object.assign({}, state, { getWorkOrderDetailPrint: payload });
    case GET_WORK_ORDER_FILTER_PROJECT:
      return Object.assign({}, state, { getWorkOrderFilterProject: payload });
    case GET_WORK_ORDER_EXPORT:
      return Object.assign({}, state, { getWorkOrderExport: payload });
    case GET_WORK_ORDER_CATEGORY:
      return Object.assign({}, state, { getCivilWorkOrderCategory: payload });
    case GET_WORK_ORDER_CATEGORY_EXPORT:
      return Object.assign({}, state, {
        getCivilWorkOrderCategoryExport: payload,
      });
    case GET_CIVIL_PROJECT_ACTIVITY_PROGRESS:
      return Object.assign({}, state, {
        getCivilProjectActivityProgress: payload,
      });
    case GET_VENDOR_MACHINE:
      return Object.assign({}, state, { getVendorMachine: payload });
    case GET_VENDOR_MACHINE_EXPORT:
      return Object.assign({}, state, { getVendorMachineExport: payload });
    case GET_VENDOR_MACHINE_DETAIL:
      return Object.assign({}, state, { getVendorMachineDetail: payload });
    case GET_PROJECT_MACHINE_VENDOR_MAP:
      return Object.assign({}, state, { getProjectMachineVendorMap: payload });
    case GET_PROJECT_MACHINE_VENDOR_MAP_EXPORT:
      return Object.assign({}, state, {
        getProjectMachineVendorMapExport: payload,
      });
    case GET_PROJECT_MACHINE_VENDOE_MAP_DETAIL:
      return Object.assign({}, state, {
        getProjectMachineVendorMapDetail: payload,
      });
    case GET_WORK_ORDER_TASK_PLAN_RESOURCE:
      return Object.assign({}, state, {
        getWorkOrderTaskPlanResource: payload,
      });
    case GET_ACTUAL_ENTRY_TASK_PLAN_RESOURCE:
      return Object.assign({}, state, {
        getActualEntryTaskPlanResource: payload,
      });
    case GET_ACTUAL_ENTRY_VENDOR_MACHINE:
      return Object.assign({}, state, { getActualEntryVendorMachine: payload });
    case GET_CIVIL_ACTUAL_ENTRY_MACHINE:
      return Object.assign({}, state, { getActualEntryMachine: payload });
    case GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL:
      return Object.assign({}, state, {
        getCivilActualEntryMachineDetail: payload,
      });
    case GET_ACTIVITY_QUALITY_CHECK_POINT:
      return Object.assign({}, state, {
        getActivityQualityCheckPoint: payload,
      });
    case GET_ACTIVITY_QUALITY_CHECK_POINT_EXPORT:
      return Object.assign({}, state, {
        getActivityQualityCheckPointExport: payload,
      });
    case GET_ACTUAL_REJECTION_REASON:
      return Object.assign({}, state, { getActualRejectionReason: payload });
    case GET_ACTUAL_REJECTION_REASON_EXPORT:
      return Object.assign({}, state, {
        getActualRejectionReasonExport: payload,
      });
    case GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR:
      return Object.assign({}, state, {
        getCivilActualEntryFilterVendor: payload,
      });
    case GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE:
      return Object.assign({}, state, {
        getCivilActualEntryFilterMachine: payload,
      });
    case GET_ACTUAL_ENTRY_VENDOR_LABOUR:
      return Object.assign({}, state, { getActualEntryVendorLabour: payload });
    case GET_CIVIL_ACTUAL_ENTRY_LABOUR:
      return Object.assign({}, state, { getCivilActualEntryLabour: payload });
    case GET_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL:
      return Object.assign({}, state, {
        getCivilActualEntryLabourDetail: payload,
      });
    case GET_ACTUAL_ENTRY_VENDOR_CONTRACT:
      return Object.assign({}, state, {
        getActualEntryVendorContract: payload,
      });
    case GET_CIVIL_ACTUAL_ENTRY_CONTRACT:
      return Object.assign({}, state, { getCivilActualEntryContract: payload });
    case GET_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL:
      return Object.assign({}, state, {
        getCivilActualEntryContractDetail: payload,
      });
    case GET_GST:
      return Object.assign({}, state, { getGst: payload });
    case GET_TDS:
      return Object.assign({}, state, { getTds: payload });
    case GET_WORK_ORDER_FILTER_COMPANY:
      return Object.assign({}, state, { getWorkOrderFilterCompany: payload });
    case GET_VENDOR_MACHINE_FILTER_VENDOR:
      return Object.assign({}, state, {
        getVendorMachineFilterVendor: payload,
      });
    case GET_VENDOR_MACHINE_FILTER_MACHINE_TYPE:
      return Object.assign({}, state, {
        getVendorMachineFilterMachineType: payload,
      });
    case GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_VENDOR:
      return Object.assign({}, state, {
        getProjectMachineVendorMapFilterVendor: payload,
      });
    case GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_MACHINE_TYPE:
      return Object.assign({}, state, {
        getProjectMachineVendorMapFilterMachineType: payload,
      });
    case GET_MATERIAL:
      return Object.assign({}, state, { getMaterial: payload });
    case GET_ITEM_GROUP:
      return Object.assign({}, state, { getItemGroup: payload });
    case GET_ITEM_GROUP_EXPORT:
      return Object.assign({}, state, { getItemGroupExport: payload });
    case GET_ASSET_TYPE:
      return Object.assign({}, state, { getAssetType: payload });
    case GET_STOCK_UOM:
      return Object.assign({}, state, { getStockUom: payload });
    case GET_ASSET_GROUP:
      return Object.assign({}, state, { getAssetGroup: payload });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialSchedule: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialFilterProject: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROCESS:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialFilterProcess: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_TASK:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialFilterTask: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_RESOURCE:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialFilterResource: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialScheduleFilterProject: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROCESS:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialScheduleFilterProcess: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_TASK:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialScheduleFilterTask: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_RESOURCE:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialScheduleFilterResource: payload,
      });
    case GET_CIVIL_PROJECT_DRAWING:
      return Object.assign({}, state, { getCivilProjectDrawing: payload });
    case GET_CIVIL_PROJECT_DRAWING_FILTER:
      return Object.assign({}, state, { getCivilProjectsDrawing: payload });
    case GET_PROJECT_DOCUMENT_TYPE:
      return Object.assign({}, state, { getProjectDrawingFilter: payload });
    case GET_CIVIL_WORK_INSPECTION:
      return Object.assign({}, state, { getCivilWorkInspection: payload });
    case GET_CIVIL_WORK_INSPECTION_EXPORT:
      return Object.assign({}, state, {
        getCivilWorkInspectionExport: payload,
      });
    case GET_CIVIL_WORK_INSPECTION_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCivilProjectWorkInspectionFilterProject: payload,
      });
    case GET_CIVIL_WORK_INSPECTION_FILTER_PROCESS:
      return Object.assign({}, state, {
        getCivilWorkInspectionProcessPlanFilterProcess: payload,
      });
    case GET_CIVIL_WORK_INSPECTION_TASK_PLAN_FILTER_TASK:
      return Object.assign({}, state, {
        getCivilWorkInspectionTaskPlanFilterTask: payload,
      });
    case GET_CIVIL_WORK_INSPECTION_DETAIL:
      return Object.assign({}, state, {
        getCivilWorkInspectionDetail: payload,
      });
    case GET_CIVIL_QC_TRAINING:
      return Object.assign({}, state, { getCivilQcTraining: payload });
    case GET_CIVIL_QC_TRAINING_EXPORT:
      return Object.assign({}, state, { getCivilQcTrainingExport: payload });
    case GET_CIVIL_QC_TRAINING_ATTENDEE:
      return Object.assign({}, state, { getCivilQcTrainingAttendee: payload });
    case GET_CIVIL_QC_TRAINING_DETAIL:
      return Object.assign({}, state, { getCivilQcTrainingDetail: payload });
    case GET_CIVIL_QC_TRAINING_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCivilQcTrainingFilterProject: payload,
      });
    case GET_CIVIL_QC_TRAINING_FILTER_PROCESS:
      return Object.assign({}, state, {
        getCivilQcTrainingFilterProcess: payload,
      });
    case GET_CIVIL_QC_TRAINING_FILTER_TASK:
      return Object.assign({}, state, {
        getCivilQcTrainingFilterTask: payload,
      });
    case GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAILS:
      return Object.assign({}, state, {
        getCivilProjectTaskPlanMaterialScheduleDetail: payload,
      });
    case GET_CIVIL_PROJECT_APPROVAL:
      return Object.assign({}, state, {
        getCivilProjectApproval: payload,
      });
    case GET_CIVIL_PROJECT_BILL:
      return Object.assign({}, state, {
        getCivilProjectBill: payload,
      });
      case GET_CIVIL_PROJECT_BILL_EXPORT:
        return Object.assign({}, state, {
          getCivilProjectBillExport: payload,
        });
    case GET_CIVIL_PROJECT_BILL_DETAIL:
      return Object.assign({}, state, { getCivilProjectBillDetail: payload });
    case GET_CIVIL_PROJECT_BILL_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCivilProjectBillFilterProject: payload,
      });
    case GET_CIVIL_PROJECT_BILL_FILTER_COMPANY:
      return Object.assign({}, state, {
        getCivilProjectBillFilterCompany: payload,
      });
    case GET_CIVIL_PROJECT_BILL_FILTER_VENDOR:
      return Object.assign({}, state, {
        getCivilProjectBillFilterVendor: payload,
      });
    case GET_CIVIL_PROJECT_BILL_FILTER_CATEGORY:
      return Object.assign({}, state, {
        getCivilProjectBillFilterCategory: payload,
      });
    case GET_CIVIL_ACTUAL_ENTRY_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCivilActualEntryFilterProject: payload,
      });
    case GET_CIVIL_ACTUAL_ENTRY_FILTER_WBS:
      return Object.assign({}, state, {
        getCivilActualEntryFilterWbs: payload,
      });
    case GET_CIVIL_ACTUAL_ENTRY_FILTER_ACTIVITY:
      return Object.assign({}, state, {
        getCivilActualEntryFilterActivity: payload,
      });
    case GET_CIVIL_PROJECT_FOR_ACTUAL_ENTRY:
      return Object.assign({}, state, {
        getCivilProjectForActualEntry: payload,
      });
    case GET_CIVIL_PROJECT_WBS_FOR_ACTUAL_ENTRY:
      return Object.assign({}, state, {
        getCivilProjectWbsForActualEntry: payload,
      });
    case GET_CIVIL_PROJECT_ACTIVITY_FOR_ACTUAL_ENTRY:
      return Object.assign({}, state, {
        getCivilProjectActivityForActualEntry: payload,
      });
      case GET_CIVIL_SETTING:
        return Object.assign({}, state, {
          getCivilSetting: payload,
        });
      case GET_CIVIL_SETTING_EXPORT:
        return Object.assign({}, state, {
          getCivilSettingExport: payload,
        });
      case GET_CIVIL_PROJECT_SETTING:
        return Object.assign({}, state, {
          getCivilProjectSetting: payload,
        });
      case GET_CIVIL_PROJECT_SETTING_FILTER:
        return Object.assign({}, state, {
          getCivilProjectSettingFilter: payload,
        });
  
      case GET_CIVIL_PROJECT_SETTING_DETAILS:
        return Object.assign({}, state, {
          getCivilProjectSettingDetails: payload,
        });
  
      case GET_CIVIL_PROJECT_SETTING_EXPORT:
        return Object.assign({}, state, {
          getCivilProjectSettingExport: payload,
        });
  
      case GET_CIVIL_PROJECT_SETTING_FILTER_PROJECT:
        return Object.assign({}, state, {
          getCivilProjectSettingFilterProject: payload,
        });
      case GET_CIVIL_PROJECT_SETTING_FILTER_CIVIL:
        return Object.assign({}, state, {
          getCivilProjectSettingFilterCivilSetting: payload,
        });
        
    default:
      return state;
  }
};

export default Report_reducer;
