import {
  GET_PROJECT_AUTHORITY,
  GET_BD_USER,
  GET_PROJECT_PHASE,
  GET_AUTHORIZATION,
  GET_MENU_PERMISSION,
  GET_DISTRICT,
  GET_TALUK,
  GET_HOBLI,
  GET_PROJECT_BROKERS,
  GET_UPCOMING_PROJECT_LIST,
  GET_FUNCTIONALITY,
  GET_VILLAGE_LIST,
  GET_PROJECT_OWNERS_NAMES,
  GET_PROJECT_NAMES,
  GET_MY_WEB_NOTIFICATION,
  GET_PROJECT_TASK_STATUS,
  GET_PROJECT_DELAY_REASON,
  GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS,
  GET_PROJECT_TASK_PLAN_DETAILS,
  GET_PROJECT_PLAN,
  GET_PROJECT_FILE_LIST,
  GET_PROJECT_SURVEY_NO_TASK_APPROVAL,
  GET_LAND_OWNER_NAMES,
  GET_LIASON_PROCESS_PLAN,
  GET_LIASON_TASK_PLAN,
  GET_LIASON_DOCUMENT_TASK_PLAN,
  GET_PROJECT_LIST,
  GET_PROJECT_FLOW_LIST,
  GET_DOCUMENT_TYPES,
  GET_PROJECT_BD_OWNER,
  GET_PROJECT_LEGAL_OWNER,
  GET_PROJECT_PLAN_FILE_LIST,
  GET_LIAISON_CATEGORY_LIST,
  GET_LIAISON_PROCESS_LIST,
  GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT,
  GET_LAND_OWNER_LIST,
  GET_SURVEY_NO_LIST,
  GET_SURVEY_DETAIL_LIST,
  GET_FILE_ACQUISITION_INFO_LIST,
  GET_ACQUISITION_MODE_LIST,
  GET_ACQUISITION_INFO_DETAIL,
  GET_LIAISON_DOCUMENT_CATEGORY_LIST,
  GET_PROJECT_LIST_WITH_EXTENT,
  GET_FEASIBILITY_ITEM_LIST,
  GET_FEASIBILITY_REPORT_LIST,
  GET_FEASIBILITY_REPORT_DETAIL,
  GET_LAND_OWNER_DETAIL,
  GET_LAND_BROKER_LIST,
  GET_LAND_BROKER_DETAIL,
  GET_LAND_OWNNER_VILLAGE_LIST,
  GET_PROJECT_FUND_SOURCE_LIST,
  GET_PROJECT_FUND_REQUIREMENT_DETAIL,
  GET_PROJECT_FUND_LIST,
  GET_PROJECT_FUND_DETAIL,
  GET_PROJECT_FILE_PAYEMENT_DETAIL,
  GET_BD_PAYMENT_LIST,
  GET_BD_PAYMENT_DETAIL,
  GET_BD_PAYMENT_MILESTONE,
  GET_PROJECT_BROKER_LIST,
  GET_PROJECT_BROKER_DETAIL,
  GET_PROJECT_BROKER_PAYMENT_INFO,
  GET_PROJECT_LAND_OWNER,
  GET_PROJECT_OWNER_SURVEY_NUMBER_LIST,
  GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL,
  GET_LIAISON_PROCESS_PLAN_FILE,
  GET_LIASON_PROCESS_PLAN_APPLICATION,
  GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL,
  GET_PROJECTC_MILESTONE,
  GET_BD_REGISTRATION_ITEM,
  GET_SUBREGISTER_OFFICE,
  GET_PROJECT_FILE_DOCUMENT,
  GET_DEPENDENT_RELATIONSHIP,
  GET_BD_PAYMENT_ADVANCE_DETAIL,
  GET_PROJECT_DETAIL,
  GET_PROJECT_DROP_REASON_LIST,
  GET_COMPANY_PROJECT_MAP,
  GET_COMPANY,
  GET_DROPPED_PROJECT_LIST,
  GET_INSERTED_LAND_OWNER_DATA,
  GET_INSERTED_LAND_BROKER_DATA,
  GET_LAND_OWNER_ACCOUNT_DETAIL,
  GET_LAND_BROKER_ACCOUNT_DETAIL,
  GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST,
  GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST,
  GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST,
  GET_USER_PROFILE,
  UPDATE_EMPLOYEE_PROFILE,
  GET_BIRTHDAY,
  GET_EMPLOYEE_WORK_ANNIVERSARY,
  GET_PROJECT_LIST_FILTER,
  GET_PROJECT_UPCOMING_FILTER,
  GET_CIVIL_PROJECT_FILTER,
  GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL,
  GET_PROJECT_FILE_FILTER,
  GET_LIAISON_PROCESS_FILTER,
  GET_APF_PANEL,
  GET_APF_PENDING_PROJECT_FILE,
  GET_APF_TASK_PLAN,
  GET_APF_TASK_PLAN_DETAIL,
  GET_APF_TASK_PLAN_PROJECT_FILTER,
  GET_APF_TASK_PLAN_PANEL_FILTER,
  GET_APF_TASK_PLAN_PROJECT_FILE_FILTER,
  GET_APF_TASK_FILTER,
  GET_APF_TASK_PLAN_QUERY,
  GET_APF_TASK_PLAN_QUERY_DETAIL,
  GET_LIAISON_DOCUMENTATION_REPORT,
  GET_BD_LAND_DISPUTE_TYPE,
  GET_BD_LAND_DISPUTE_LIST,
  GET_BD_LAND_DISPUTE_DETAIL,
  GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER,
  GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER,
  GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER,
  GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER,
  GET_COURT_CASE_TYPE,
  GET_COURT_CASE_STATUS,
  GET_COURT_CASE_JURISDICTION,
  GET_DIRECTOR,
  GET_COURT_CASE,
  GET_COURT_CASE_DETAIL,
  GET_TRAINING_CONTENT,
  GET_BD_PROJECT_TASK_REJECTION_REASON,
  GET_BD_AGREEMENT_TYPE,
  GET_BD_PROJECT_REPORT,
  GET_BD_PROJECT_TASK_REPORT,
  GET_PROJECT_PROPOSED_NAME,
  GET_TRAINING,
  GET_APF_TASK_PLAN_TASK_FILTER,
  GET_LIAISON_PROCESS_PLAN_PROJECT,
  GET_LIAISON_PROCESS_PLAN_PROCESS,
  GET_LIAISON_PAYMENT_LIST,
  GET_LIAISON_PAYMENT_DETAIL,
  GET_LIAISON_PAYMENT_SURVEY_NUMBER,
  GET_LIAISON_PAYMENT_TASK_PLAN,
  GET_FUNCTIONALITY_PERMISSION_DETAIL,
  GET_LIAISON_PAYMENT_FILTER_PROJECT,
  GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY,
  GET_PROJECT_NAMES1,
  GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER,
  GET_PROJECT_TASK_PLAN_FILTER_TASK,
  GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING,
  GET_LIAISON_APPLICABLE_PROCESS_PLAN,
  GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT,
  GET_DEPARTMENT,
  GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL,
  GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL,
  GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY,
  GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1,
  GET_LIAISON_TASK_PLAN_DETAIL,
  GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL,
  GET_LIAISON_TASK,
  GET_LIAISON_PROCESS_PLAN_PENDING_TASK,
  GET_LIAISON_PROCESS,
  GET_LIAISON_PROCESS_CATEGORY1,
  GET_LIAISON_PROCESS_OPTIONAL,
  GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL,
  GET_LIAISON_PROCESS_PLAN,
  GET_LIAISON_PROCESS_PLAN_DETAIL,
  GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT,
  GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS,
  GET_BD_PROJECT_TASK_REPORT_EXPORT,
  GET_NEWS_LETTER,
  GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE,
  GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY,
  GET_LIAISON_PROCESS_PLAN_EXPORT,
  GET_PROJECT_ZONE,
  GET_LIAISON_PROCESS_PLAN_APPROVAL,
  GET_COURT_CASE_HANDLED_BY,
  GET_BD_LAND_DISPUTE_HANDLED_BY,
  GET_BD_LEGAL_TASK_LIST_EXPORT,
  GET_BD_LAND_DISPUTE_EXPORT,
  GET_COURT_CASE_EXPORT,
  GET_COURT_CASE_PROJECT_FILTER,
  GET_PROJECT_DEED,
  GET_BD_PROJECT_TASK,
  GET_BD_PROJECT_CONSOLIDATED_REPORT,
  GET_PROJECT_FILE_ACQUISITION_PENDING,
  GET_ACQUIRED_FILE_EXPORT,
  GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER,
  GET_FILE_ACQUISITION_INFO_EXPORT,
  GET_PROJECT_FILE_EXPORT,
  GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT,
  GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL,
  GET_BD_FILE_ACQUISITION_REASON,
  GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER,
  GET_PROJECT_LEGAL_OWNER_FILTER,
  GET_PROJECT_BD_OWNER_FILTER,
  GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON,
  GET_LOAN_PANEL,
  GET_LOAN_APPLICATION_ELIGIBLE_PROJECT,
  GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE,
  GET_LOAN_PROCESS_PLAN,
  GET_LOAN_TASK_PLAN,
  GET_LOAN_TASK_PLAN_DETAIL,
  GET_PROJECT_LIAISON_OWNER_FILTER,
  GET_LOAN_PROCESS_PLAN_DETAIL,
  GET_LOAN_PROCESS_PLAN_PROJECT_FILTER,
  GET_LOAN_PROCESS_PLAN_PROCESS_FILTER,
  GET_LOAN_PROCESS_PLAN_BANK_FILTER,
  GET_LOAN_PROCESS_PLAN_OWNER_FILTER,
  GET_LIAISON_PROCESS_PLAN_DELAYED,
  GET_LIAISON_PROCESS_PLAN_DELAYED_EXPORT,
  // BD Master
  GET_BD_LAND_DISPUTE_TYPE_MASTER,
  PROJECT_DEPENDENT_TASK_MASTER,
  GET_PLANNING_AUTHORITY_MASTER,
  GET_LIAISON_PROCESS_CATEGORY_MASTER,
  GET_BD_PROJECT_TASK_CATEGORY_MASTER,
  PROJECT_TASK_DEPENDENCY_MASTER,
  GET_LIAISON_CATEGORY_MASTER,
  GET_LIAISON_PROCESS_DEPENDENCY_MASTER,
  GET_BD_LAND_DISPUTE_TYPE_MASTER_EXPORT,
  GET_LIAISON_PROCESS_MASTER,
  GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW,
  GET_LIAISON_PROCESS_DETAIL,
  GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE_LIST,
  GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER,
  GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE,
  GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT,
  GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT,
  GET_BD_PAYMENT_MILESTONE_MASTER,
  GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_EXPORT,
  GET_PROJECT_MOU_REPORT_KPI,
  GET_PROJECT_MOU_REPORT_KPI_EXPORT,
  GET_PROJECT_TASK_REJECTION_REASON_MASTER,
  GET_AGREEMENT_TYPE_MASTER,
  GET_PROJECT_MOU_REPORT_KPI_FILTER_PROJECT,
  GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL_EXPORT,
  GET_COURT_ESTABLISHMENT,
  GET_COURT_CASE_STATUS_MASTER,
  GET_COURT_CASE_TYPE_MASTER,
  GET_REGISTRATION_FEES_RATE_LIST,
  GET_REGISTRATION_FEES_MASTER,
  GET_LIAISON_PROCESS_DEPENDENCY,
  GET_LIASION_PROCESSES_EXCEPTION,
  GET_APF_TASK_LIST,
  GET_APF_PANEL_LIST,
  GET_MASTER,
  GET_PROJECT_DOCUMENT_TYPE,
  GET_LIASISON_DELAY_REASON,
  GET_BANK,
  GET_BANK_CATEGORY,
  GET_LOAN_TASK,
  GET_LOAN_PROCESS,
  GET_LIAISON_PAYMENT_EXPORT,
  GET_PROJECT_TASK_TRANSACTION,
  GET_BD_TASK_APPROVAL,
  GET_PROJECT_EXPORT,
  GET_SUB_REGISTRAR_OFFICE,
  GET_SUB_REGISTRAR_MAPPING,
  GET_REGISTRATION_FEES_RATE_MASTER,
  GET_LIAISON_PROCESS_APPLICABILITY_REASON,
  GET_LANDLORD_MEETING,
  GET_LANDLORD_MEETING_STAGE,
  GET_LANDLORD_MEETING_PARTICULAR,
  GET_NEWS_LETTER_CATEGORY,
  GET_APF_TASK_PLAN_REPORT,
  GET_PROJECT_DOCUMENT_TYPE_EXPORT,
  GET_BANK_MASTER_EXPORT,
  GET_APF_PANEL_EXPORT,
  GET_LOAN_PROCESS_EXPORT,
  GET_LOAN_PANEL_EXPORT,
  GET_LIAISON_TASK_EXPORT,
  GET_LIAISON_PROCESS_MASTER_EXPORT,
  GET_BD_PROJECT_TASK_MASTER_EXPORT,
  GET_LIASISON_DELAY_REASON_MASTER_EXPORT,
  GET_REGISTRATION_FEES_RATE_EXPORT_MASTER,
  GET_AGREEMENT_TYPE_EXPORT_MASTER,
  GET_LIASION_PROCESSES_EXCEPTION_EXPORT,
  GET_PROJECT_TASK_REJECTION_REASON_EXPORT_MASTER,
  GET_BD_PAYMENT_MILESTONE_EXPORT_MASTER,
  GET_REGISTRATION_FEES_EXPORT_MASTER,
  GET_PLANNING_AUTHORITY_EXPORT_MASTER,
  PROJECT_TASK_DEPENDENCY_EXPORT_MASTER,
  GET_BD_PROJECT_TASK_CATEGORY_EXPORT_MASTER,
  GET_BD_TASK_EXPORT_APPROVAL,
  GET_PROJECT_DROP_REASON_EXPORT_LIST,
  GET_PROJECT_TASK_TRANSACTION_EXPORT,
  GET_SUB_REGISTRAR_MAPPING_EXPORT,
  GET_LOAN_TASK_EXPORT,
  GET_LIAISON_CATEGORY_EXPORT,
  GET_COURT_CASE_TYPE_EXPORT,
  GET_COURT_CASE_STATUS_EXPORT,
  GET_COURT_ESTABLISHMENT_EXPORT,
  GET_BD_LAND_DISPUTE_TYPE_EXPORT,
  GET_LIAISON_PROCESS_APPLICABILITY_REASON_EXPORT,
  GET_LIAISON_PROCESS_DEPENDENCY_EXPORT,
  GET_LIAISON_PROCESS_CATEGORY_EXPORT,
  GET_APF_TASK_LIST_EXPORT,
  GET_LAND_LORD_MEETING_PARTICULAR_REMARK,
  GET_LAND_LORD_MEETING_ATTENDEE,
  GET_LAND_LORD_MEETING_PARTICULAR_EXPORT,
  GET_LAND_LORD_MEETING_PARTICULAR,
  GET_LAND_LORD_MEETING_STAGE_MASTER,
  GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER,
  GET_LANDLORD_MEETING_DETAIL,
  GET_APF_TASK_PLAN_REPORT_EXPORT,
  GET_APF_TASK_PLAN_EXPORT,
  GET_APF_TASK_PLAN_QUERY_EXPORT,
  GET_LAND_LORD_MEETING_MOM,
  GET_LIAISON_PROCESS_DEPENDENT,
  GET_BD_PROJECT_DEED_TYPE,
  GET_LIAISON_PROCESS_PLAN_MERGER_FILE,
  GET_BD_LAND_DISPUTE_PRIORITY,
  GET_APF_TASK_PLAN_QUERY_TEMPLETE,
  GET_LIAISON_PROCESS_PLAN_EXCEPTION,
  GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY,
  GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY,
  GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER,
  GET_APF_TASK_PLAN_FILE,
  GET_LANDLORD_MEETING_DETAIL_PRINT,
  GET_APF_TASK_PLAN_QUERY_FILTER_FILE,
  GET_LIAISON_PROCESS_AUTHORITY_MAP,
  GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE,
  GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE,
  GET_APF_TASK_PLAN_OWNER_FILTER,
  GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER,
  GET_PROJECT_INSERTED_DATA,
  GET_PROJECT_ZONE_EXPORT,
  GET_LAND_LORD_MEETING_EXPORT,
  GET_LIAISON_PROCESS_FOR_EXCEPTION,
  GET_LANDLORD_MEETING_REPORT,
  GET_LANDLORD_MEETING_REPORT_DETAIL_PRINT,
  GET_PROJECT_FILE_FILTER_FOR_TEMPLATE,
  GET_PROJECT_CATEGORY,
  GET_BD_PROJECT_TASK_PROJECT_CATEGORY_MAP,
  GET_PROJECT_CATEGORY_EXPORT,
  GET_PROJECT_FILE_FILTER_PROJECT,
  GET_PROJECT_PRIORITY,
  GET_LAND_ACQUISITION_KPI_REPORT,
  GET_LAND_ACQUISITION_KPI_REPORT_EXPORT,
  GET_PROJECT_MOU_KPI_REPORT,
  GET_PROJECT_MOU_KPI_REPORT_EXPORT,
  GET_LAND_ACQUISITION_REPORT,
  GET_LAND_ACQUISITION_REPORT_EXPORT,
  GET_APF_TASK_PLAN_QUERY_REPORT,
  GET_APF_TASK_PLAN_QUERY_REPORT_EXPORT,
  GET_PROJECT_PHASE_MASTER,
  GET_PROJECT_PHASE_MASTER_EXPORT,
  GET_LAND_ACQUISITION_REPORT_ITEM,
  GET_LAND_ACQUISITION_REPORT_ITEM_EXPORT,
  GET_PO_REJECTION_REPORT,
  GET_PO_CYCLE_REPORT,
  GET_STOCK_PROJECT,
  GET_LAND_ACQUISITION_OVERALL_REPORT,
  GET_BD_TASK_APPROVAL_FILTER_USER,
  GET_BD_TASK_APPROVAL_FILTER_TASK,
  GET_BD_TASK_APPROVAL_TASK_TO_ADD,
  GET_PROJECT_TASK_FOR_OWNER_UPDATE,
  GET_PROJECT_DROPPED_FILTER,
  GET_LIASON_DOCUMENT_TASK_PLAN_APPLICABLE,
  GET_PROJECT_FLOW_LIST_EXPORT,
  GET_APF_TASK_EXTRA_FILE,
  GET_SUB_MENU,
  GET_PROJECT_DOCUMENT_REPOSITORY,
  GET_PROJECT_DOCUMENT_REPOSITORY_EXPORT,
  GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILTER,
  GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILE_FILTER,
  GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_DOCUMENT_TYPE,
  PROJECT_FILE_MOVE,
  TO_PROJECT_MOVE_FILE,
  GET_PROJECT_SUB_TASK_EXPORT,
  GET_PROJECT_SUB_TASK,
  GET_LIAISON_PROCESS_PLAN_KPI,
  GET_LIAISON_PROCESS_PLAN_KPI_EXPORT,
  GET_LIAISON_PROCESS_PLAN_EXPORT_DATA,
  GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_PROJECT,
} from "./types";
const initialStateChart = {
  labels: [],
  datasets: [],
};
const initialState = {
  projectAuthority: [],
  // civilResourceTypePlanning : initialStateChart,
  // civilBudgetPlanning : initialStateChart,
  user: [],
  projectPhase: [],
  districts: [],
  taluks: [],
  hobli: [],
  villages: [],
  landOwnerVillages: [],
  projectBrokers: [],
  projectList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  upComingProjectList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectFlowList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectOwners: [],
  projectNames: [],
  projectNames1: [],
  projectTaskStatus: [],
  projectTaskDelayReason: [],
  projectTaskDocumentType: [],
  ProjectTaskPlanDetail: [],
  projectPlans: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectFileList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liasonTaskPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liasonProcessPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectSurveyNoTaskApprovals: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  landOwnerNames: [],
  projectBdOwner: [],
  projectLegalOwner: [],
  liasonDocumentTaskPlan: [],
  notificationList: [],
  projectSurveyNoTaskPlanApprovalDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  authorizationList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  menuForPermissionList: [],
  getFunctionalityList: [],
  projectPlanFileList: [],
  liaisonCategoryList: [],
  fileLiaisonProcessList: [],
  taskPlanMandotoryDocumentList: [],
  //New
  landOwnerList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  surveyNumberList: [],
  fileAcquisitionInfoList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  fileAcquisitionModeList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  fileAcquisitionInfoDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectListWithExtent: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  feasibilityItemList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  feasibilityReportList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  feasibilityReportDetail: [],
  landOwnerDetail: [],
  landBrokerList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  landBrokerDetail: [],
  liaisonDocumentCategoryList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonDocumentCategoryList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectFundSourceList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectFundRequirementDetail: [],
  projectFundList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectFundDetail: [],
  projectFilePaymentDetail: [],
  bdPaymentList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdPaymentDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdPaymentMilestone: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectBrokerList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectBrokerDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectBrokerPaymentInfo: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectLandOwner: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectOwnerSurveyNoList: {
    error: false,
    message: "",
    projectFile: [],
  },
  projectFileAdvancePaymentDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanFile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liasonProcessPlanApplication: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanApplicationFileDetail: {
    error: false,
    message: "",
    data: [],
  },
  projectMilestone: {
    error: false,
    message: "",
    data: [],
  },
  bdRegistrationItem: {
    error: false,
    message: "",
    data: [],
  },
  subRegisterOffice: {
    error: false,
    message: "",
    data: [],
  },
  projectFileDocument: {
    error: false,
    message: "",
    data: [],
  },
  getDependentRelationship: {
    error: false,
    message: "",
    relationship: [],
  },
  bdPaymentAdvanceDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectDropReason: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  companyProjectMap: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectDetails: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  companyDetails: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  updateProjectCompany: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  droppedProjectList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  insertedLandOwnerData: "",
  insertedLandBrokerData: "",
  landOwnerAccountDetail: {
    error: false,
    message: "",
    data: [],
  },
  landBrokerAccountDetail: {
    error: false,
    message: "",
    data: [],
  },
  processPlanFilterOwners: [],
  processPlanApplicationFilterOwners: [],
  liaisonDocFilterOwners: [],
  userProfileDetails: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  updateEmployeeProfile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  GetBirthDay: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getAnniversary: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectListFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectUpcomingFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectFilter: {
    error: false,
    message: "",
    projectData: [],
    totalCount: 0,
  },
  liasonDocumentTaskPlanApproval: [],
  projectFileFilterList: [],
  liaisonProcessFilterList: [],
  apfPanelList: {
    error: false,
    message: "",
    data: [],
  },
  apfPendingProjectFileList: {
    error: false,
    message: "",
    data: [],
  },
  apfTaskPlanList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanDetail: {
    error: false,
    message: "",
    data: [],
  },
  apfTaskProjectFilterList: {
    error: false,
    message: "",
    data: [],
  },
  apfTaskPanelFilterList: {
    error: false,
    message: "",
    data: [],
  },
  apfTaskProjectFileFilterList: {
    error: false,
    message: "",
    data: [],
  },
  apfTaskFilterList: {
    error: false,
    message: "",
    data: [],
  },
  apfTaskPlanQueryList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanQueryDetail: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonDocumentationReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBDLandDisputeType: {
    error: false,
    message: "",
    data: [],
  },
  getBDLandDisputeList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdLandDisputeDetail: {
    error: false,
    message: "",
    data: [],
  },
  liaisonDocumentationReportProjectFilter: {
    error: false,
    message: "",
    data: [],
  },
  liaisonDocProjectFileFilter: {
    error: false,
    message: "",
    data: [],
  },
  liaisonDocReportCategoryFilter: {
    error: false,
    message: "",
    data: [],
  },
  courtCaseTypeList: {
    error: false,
    message: "",
    data: [],
  },
  courtCaseStatusList: {
    error: false,
    message: "",
    data: [],
  },
  courtCaseJurisdictionList: {
    error: false,
    message: "",
    data: [],
  },
  directorList: {
    error: false,
    message: "",
    data: [],
  },
  courtCaseList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  courtCaseDetail: {
    error: false,
    message: "",
    data: [],
  },
  courtCaseHandledBy: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  trainingContentList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdProjectTaskRejectionReasonList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdAgreementTypeList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdProjectReportList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdProjectTaskReportList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdProjectTaskReportExportData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectProposedNameList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getTraining: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  apfTaskPlanTaskFilterList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessPlanProject: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonProcessPlanProcess: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonPaymentList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonPaymentDetail: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonPaymentSurveyNumber: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonPaymentTaskPlan: {
    error: false,
    message: "",
    data: [],
  },
  getFunctionalityPermissionDetail: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonPaymentFilterProject: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonPaymentFilterRequestedBy: {
    error: false,
    message: "",
    data: [],
  },
  liaisonDocReportOwnerFilter: {
    error: false,
    message: "",
    data: [],
  },
  projectTaskPlanFilterTaskOwner: [],
  projectTaskPlanFilterTask: [],
  liasonDocumentTaskPlanPending: [],
  getLiaisonApplicableProcessPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liasonDocumentTaskPlanExport: [],
  departmentList: [],
  liaisonDocumentTaskPlanApprovalDetail: {
    error: false,
    message: "",
    data: [],
  },
  liaisonDocumentTaskPlanDetail: {
    error: false,
    message: "",
    data: [],
  },
  projectTaskPlanFilterTaskCategory: [],
  liaisonProcessPlanApplicationDetail1: {
    error: false,
    message: "",
    data: [],
  },
  liaisonTaskPlanDetail: {
    error: false,
    message: "",
    data: [],
  },
  liaisonProcessPlanPendingDetail: {
    error: false,
    message: "",
    data: [],
  },
  liaisonTasks: {
    error: false,
    message: "",
    data: [],
  },
  liaisonProcessPlanPendingTask: {
    error: false,
    message: "",
    data: [],
  },
  liaisonProcessList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessCategory1: [],
  liaisonProcessOptional: [],
  liaisonProcessSurveyNumberOptional: [],
  liaisonProcessPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanApproval: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanDetail: {
    error: false,
    message: "",
    data: [],
  },
  liaisonProcessPlanFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanFilterProcess: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  newsLatterList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessIdentificationPendingFile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessIdentificationPendingCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBDLegalTaskListExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  landDisputeExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  courtCaseListExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  courtCaseProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDeed: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdProjectConsolidatedReport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectFileAcquisitionPending: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getFileAcquisitionInfoExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectFileExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectFileAcquisitionPendingDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  bdFileAcquisitionReason: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectFileAcquisitionHandledByFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectLegalOwnerFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectBdOwnerFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanApplicabilityReason: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  loanPanel: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  loanApplicationEligibleProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  loanApplicationEligibleProjectFile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  loanProcessPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  loanTaskPlan: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  loanTaskPlanDetail: {
    error: false,
    message: "",
    data: [],
  },
  projectLiaisonOwnerFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  loanProcessPlanDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  loanProcessPlanProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanDelayed: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liaisonProcessPlanDelayedExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  // BD Master
  getBdLandDisputeMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdLandDisputeMasterExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiasionProcessCategoryMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  ProjectTaskCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  ProjectTaskDependency: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdProjectDependentTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPlanningAuthority: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiasionCategoryMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiasionProsessDependency: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcess1: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessCategory: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonProcessDetails: {
    error: false,
    message: "",
    data: [],
  },
  processPlanFilterAccountable: [],
  processPlanFilterSurveyNumber: {
    error: false,
    message: "",
    data: [],
  },
  liaisonDocFilterAccountable: [],
  getLiaisonPlanApprovalReport: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonPlanApprovalReportFilterProject: {
    error: false,
    message: "",
    data: [],
  },
  getBdPaymentMilestoneList: {
    error: false,
    message: "",
    data: [],
  },
  LiaisonPlanApprovalReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMoUReportKPI: {
    error: false,
    message: "",
    data: [],
  },
  ProjectMoUReportKPI: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdProjectTaskRejectionReasonMaster: {
    error: false,
    message: "",
    data: [],
  },
  getBdAgreementTypeMaster: {
    error: false,
    message: "",
    data: [],
  },
  getProjectMoUReportKPIFilterProject: {
    error: false,
    message: "",
    data: [],
  },
  liasonDocumentTaskPlanApprovalExport: [],
  getCourtEstablishment: {
    error: false,
    message: "",
    data: [],
  },
  getCourtCaseStatusMaster: {
    error: false,
    message: "",
    data: [],
  },
  getCourtCaseTypeMaster: {
    error: false,
    message: "",
    data: [],
  },
  getBdRegistrationFees: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdRegistrationFeesRateNew: {
    error: false,
    message: "",
    data: [],
  },
  get_Liaison_Process_Dependency: {
    error: false,
    message: "",
    data: [],
  },
  getLiasionProsessesException: {
    error: false,
    message: "",
    data: [],
  },
  getApfTaskList: {
    error: false,
    message: "",
    data: [],
  },
  getApfPanel: {
    error: false,
    message: "",
    data: [],
  },
  getMasterList: {
    error: false,
    message: "",
    data: [],
  },
  getProjectDocumentType: {
    error: false,
    message: "",
    data: [],
  },
  getLiasisonDelayReason: {
    error: false,
    message: "",
    data: [],
  },
  getBank: {
    error: false,
    message: "",
    data: [],
  },
  getBankCategory: {
    error: false,
    message: "",
    data: [],
  },
  getLoanTask: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcess: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonPaymentExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdProjectTaskTransaction: {
    error: false,
    message: "",
    data: [],
  },
  getBdTaskApprovalMaster: {
    error: false,
    message: "",
    data: [],
  },
  getProjectExport: {
    error: false,
    message: "",
    data: [],
  },
  getSubRegistrarOfficeMapping: {
    error: false,
    message: "",
    data: [],
  },
  getSubRegistrarMapping: {
    error: false,
    message: "",
    data: [],
  },
  getBdRegistrationFeesRate: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonProcessApplicabilityReason: {
    error: false,
    message: "",
    data: [],
  },
  getLandlordMeeting: {
    error: false,
    message: "",
    data: [],
  },
  getLandLoadMeetingStage: {
    error: false,
    message: "",
    data: [],
  },
  getLandLoadMeetingParticular: {
    error: false,
    message: "",
    data: [],
  },
  getNewsLetterCategory: {
    error: false,
    message: "",
    data: [],
  },
  getLiasisonDelayReasonMasterExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectDocumentTypeExport: {
    error: false,
    message: "",
    data: [],
  },
  getBankMasterExport: {
    error: false,
    message: "",
    data: [],
  },
  getApfPanelExport: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessExport: {
    error: false,
    message: "",
    data: [],
  },
  getLoanPanelExport: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonTaskExport: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonProcessMasterExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectTaskMasterExport: {
    error: false,
    message: "",
    data: [],
  },
  getApfTaskPlanReport: {
    error: false,
    message: "",
    data: [],
  },
  getLiasisonDelayReasonMasterExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectDocumentTypeExport: {
    error: false,
    message: "",
    data: [],
  },
  getRegistrationFeesRateExport: {
    error: false,
    message: "",
    data: [],
  },
  getAgreementTypeExport: {
    error: false,
    message: "",
    data: [],
  },
  getLiasionProcessExceptionExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectTaskMasterExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectTaskRejectionReasonExport: {
    error: false,
    message: "",
    data: [],
  },
  getBdPaymentMilestoneListExport: {
    error: false,
    message: "",
    data: [],
  },
  getRegistrationFeesExport: {
    error: false,
    message: "",
    data: [],
  },
  getPlanningAuthorityExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectTaskDependencyExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectTaskCategoryExport: {
    error: false,
    message: "",
    data: [],
  },
  getTaskApprovalExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectDropReasonExport: {
    error: false,
    message: "",
    data: [],
  },
  getBdProjectTaskTransactionExport: {
    error: false,
    message: "",
    data: [],
  },
  getSubRegistrarExport: {
    error: false,
    message: "",
    data: [],
  },
  getLoanTaskExport: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonCategoryExport: {
    error: false,
    message: "",
    data: [],
  },
  getCourtCaseTypeExport: {
    error: false,
    message: "",
    data: [],
  },
  getCourtCaseStatusExport: {
    error: false,
    message: "",
    data: [],
  },
  getCourtEstablishmentExport: {
    error: false,
    message: "",
    data: [],
  },
  getlanddisputeExport: {
    error: false,
    message: "",
    data: [],
  },
  getApplicabilityReasonExport: {
    error: false,
    message: "",
    data: [],
  },
  getdependencyExport: {
    error: false,
    message: "",
    data: [],
  },
  getcategoryExport: {
    error: false,
    message: "",
    data: [],
  },
  getapftaskExport: {
    error: false,
    message: "",
    data: [],
  },
  getLandLordMeetingParticularRemark: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLandLordMeetingAttendee: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLandLordMeetingParticular: {
    error: false,
    message: "",
    data: [],
  },
  getLandLordMeetingParticularExport: {
    error: false,
    message: "",
    data: [],
  },
  getLandlordMeetingStageMaster: {
    error: false,
    message: "",
    data: [],
  },
  getLandlordMeetingStageExportMaster: {
    error: false,
    message: "",
    data: [],
  },
  getLandLordMeetingDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanReportExport: {
    error: false,
    message: "",
    data: [],
  },
  getApfTaskPlanExport: {
    error: false,
    message: "",
    data: [],
  },
  getApfTaskPlanQueryReportExport: {
    error: false,
    message: "",
    data: [],
  },
  getLandLordMeetingMom: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessDependent: {
    error: false,
    message: "",
    data: [],
  },
  getBdProjectDeedType: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonProcessPlanMergerFile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdLandDisputePriority: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanQueryTemplete: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessPlanDependencyException: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectTaskPlanApprovalBy: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessPlanApprovalFilterApproverBy: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonDocumentationTaskPlanApprovalFilterApproverBy: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanFile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanQueryFilterFile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessAuthorityMapList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanQueryFilterResponsible: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanQueryFilterAccountable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanOwnerFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanAccountableFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectInsertedData: {},
  getProjectZoneExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLandlordMeetingExport: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonProcessForException: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLandlordMeetingReport: {
    error: false,
    message: "",
    data: [],
  },
  getLandLordMeetingReportDetailPrint: {
    error: false,
    message: "",
    data: [],
  },
  getProjectFileFilterForTemplate: {
    error: false,
    message: "",
    data: [],
  },
  getProjectCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdProjectTaskProjectCategoryMapList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectCategoryExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectFileFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectPriority: {
    error: false,
    message: "",
    data: [],
  },
  getLandAcquisitionKpiReport: {
    error: false,
    message: "",
    data: [],
  },
  getLandAcquisitionKpiReportExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectMoUKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectMoUKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLandAcquisitionKPIReport: {
    error: false,
    message: "",
    data: [],
  },
  getLandAcquisitionReportExport: {
    error: false,
    message: "",
    data: [],
  },
  getApfTaskPlanQueryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  // getApfTaskPlanQueryReportExport: {
  //     error: false,
  //     message: "",
  //     data: [],
  //     "totalCount": 0
  // },
  getProjectPhaseMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectPhaseMasterExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLandAcquisitionKPIReportItem: {
    error: false,
    message: "",
    data: [],
  },
  getLandAcquisitionKPIReportItemExport: {
    error: false,
    message: "",
    data: [],
  },
  getPurchaseOrderRejectionReport: {
    data: [],
    message: "",
    error: false,
    totalCount: 0,
  },
  getPurchaseOrderCycleReport: {
    data: [],
    message: "",
    error: false,
    totalCount: 0,
  },
  getStockProject: {
    data: [],
    message: "",
    error: false,
    totalCount: 0,
  },
  getLandAquisationOveralReport: {
    data: [],
    message: "",
    error: false,
    totalCount: 0,
  },
  getBdTaskApprovalFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdTaskApprovalFilterTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdTaskApprovalTaskToAdd: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectTaskForOwnerUpdate: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDroppedFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  liasonDocumentTaskPlanApplicable: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  projectFlowListExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getApfTaskPlanExtraFile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSubMenu: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDocumentRepository: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDocumentRepositoryExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDocumentRepositoryFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDocumentRepositoryFilterProjectFile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDocumentRepositoryFilterDocumentType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectFileMove: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectFileMoveTO: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdProjectSubTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getBdProjectSubTaskExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessPlanKpi: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessPlanKpiExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLiaisonProcessPlanDataExport:{
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProjectDocumentRepositoryFilterProject1:{
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  }
};
const Report_reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_PROJECT_AUTHORITY:
      return Object.assign({}, state, { projectAuthority: payload });
    case GET_BD_USER:
      return Object.assign({}, state, { user: payload });
    case GET_PROJECT_PHASE:
      return Object.assign({}, state, { projectPhase: payload });
    case GET_DISTRICT:
      return Object.assign({}, state, { districts: payload });
    case GET_TALUK:
      return Object.assign({}, state, { taluks: payload });
    case GET_HOBLI:
      return Object.assign({}, state, { hobli: payload });
    case GET_PROJECT_BROKERS:
      return Object.assign({}, state, { projectBrokers: payload });
    case GET_PROJECT_LIST:
      return Object.assign({}, state, { projectList: payload });
    case GET_PROJECT_PLAN_FILE_LIST:
      return Object.assign({}, state, { projectPlanFileList: payload });
    case GET_LIAISON_CATEGORY_LIST:
      return Object.assign({}, state, { liaisonCategoryList: payload });
    case GET_LIAISON_PROCESS_LIST:
      return Object.assign({}, state, { fileLiaisonProcessList: payload });
    case GET_PROJECT_TASK_PLAN_MANDOTORY_DOCUMENT:
      return Object.assign({}, state, {
        taskPlanMandotoryDocumentList: payload,
      });
    case GET_PROJECT_FLOW_LIST:
      return Object.assign({}, state, { projectFlowList: payload });
    case GET_VILLAGE_LIST:
      return Object.assign({}, state, { villages: payload });
    case GET_PROJECT_OWNERS_NAMES:
      return Object.assign({}, state, { projectOwners: payload });
    case GET_PROJECT_NAMES:
      return Object.assign({}, state, { projectNames: payload });
    case GET_PROJECT_TASK_STATUS:
      return Object.assign({}, state, { projectTaskStatus: payload });
    case GET_PROJECT_DELAY_REASON:
      return Object.assign({}, state, { projectTaskDelayReason: payload });
    case GET_DOCUMENT_TYPES:
      return Object.assign({}, state, { projectTaskDocumentType: payload });
    case GET_PROJECT_TASK_PLAN_DETAILS:
      return Object.assign({}, state, { ProjectTaskPlanDetail: payload });
    case GET_PROJECT_PLAN:
      return Object.assign({}, state, { projectPlans: payload });
    case GET_PROJECT_FILE_LIST:
      return Object.assign({}, state, { projectFileList: payload });
    case GET_LAND_OWNER_NAMES:
      return Object.assign({}, state, { landOwnerNames: payload });
    case GET_LIASON_TASK_PLAN:
      return Object.assign({}, state, { liasonTaskPlan: payload });
    case GET_LIASON_PROCESS_PLAN:
      return Object.assign({}, state, { liasonProcessPlan: payload });
    case GET_PROJECT_BD_OWNER:
      return Object.assign({}, state, { projectBdOwner: payload });
    case GET_PROJECT_LEGAL_OWNER:
      return Object.assign({}, state, { projectLegalOwner: payload });
    case GET_LIASON_DOCUMENT_TASK_PLAN:
      return Object.assign({}, state, { liasonDocumentTaskPlan: payload });
    case GET_PROJECT_SURVEY_NO_TASK_APPROVAL:
      return Object.assign({}, state, {
        projectSurveyNoTaskApprovals: payload,
      });
    case GET_PROJECT_SURVEY_NO_TASK_APPROVAL_DETAILS:
      return Object.assign({}, state, {
        projectSurveyNoTaskPlanApprovalDetail: payload,
      });
    case GET_MY_WEB_NOTIFICATION:
      return Object.assign({}, state, { notificationList: payload });
    case GET_UPCOMING_PROJECT_LIST:
      return Object.assign({}, state, { upComingProjectList: payload });
    case GET_AUTHORIZATION:
      return Object.assign({}, state, { authorizationList: payload });
    case GET_MENU_PERMISSION:
      return Object.assign({}, state, { menuForPermissionList: payload });
    case GET_FUNCTIONALITY:
      return Object.assign({}, state, { getFunctionalityList: payload });
    //New
    case GET_LAND_OWNER_LIST:
      return Object.assign({}, state, { landOwnerList: payload });
    case GET_SURVEY_NO_LIST:
      return Object.assign({}, state, { surveyNumberList: payload });
    case GET_SURVEY_DETAIL_LIST:
      return Object.assign({}, state, { surveyNumberDetail: payload });
    case GET_FILE_ACQUISITION_INFO_LIST:
      return Object.assign({}, state, { fileAcquisitionInfoList: payload });
    case GET_ACQUISITION_MODE_LIST:
      return Object.assign({}, state, { fileAcquisitionModeList: payload });
    case GET_ACQUISITION_INFO_DETAIL:
      return Object.assign({}, state, { fileAcquisitionInfoDetail: payload });
    case GET_PROJECT_LIST_WITH_EXTENT:
      return Object.assign({}, state, { projectListWithExtent: payload });
    case GET_FEASIBILITY_ITEM_LIST:
      return Object.assign({}, state, { feasibilityItemList: payload });
    case GET_FEASIBILITY_REPORT_LIST:
      return Object.assign({}, state, { feasibilityReportList: payload });
    case GET_FEASIBILITY_REPORT_DETAIL:
      return Object.assign({}, state, { feasibilityReportDetail: payload });
    case GET_LAND_OWNER_DETAIL:
      return Object.assign({}, state, { landOwnerDetail: payload });
    case GET_LAND_BROKER_LIST:
      return Object.assign({}, state, { landBrokerList: payload });
    case GET_LAND_BROKER_DETAIL:
      return Object.assign({}, state, { landBrokerDetail: payload });
    case GET_LAND_OWNNER_VILLAGE_LIST:
      return Object.assign({}, state, { landOwnerVillages: payload });
    case GET_LIAISON_DOCUMENT_CATEGORY_LIST:
      return Object.assign({}, state, { liaisonDocumentCategoryList: payload });
    case GET_PROJECT_FUND_SOURCE_LIST:
      return Object.assign({}, state, { projectFundSourceList: payload });
    case GET_PROJECT_FUND_REQUIREMENT_DETAIL:
      return Object.assign({}, state, {
        projectFundRequirementDetail: payload,
      });
    case GET_PROJECT_FUND_LIST:
      return Object.assign({}, state, { projectFundList: payload });
    case GET_PROJECT_FUND_DETAIL:
      return Object.assign({}, state, { projectFundDetail: payload });
    case GET_PROJECT_FILE_PAYEMENT_DETAIL:
      return Object.assign({}, state, { projectFilePaymentDetail: payload });
    case GET_BD_PAYMENT_LIST:
      return Object.assign({}, state, { bdPaymentList: payload });
    case GET_BD_PAYMENT_DETAIL:
      return Object.assign({}, state, { bdPaymentDetail: payload });
    case GET_BD_PAYMENT_MILESTONE:
      return Object.assign({}, state, { bdPaymentMilestone: payload });
    case GET_PROJECT_BROKER_LIST:
      return Object.assign({}, state, { projectBrokerList: payload });
    case GET_PROJECT_BROKER_DETAIL:
      return Object.assign({}, state, { projectBrokerDetail: payload });
    case GET_PROJECT_BROKER_PAYMENT_INFO:
      return Object.assign({}, state, { projectBrokerPaymentInfo: payload });
    case GET_PROJECT_LAND_OWNER:
      return Object.assign({}, state, { projectLandOwner: payload });
    case GET_PROJECT_OWNER_SURVEY_NUMBER_LIST:
      return Object.assign({}, state, { projectOwnerSurveyNoList: payload });
    case GET_PROJECT_FILE_ADVANCE_PAYMENT_DETAIL:
      return Object.assign({}, state, {
        projectFileAdvancePaymentDetail: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_FILE:
      return Object.assign({}, state, { liaisonProcessPlanFile: payload });
    case GET_LIASON_PROCESS_PLAN_APPLICATION:
      return Object.assign({}, state, {
        liasonProcessPlanApplication: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_APPLICATION_FILE_DETAIL:
      return Object.assign({}, state, {
        liaisonProcessPlanApplicationFileDetail: payload,
      });
    case GET_PROJECTC_MILESTONE:
      return Object.assign({}, state, { projectMilestone: payload });
    case GET_BD_REGISTRATION_ITEM:
      return Object.assign({}, state, { bdRegistrationItem: payload });
    case GET_SUBREGISTER_OFFICE:
      return Object.assign({}, state, { subRegisterOffice: payload });
    case GET_PROJECT_FILE_DOCUMENT:
      return Object.assign({}, state, { projectFileDocument: payload });
    case GET_DEPENDENT_RELATIONSHIP:
      return Object.assign({}, state, { getDependentRelationship: payload });
    case GET_BD_PAYMENT_ADVANCE_DETAIL:
      return Object.assign({}, state, { bdPaymentAdvanceDetail: payload });
    case GET_PROJECT_DETAIL:
      return Object.assign({}, state, { projectDetail: payload });
    case GET_PROJECT_DROP_REASON_LIST:
      return Object.assign({}, state, { projectDropReason: payload });
    case GET_COMPANY_PROJECT_MAP:
      return Object.assign({}, state, { companyProjectMap: payload });
    // case GET_PROJECT:
    //     return Object.assign({}, state, { projectDetails: payload });
    case GET_COMPANY:
      return Object.assign({}, state, { companyDetails: payload });
    case GET_DROPPED_PROJECT_LIST:
      return Object.assign({}, state, { droppedProjectList: payload });
    case GET_INSERTED_LAND_OWNER_DATA:
      return Object.assign({}, state, { insertedLandOwnerData: payload });
    case GET_INSERTED_LAND_BROKER_DATA:
      return Object.assign({}, state, { insertedLandBrokerData: payload });
    case GET_LAND_OWNER_ACCOUNT_DETAIL:
      return Object.assign({}, state, { landOwnerAccountDetail: payload });
    case GET_LAND_BROKER_ACCOUNT_DETAIL:
      return Object.assign({}, state, { landBrokerAccountDetail: payload });
    case GET_LIAISON_PROCESS_PLAN_FILTER_OWNER_LIST:
      return Object.assign({}, state, { processPlanFilterOwners: payload });
    case GET_LIAISON_PROCESS_PLAN_APPLICATION_FILTER_OWNER_LIST:
      return Object.assign({}, state, {
        processPlanApplicationFilterOwners: payload,
      });
    case GET_LIAISON_DOC_TASK_PLAN_FILTER_OWNER_LIST:
      return Object.assign({}, state, { liaisonDocFilterOwners: payload });
    case GET_USER_PROFILE:
      return Object.assign({}, state, { userProfileDetails: payload });
    case UPDATE_EMPLOYEE_PROFILE:
      return Object.assign({}, state, { updateEmployeeProfile: payload });
    case GET_BIRTHDAY:
      return Object.assign({}, state, { GetBirthDay: payload });
    case GET_EMPLOYEE_WORK_ANNIVERSARY:
      return Object.assign({}, state, { getAnniversary: payload });
    case GET_PROJECT_LIST_FILTER:
      return Object.assign({}, state, { getProjectListFilter: payload });
    case GET_PROJECT_UPCOMING_FILTER:
      return Object.assign({}, state, { getProjectUpcomingFilter: payload });
    case GET_CIVIL_PROJECT_FILTER:
      return Object.assign({}, state, { getCivilProjectFilter: payload });
    case GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL:
      return Object.assign({}, state, {
        liasonDocumentTaskPlanApproval: payload,
      });
    case GET_PROJECT_FILE_FILTER:
      return Object.assign({}, state, { projectFileFilterList: payload });
    case GET_LIAISON_PROCESS_FILTER:
      return Object.assign({}, state, { liaisonProcessFilterList: payload });
    case GET_APF_PANEL:
      return Object.assign({}, state, { apfPanelList: payload });
    case GET_APF_PENDING_PROJECT_FILE:
      return Object.assign({}, state, { apfPendingProjectFileList: payload });
    case GET_APF_TASK_PLAN:
      return Object.assign({}, state, { apfTaskPlanList: payload });
    case GET_APF_TASK_PLAN_DETAIL:
      return Object.assign({}, state, { getApfTaskPlanDetail: payload });
    case GET_APF_TASK_PLAN_PROJECT_FILTER:
      return Object.assign({}, state, { apfTaskProjectFilterList: payload });
    case GET_APF_TASK_PLAN_PANEL_FILTER:
      return Object.assign({}, state, { apfTaskPanelFilterList: payload });
    case GET_APF_TASK_PLAN_PROJECT_FILE_FILTER:
      return Object.assign({}, state, {
        apfTaskProjectFileFilterList: payload,
      });
    case GET_APF_TASK_FILTER:
      return Object.assign({}, state, { apfTaskFilterList: payload });
    case GET_APF_TASK_PLAN_QUERY:
      return Object.assign({}, state, { apfTaskPlanQueryList: payload });
    case GET_APF_TASK_PLAN_QUERY_DETAIL:
      return Object.assign({}, state, { getApfTaskPlanQueryDetail: payload });
    case GET_LIAISON_DOCUMENTATION_REPORT:
      return Object.assign({}, state, {
        getLiaisonDocumentationReport: payload,
      });
    case GET_BD_LAND_DISPUTE_TYPE:
      return Object.assign({}, state, { getBDLandDisputeType: payload });
    case GET_BD_LAND_DISPUTE_LIST:
      return Object.assign({}, state, { getBDLandDisputeList: payload });
    case GET_BD_LAND_DISPUTE_DETAIL:
      return Object.assign({}, state, { bdLandDisputeDetail: payload });
    case GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILTER:
      return Object.assign({}, state, {
        liaisonDocumentationReportProjectFilter: payload,
      });
    case GET_LIAISON_DOCUMENTATION_REPORT_PROJECT_FILE_FILTER:
      return Object.assign({}, state, {
        liaisonDocReportProjectFileFilter: payload,
      });
    case GET_LIAISON_DOCUMENTATION_REPORT_CATEGORY_FILTER:
      return Object.assign({}, state, {
        liaisonDocReportCategoryFilter: payload,
      });
    case GET_COURT_CASE_TYPE:
      return Object.assign({}, state, { courtCaseTypeList: payload });
    case GET_COURT_CASE_STATUS:
      return Object.assign({}, state, { courtCaseStatusList: payload });
    case GET_COURT_CASE_JURISDICTION:
      return Object.assign({}, state, { courtCaseJurisdictionList: payload });
    case GET_DIRECTOR:
      return Object.assign({}, state, { directorList: payload });
    case GET_COURT_CASE:
      return Object.assign({}, state, { courtCaseList: payload });
    case GET_COURT_CASE_DETAIL:
      return Object.assign({}, state, { courtCaseDetail: payload });
    case GET_COURT_CASE_HANDLED_BY:
      return Object.assign({}, state, { courtCaseHandledBy: payload });
    case GET_BD_LAND_DISPUTE_HANDLED_BY:
      return Object.assign({}, state, { landDisputeHandledBy: payload });
    case GET_TRAINING_CONTENT:
      return Object.assign({}, state, { trainingContentList: payload });
    case GET_BD_PROJECT_TASK_REJECTION_REASON:
      return Object.assign({}, state, {
        bdProjectTaskRejectionReasonList: payload,
      });
    case GET_BD_AGREEMENT_TYPE:
      return Object.assign({}, state, { bdAgreementTypeList: payload });
    case GET_BD_PROJECT_REPORT:
      return Object.assign({}, state, { bdProjectReportList: payload });
    case GET_BD_PROJECT_TASK_REPORT:
      return Object.assign({}, state, { bdProjectTaskReportList: payload });
    case GET_BD_PROJECT_TASK_REPORT_EXPORT:
      return Object.assign({}, state, {
        bdProjectTaskReportExportData: payload,
      });
    case GET_PROJECT_PROPOSED_NAME:
      return Object.assign({}, state, { projectProposedNameList: payload });
    case GET_TRAINING:
      return Object.assign({}, state, { getTraining: payload });
    case GET_APF_TASK_PLAN_TASK_FILTER:
      return Object.assign({}, state, { apfTaskPlanTaskFilterList: payload });
    case GET_LIAISON_PROCESS_PLAN_PROJECT:
      return Object.assign({}, state, {
        getLiaisonProcessPlanProject: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_PROCESS:
      return Object.assign({}, state, {
        getLiaisonProcessPlanProcess: payload,
      });
    case GET_LIAISON_PAYMENT_LIST:
      return Object.assign({}, state, { getLiaisonPaymentList: payload });
    case GET_LIAISON_PAYMENT_DETAIL:
      return Object.assign({}, state, { getLiaisonPaymentDetail: payload });
    case GET_LIAISON_PAYMENT_SURVEY_NUMBER:
      return Object.assign({}, state, {
        getLiaisonPaymentSurveyNumber: payload,
      });
    case GET_LIAISON_PAYMENT_TASK_PLAN:
      return Object.assign({}, state, { getLiaisonPaymentTaskPlan: payload });
    case GET_FUNCTIONALITY_PERMISSION_DETAIL:
      return Object.assign({}, state, {
        getFunctionalityPermissionDetail: payload,
      });
    case GET_LIAISON_PAYMENT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getLiaisonPaymentFilterProject: payload,
      });
    case GET_LIAISON_PAYMENT_FILTER_REQUESTED_BY:
      return Object.assign({}, state, {
        getLiaisonPaymentFilterRequestedBy: payload,
      });
    case GET_LIAISON_DOCUMENTATION_REPORT_OWNER_FILTER:
      return Object.assign({}, state, { liaisonDocReportOwnerFilter: payload });
    case GET_PROJECT_NAMES1:
      return Object.assign({}, state, { projectNames1: payload });
    case GET_PROJECT_TASK_PLAN_FILTER_TASK_OWNER:
      return Object.assign({}, state, {
        projectTaskPlanFilterTaskOwner: payload,
      });
    case GET_PROJECT_TASK_PLAN_FILTER_TASK:
      return Object.assign({}, state, { projectTaskPlanFilterTask: payload });
    case GET_LIAISON_DOCUMENT_TASK_PLAN_PENDING:
      return Object.assign({}, state, {
        liasonDocumentTaskPlanPending: payload,
      });
    case GET_LIAISON_APPLICABLE_PROCESS_PLAN:
      return Object.assign({}, state, {
        getLiaisonApplicableProcessPlan: payload,
      });
    case GET_LIASON_DOCUMENT_TASK_PLAN_EXPORT:
      return Object.assign({}, state, {
        liasonDocumentTaskPlanExport: payload,
      });
    case GET_DEPARTMENT:
      return Object.assign({}, state, { departmentList: payload });
    case GET_LIAISON_DOCUMENT_TASK_PLAN_APPROVAL_DETAIL:
      return Object.assign({}, state, {
        liaisonDocumentTaskPlanApprovalDetail: payload,
      });
    case GET_LIAISON_DOCUMENT_TASK_PLAN_DETAIL:
      return Object.assign({}, state, {
        liaisonDocumentTaskPlanDetail: payload,
      });
    case GET_PROJECT_TASK_PLAN_FILTER_TASK_CATEGORY:
      return Object.assign({}, state, {
        projectTaskPlanFilterTaskCategory: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_APPLICATION_DETAIL_1:
      return Object.assign({}, state, {
        liaisonProcessPlanApplicationDetail1: payload,
      });
    case GET_LIAISON_TASK_PLAN_DETAIL:
      return Object.assign({}, state, { liaisonTaskPlanDetail: payload });
    case GET_LIAISON_PROCESS_PLAN_PENDING_DETAIL:
      return Object.assign({}, state, {
        liaisonProcessPlanPendingDetail: payload,
      });
    case GET_LIAISON_TASK:
      return Object.assign({}, state, { liaisonTasks: payload });
    case GET_LIAISON_PROCESS_PLAN_PENDING_TASK:
      return Object.assign({}, state, {
        liaisonProcessPlanPendingTask: payload,
      });
    case GET_LIAISON_PROCESS:
      return Object.assign({}, state, { liaisonProcessList: payload });
    case GET_LIAISON_PROCESS_CATEGORY1:
      return Object.assign({}, state, { liaisonProcessCategory1: payload });
    case GET_LIAISON_PROCESS_OPTIONAL:
      return Object.assign({}, state, { liaisonProcessOptional: payload });
    case GET_LIAISON_PROCESS_SURVEY_NUMBER_OPTIONAL:
      return Object.assign({}, state, {
        liaisonProcessSurveyNumberOptional: payload,
      });
    case GET_LIAISON_PROCESS_PLAN:
      return Object.assign({}, state, { liaisonProcessPlan: payload });
    case GET_LIAISON_PROCESS_PLAN_APPROVAL:
      return Object.assign({}, state, { liaisonProcessPlanApproval: payload });
    case GET_LIAISON_PROCESS_PLAN_DETAIL:
      return Object.assign({}, state, { liaisonProcessPlanDetail: payload });
    case GET_LIAISON_PROCESS_PLAN_FILTER_PROJECT:
      return Object.assign({}, state, {
        liaisonProcessPlanFilterProject: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_FILTER_PROCESS:
      return Object.assign({}, state, {
        liaisonProcessPlanFilterProcess: payload,
      });
    case GET_NEWS_LETTER:
      return Object.assign({}, state, { newsLatterList: payload });
    case GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_FILE:
      return Object.assign({}, state, {
        liaisonProcessIdentificationPendingFile: payload,
      });
    case GET_LIAISON_PROCESS_IDENTIFICATION_PENDING_CATEGORY:
      return Object.assign({}, state, {
        liaisonProcessIdentificationPendingCategory: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_EXPORT:
      return Object.assign({}, state, { liaisonProcessPlanExport: payload });
    case GET_PROJECT_ZONE:
      return Object.assign({}, state, { projectZone: payload });
    case GET_BD_LEGAL_TASK_LIST_EXPORT:
      return Object.assign({}, state, { getBDLegalTaskListExport: payload });
    case GET_BD_LAND_DISPUTE_EXPORT:
      return Object.assign({}, state, { landDisputeExport: payload });
    case GET_COURT_CASE_EXPORT:
      return Object.assign({}, state, { courtCaseListExport: payload });
    case GET_COURT_CASE_PROJECT_FILTER:
      return Object.assign({}, state, { courtCaseProjectFilter: payload });
    case GET_PROJECT_DEED:
      return Object.assign({}, state, { getProjectDeed: payload });
    case GET_BD_PROJECT_TASK:
      return Object.assign({}, state, { getProjectTask: payload });
    case GET_BD_PROJECT_CONSOLIDATED_REPORT:
      return Object.assign({}, state, {
        getBdProjectConsolidatedReport: payload,
      });
    case GET_PROJECT_FILE_ACQUISITION_STATUS_FILTER:
      return Object.assign({}, state, {
        projectFileAcquisitionStatusFilter: payload,
      });
    case GET_BD_FILE_ACQUISITION_REASON:
      return Object.assign({}, state, { bdFileAcquisitionReason: payload });
    case GET_PROJECT_FILE_ACQUISITION_PENDING:
      return Object.assign({}, state, {
        getProjectFileAcquisitionPending: payload,
      });
    case GET_ACQUIRED_FILE_EXPORT:
      return Object.assign({}, state, { getAcquiredFileExport: payload });
    case GET_FILE_ACQUISITION_INFO_EXPORT:
      return Object.assign({}, state, {
        getFileAcquisitionInfoExport: payload,
      });
    case GET_PROJECT_FILE_EXPORT:
      return Object.assign({}, state, { getProjectFileExport: payload });
    case GET_BD_PROJECT_CONSOLIDATED_REPORT_PRINT:
      return Object.assign({}, state, {
        getBdProjectConsolidatedReportPrint: payload,
      });
    case GET_PROJECT_FILE_ACQUISTION_PENDING_DETAIL:
      return Object.assign({}, state, {
        getProjectFileAcquisitionPendingDetail: payload,
      });
    case GET_PROJECT_FILE_ACQUISTION_HANDLED_BY_FILTER:
      return Object.assign({}, state, {
        getProjectFileAcquisitionHandledByFilter: payload,
      });
    case GET_PROJECT_LEGAL_OWNER_FILTER:
      return Object.assign({}, state, { projectLegalOwnerFilter: payload });
    case GET_PROJECT_BD_OWNER_FILTER:
      return Object.assign({}, state, { projectBdOwnerFilter: payload });
    case GET_LIAISON_PROCESS_PLAN_APPLICABILITY_REASON:
      return Object.assign({}, state, {
        liaisonProcessPlanApplicabilityReason: payload,
      });
    case GET_LOAN_PANEL:
      return Object.assign({}, state, { loanPanel: payload });
    case GET_LOAN_APPLICATION_ELIGIBLE_PROJECT:
      return Object.assign({}, state, {
        loanApplicationEligibleProject: payload,
      });
    case GET_LOAN_APPLICATION_ELIGIBLE_PROJECT_FILE:
      return Object.assign({}, state, {
        loanApplicationEligibleProjectFile: payload,
      });
    case GET_LOAN_PROCESS_PLAN:
      return Object.assign({}, state, { loanProcessPlan: payload });
    case GET_LOAN_TASK_PLAN:
      return Object.assign({}, state, { loanTaskPlan: payload });
    case GET_LOAN_TASK_PLAN_DETAIL:
      return Object.assign({}, state, { loanTaskPlanDetail: payload });
    case GET_PROJECT_LIAISON_OWNER_FILTER:
      return Object.assign({}, state, { projectLiaisonOwnerFilter: payload });
    case GET_LOAN_PROCESS_PLAN_DETAIL:
      return Object.assign({}, state, { loanProcessPlanDetail: payload });
    case GET_LOAN_PROCESS_PLAN_PROJECT_FILTER:
      return Object.assign({}, state, {
        loanProcessPlanProjectFilter: payload,
      });
    case GET_LOAN_PROCESS_PLAN_PROCESS_FILTER:
      return Object.assign({}, state, {
        loanProcessPlanProcessFilter: payload,
      });
    case GET_LOAN_PROCESS_PLAN_BANK_FILTER:
      return Object.assign({}, state, { loanProcessPlanBankFilter: payload });
    case GET_LOAN_PROCESS_PLAN_OWNER_FILTER:
      return Object.assign({}, state, { loanProcessPlanOwnerFilter: payload });
    case GET_LIAISON_PROCESS_PLAN_DELAYED:
      return Object.assign({}, state, { liaisonProcessPlanDelayed: payload });
    case GET_LIAISON_PROCESS_PLAN_DELAYED_EXPORT:
      return Object.assign({}, state, {
        liaisonProcessPlanDelayedExport: payload,
      });
    // BD Master
    case GET_BD_LAND_DISPUTE_TYPE_MASTER:
      return Object.assign({}, state, { getBdLandDisputeMaster: payload });
    case GET_BD_LAND_DISPUTE_TYPE_MASTER_EXPORT:
      return Object.assign({}, state, {
        getBdLandDisputeMasterExport: payload,
      });
    case GET_LIAISON_PROCESS_CATEGORY_MASTER:
      return Object.assign({}, state, {
        getLiasionProcessCategoryMaster: payload,
      });
    case GET_BD_PROJECT_TASK_CATEGORY_MASTER:
      return Object.assign({}, state, { ProjectTaskCategory: payload });
    case PROJECT_TASK_DEPENDENCY_MASTER:
      return Object.assign({}, state, { ProjectTaskDependency: payload });
    case PROJECT_DEPENDENT_TASK_MASTER:
      return Object.assign({}, state, { getBdProjectDependentTask: payload });
    case GET_PLANNING_AUTHORITY_MASTER:
      return Object.assign({}, state, { getPlanningAuthority: payload });
    case GET_LIAISON_CATEGORY_MASTER:
      return Object.assign({}, state, { getLiasionCategoryMaster: payload });
    case GET_LIAISON_PROCESS_DEPENDENCY_MASTER:
      return Object.assign({}, { getLiasionProsessDependency: payload });
    case GET_LIAISON_PROCESS_MASTER:
      return Object.assign({}, state, { getLiaisonProcess1: payload });
    case GET_LIAISON_PROCESS_CATEGORY_MASTER_NEW:
      return Object.assign({}, state, { getLiaisonProcessCategory: payload });
    case GET_LIAISON_PROCESS_DETAIL:
      return Object.assign({}, state, { getLiaisonProcessDetails: payload });
    case GET_LIAISON_PROCESS_PLAN_FILTER_ACCOUNTABLE_LIST:
      return Object.assign({}, state, {
        processPlanFilterAccountable: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_FILTER_SY_NUMBER:
      return Object.assign({}, state, {
        processPlanFilterSurveyNumber: payload,
      });
    case GET_LIAISON_DOC_TASK_PLAN_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, { liaisonDocFilterAccountable: payload });
    case GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT:
      return Object.assign({}, state, {
        getLiaisonPlanApprovalReport: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getLiaisonPlanApprovalReportFilterProject: payload,
      });
    case GET_BD_PAYMENT_MILESTONE_MASTER:
      return Object.assign({}, state, { getBdPaymentMilestoneList: payload });
    case GET_LIAISON_PROCESS_PLAN_APPROVAL_REPORT_EXPORT:
      return Object.assign({}, state, {
        LiaisonPlanApprovalReportExport: payload,
      });
    case GET_PROJECT_MOU_REPORT_KPI:
      return Object.assign({}, state, { getProjectMoUReportKPI: payload });
    case GET_PROJECT_MOU_REPORT_KPI_EXPORT:
      return Object.assign({}, state, { ProjectMoUReportKPI: payload });
    case GET_PROJECT_TASK_REJECTION_REASON_MASTER:
      return Object.assign({}, state, {
        getBdProjectTaskRejectionReasonMaster: payload,
      });
    case GET_AGREEMENT_TYPE_MASTER:
      return Object.assign({}, state, { getBdAgreementTypeMaster: payload });
    case GET_PROJECT_MOU_REPORT_KPI_FILTER_PROJECT:
      return Object.assign({}, state, {
        getProjectMoUReportKPIFilterProject: payload,
      });
    case GET_LIASON_DOCUMENT_TASK_PLAN_APPROVAL_EXPORT:
      return Object.assign({}, state, {
        liasonDocumentTaskPlanApprovalExport: payload,
      });
    case GET_COURT_ESTABLISHMENT:
      return Object.assign({}, state, { getCourtEstablishment: payload });
    case GET_COURT_CASE_STATUS_MASTER:
      return Object.assign({}, state, { getCourtCaseStatusMaster: payload });
    case GET_COURT_CASE_TYPE_MASTER:
      return Object.assign({}, state, { getCourtCaseTypeMaster: payload });
    case GET_REGISTRATION_FEES_RATE_LIST:
      return Object.assign({}, { getBdRegistrationFeesRateNew: payload });
    case GET_REGISTRATION_FEES_MASTER:
      return Object.assign({}, state, { getBdRegistrationFees: payload });
    case GET_LIAISON_PROCESS_DEPENDENCY:
      return Object.assign({}, state, {
        get_Liaison_Process_Dependency: payload,
      });
    case GET_LIASION_PROCESSES_EXCEPTION:
      return Object.assign({}, state, {
        getLiasionProsessesException: payload,
      });
    case GET_APF_TASK_LIST:
      return Object.assign({}, state, { getApfTaskList: payload });
    case GET_APF_PANEL_LIST:
      return Object.assign({}, state, { getApfPanel: payload });
    case GET_MASTER:
      return Object.assign({}, state, { getMasterList: payload });
    case GET_PROJECT_DOCUMENT_TYPE:
      return Object.assign({}, state, { getProjectDocumentType: payload });
    case GET_LIASISON_DELAY_REASON:
      return Object.assign({}, state, { getLiasisonDelayReason: payload });
    case GET_BANK:
      return Object.assign({}, state, { getBank: payload });
    case GET_BANK_CATEGORY:
      return Object.assign({}, state, { getBankCategory: payload });
    case GET_LOAN_TASK:
      return Object.assign({}, state, { getLoanTask: payload });
    case GET_LOAN_PROCESS:
      return Object.assign({}, state, { getLoanProcess: payload });
    case GET_LIAISON_PAYMENT_EXPORT:
      return Object.assign({}, state, { getLiaisonPaymentExport: payload });
    case GET_PROJECT_TASK_TRANSACTION:
      return Object.assign({}, state, { getBdProjectTaskTransaction: payload });
    case GET_BD_TASK_APPROVAL:
      return Object.assign({}, state, { getBdTaskApprovalMaster: payload });
    case GET_PROJECT_EXPORT:
      return Object.assign({}, state, { getProjectExport: payload });
    case GET_SUB_REGISTRAR_OFFICE:
      return Object.assign({}, state, {
        getSubRegistrarOfficeMapping: payload,
      });
    case GET_SUB_REGISTRAR_MAPPING:
      return Object.assign({}, state, { getSubRegistrarMapping: payload });
    case GET_REGISTRATION_FEES_RATE_MASTER:
      return Object.assign({}, state, { getBdRegistrationFeesRate: payload });
    case GET_LIAISON_PROCESS_APPLICABILITY_REASON:
      return Object.assign({}, state, {
        getLiaisonProcessApplicabilityReason: payload,
      });
    case GET_LANDLORD_MEETING:
      return Object.assign({}, state, { getLandlordMeeting: payload });
    case GET_LANDLORD_MEETING_STAGE:
      return Object.assign({}, state, { getLandLoadMeetingStage: payload });
    case GET_LANDLORD_MEETING_PARTICULAR:
      return Object.assign({}, state, {
        getLandLoadMeetingParticular: payload,
      });
    case GET_NEWS_LETTER_CATEGORY:
      return Object.assign({}, state, { getNewsLetterCategory: payload });
    // Export -----
    case GET_PROJECT_DOCUMENT_TYPE_EXPORT:
      return Object.assign({}, state, {
        getProjectDocumentTypeExport: payload,
      });
    case GET_BANK_MASTER_EXPORT:
      return Object.assign({}, state, { getBankMasterExport: payload });
    case GET_APF_PANEL_EXPORT:
      return Object.assign({}, state, { getApfPanelExport: payload });
    case GET_LOAN_PROCESS_EXPORT:
      return Object.assign({}, state, { getLoanProcessExport: payload });
    case GET_LOAN_PANEL_EXPORT:
      return Object.assign({}, state, { getLoanPanelExport: payload });
    case GET_LIAISON_TASK_EXPORT:
      return Object.assign({}, state, { getLiaisonTaskExport: payload });
    case GET_LIAISON_PROCESS_MASTER_EXPORT:
      return Object.assign({}, state, {
        getLiaisonProcessMasterExport: payload,
      });
    case GET_BD_PROJECT_TASK_MASTER_EXPORT:
      return Object.assign({}, state, { getProjectTaskMasterExport: payload });
    case GET_APF_TASK_PLAN_REPORT:
      return Object.assign({}, state, { getApfTaskPlanReport: payload });
    case GET_REGISTRATION_FEES_RATE_EXPORT_MASTER:
      return Object.assign({}, state, {
        getRegistrationFeesRateExport: payload,
      });
    case GET_AGREEMENT_TYPE_EXPORT_MASTER:
      return Object.assign({}, state, { getAgreementTypeExport: payload });
    case GET_LIASION_PROCESSES_EXCEPTION_EXPORT:
      return Object.assign({}, state, {
        getLiasionProcessExceptionExport: payload,
      });
    case GET_PROJECT_TASK_REJECTION_REASON_EXPORT_MASTER:
      return Object.assign({}, state, {
        getProjectTaskRejectionReasonExport: payload,
      });
    case GET_BD_PAYMENT_MILESTONE_EXPORT_MASTER:
      return Object.assign({}, state, {
        getBdPaymentMilestoneListExport: payload,
      });
    case GET_REGISTRATION_FEES_EXPORT_MASTER:
      return Object.assign({}, state, { getRegistrationFeesExport: payload });
    case GET_PLANNING_AUTHORITY_EXPORT_MASTER:
      return Object.assign({}, state, { getPlanningAuthorityExport: payload });
    case PROJECT_TASK_DEPENDENCY_EXPORT_MASTER:
      return Object.assign({}, state, {
        getProjectTaskDependencyExport: payload,
      });
    case GET_BD_PROJECT_TASK_CATEGORY_EXPORT_MASTER:
      return Object.assign({}, state, {
        getProjectTaskCategoryExport: payload,
      });
    case GET_BD_TASK_EXPORT_APPROVAL:
      return Object.assign({}, state, { getTaskApprovalExport: payload });
    case GET_PROJECT_DROP_REASON_EXPORT_LIST:
      return Object.assign({}, state, { getProjectDropReasonExport: payload });
    case GET_PROJECT_TASK_TRANSACTION_EXPORT:
      return Object.assign({}, state, {
        getBdProjectTaskTransactionExport: payload,
      });
    case GET_SUB_REGISTRAR_MAPPING_EXPORT:
      return Object.assign({}, state, { getSubRegistrarExport: payload });
    case GET_LIASISON_DELAY_REASON_MASTER_EXPORT:
      return Object.assign({}, state, {
        getLiasisonDelayReasonMasterExport: payload,
      });
    // Nikhil
    case GET_LOAN_TASK_EXPORT:
      return Object.assign({}, state, { getLoanTaskExport: payload });
    case GET_LIAISON_CATEGORY_EXPORT:
      return Object.assign({}, state, { getLiaisonCategoryExport: payload });
    case GET_COURT_CASE_TYPE_EXPORT:
      return Object.assign({}, state, { getCourtCaseTypeExport: payload });
    case GET_COURT_CASE_STATUS_EXPORT:
      return Object.assign({}, state, { getCourtCaseStatusExport: payload });
    case GET_COURT_ESTABLISHMENT_EXPORT:
      return Object.assign({}, state, { getCourtEstablishmentExport: payload });
    case GET_BD_LAND_DISPUTE_TYPE_EXPORT:
      return Object.assign({}, state, { getlanddisputeExport: payload });
    case GET_LIAISON_PROCESS_APPLICABILITY_REASON_EXPORT:
      return Object.assign({}, state, {
        getApplicabilityReasonExport: payload,
      });
    case GET_LIAISON_PROCESS_DEPENDENCY_EXPORT:
      return Object.assign({}, state, { getdependencyExport: payload });
    case GET_LIAISON_PROCESS_CATEGORY_EXPORT:
      return Object.assign({}, state, { getcategoryExport: payload });
    case GET_APF_TASK_LIST_EXPORT:
      return Object.assign({}, state, { getapftaskExport: payload });
    case GET_LAND_LORD_MEETING_PARTICULAR_REMARK:
      return Object.assign({}, state, {
        getLandLordMeetingParticularRemark: payload,
      });
    case GET_LAND_LORD_MEETING_ATTENDEE:
      return Object.assign({}, state, { getLandLordMeetingAttendee: payload });
    case GET_LAND_LORD_MEETING_PARTICULAR:
      return Object.assign({}, state, {
        getLandLordMeetingParticular: payload,
      });
    case GET_LAND_LORD_MEETING_PARTICULAR_EXPORT:
      return Object.assign({}, state, {
        getLandLordMeetingParticularExport: payload,
      });
    case GET_LAND_LORD_MEETING_STAGE_MASTER:
      return Object.assign({}, state, {
        getLandlordMeetingStageMaster: payload,
      });
    case GET_LAND_LORD_MEETING_STAGE_EXPORT_MASTER:
      return Object.assign({}, state, {
        getLandlordMeetingStageExportMaster: payload,
      });
    case GET_LANDLORD_MEETING_DETAIL:
      return Object.assign({}, state, { getLandLordMeetingDetail: payload });
    case GET_APF_TASK_PLAN_REPORT_EXPORT:
      return Object.assign({}, state, { getApfTaskPlanReportExport: payload });
    case GET_APF_TASK_PLAN_EXPORT:
      return Object.assign({}, state, { getApfTaskPlanExport: payload });
    case GET_APF_TASK_PLAN_QUERY_EXPORT:
      return Object.assign({}, state, {
        getApfTaskPlanQueryReportExport: payload,
      });
    case GET_LAND_LORD_MEETING_MOM:
      return Object.assign({}, state, { getLandLordMeetingMom: payload });
    case GET_LIAISON_PROCESS_DEPENDENT:
      return Object.assign({}, state, { getLiaisonProcessDependent: payload });
    case GET_BD_PROJECT_DEED_TYPE:
      return Object.assign({}, state, { getBdProjectDeedType: payload });
    case GET_LIAISON_PROCESS_PLAN_MERGER_FILE:
      return Object.assign({}, state, {
        getLiaisonProcessPlanMergerFile: payload,
      });
    case GET_BD_LAND_DISPUTE_PRIORITY:
      return Object.assign({}, state, { getBdLandDisputePriority: payload });
    case GET_APF_TASK_PLAN_QUERY_TEMPLETE:
      return Object.assign({}, state, { getApfTaskPlanQueryTemplete: payload });
    case GET_LIAISON_PROCESS_PLAN_EXCEPTION:
      return Object.assign({}, state, {
        getLiaisonProcessPlanDependencyException: payload,
      });
    case GET_PROJECT_TASK_PLAN_APPROVAL_FILTER_APPROVAL_BY:
      return Object.assign({}, state, {
        getProjectTaskPlanApprovalBy: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_APPROVAL_FILTER_APPROVER_BY:
      return Object.assign({}, state, {
        getLiaisonProcessPlanApprovalFilterApproverBy: payload,
      });
    case GET_LIAISON_DOCUMENTATION_TASK_PLAN_APPROAVAL_FILTER_APPROVER:
      return Object.assign({}, state, {
        getLiaisonDocumentationTaskPlanApprovalFilterApproverBy: payload,
      });
    case GET_APF_TASK_PLAN_FILE:
      return Object.assign({}, state, { getApfTaskPlanFile: payload });
    case GET_LANDLORD_MEETING_DETAIL_PRINT:
      return Object.assign({}, state, {
        getLandLordMeetingDetailPrint: payload,
      });
    case GET_APF_TASK_PLAN_QUERY_FILTER_FILE:
      return Object.assign({}, state, {
        getApfTaskPlanQueryFilterFile: payload,
      });
    case GET_LIAISON_PROCESS_AUTHORITY_MAP:
      return Object.assign({}, state, {
        getLiaisonProcessAuthorityMapList: payload,
      });
    case GET_APF_TASK_PLAN_QUERY_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getApfTaskPlanQueryFilterResponsible: payload,
      });
    case GET_APF_TASK_PLAN_QUERY_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getApfTaskPlanQueryFilterAccountable: payload,
      });
    case GET_APF_TASK_PLAN_OWNER_FILTER:
      return Object.assign({}, state, { getApfTaskPlanOwnerFilter: payload });
    case GET_APF_TASK_PLAN_ACCOUNTABLE_FILTER:
      return Object.assign({}, state, {
        getApfTaskPlanAccountableFilter: payload,
      });
    case GET_PROJECT_INSERTED_DATA:
      return Object.assign({}, state, { getProjectInsertedData: payload });
    case GET_PROJECT_ZONE_EXPORT:
      return Object.assign({}, state, { getProjectZoneExport: payload });
    case GET_LAND_LORD_MEETING_EXPORT:
      return Object.assign({}, state, { getLandlordMeetingExport: payload });
    case GET_LIAISON_PROCESS_FOR_EXCEPTION:
      return Object.assign({}, state, {
        getLiaisonProcessForException: payload,
      });
    case GET_LANDLORD_MEETING_REPORT:
      return Object.assign({}, state, { getLandlordMeetingReport: payload });
    case GET_LANDLORD_MEETING_REPORT_DETAIL_PRINT:
      return Object.assign({}, state, {
        getLandLordMeetingReportDetailPrint: payload,
      });
    case GET_PROJECT_FILE_FILTER_FOR_TEMPLATE:
      return Object.assign({}, state, {
        getProjectFileFilterForTemplate: payload,
      });
    case GET_PROJECT_CATEGORY:
      return Object.assign({}, state, { getProjectCategory: payload });
    case GET_BD_PROJECT_TASK_PROJECT_CATEGORY_MAP:
      return Object.assign({}, state, {
        getBdProjectTaskProjectCategoryMapList: payload,
      });
    case GET_PROJECT_CATEGORY_EXPORT:
      return Object.assign({}, state, { getProjectCategoryExport: payload });
    case GET_PROJECT_FILE_FILTER_PROJECT:
      return Object.assign({}, state, { getProjectFileFilterProject: payload });
    case GET_PROJECT_PRIORITY:
      return Object.assign({}, state, { getProjectPriority: payload });
    case GET_LAND_ACQUISITION_KPI_REPORT:
      return Object.assign({}, state, { getLandAcquisitionKpiReport: payload });
    case GET_LAND_ACQUISITION_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getLandAcquisitionKpiReportExport: payload,
      });
    case GET_PROJECT_MOU_KPI_REPORT:
      return Object.assign({}, state, { getProjectMoUKPIReport: payload });
    case GET_PROJECT_MOU_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getProjectMoUKPIReportExport: payload,
      });
    case GET_LAND_ACQUISITION_REPORT:
      return Object.assign({}, state, { getLandAcquisitionKPIReport: payload });
    case GET_LAND_ACQUISITION_REPORT_EXPORT:
      return Object.assign({}, state, {
        getLandAcquisitionReportExport: payload,
      });
    case GET_APF_TASK_PLAN_QUERY_REPORT:
      return Object.assign({}, state, { getApfTaskPlanQueryReport: payload });
    case GET_APF_TASK_PLAN_QUERY_REPORT_EXPORT:
      return Object.assign({}, state, {
        getApfTaskPlanQueryReportExport: payload,
      });
    case GET_PROJECT_PHASE_MASTER:
      return Object.assign({}, state, { getProjectPhaseMaster: payload });
    case GET_PROJECT_PHASE_MASTER_EXPORT:
      return Object.assign({}, state, { getProjectPhaseMasterExport: payload });
    case GET_LAND_ACQUISITION_REPORT_ITEM:
      return Object.assign({}, state, {
        getLandAcquisitionKPIReportItem: payload,
      });
    case GET_LAND_ACQUISITION_REPORT_ITEM_EXPORT:
      return Object.assign({}, state, {
        getLandAcquisitionKPIReportItemExport: payload,
      });
    case GET_PO_REJECTION_REPORT:
      return Object.assign({}, state, {
        getPurchaseOrderRejectionReport: payload,
      });
    case GET_PO_CYCLE_REPORT:
      return Object.assign({}, state, { getPurchaseOrderCycleReport: payload });
    case GET_STOCK_PROJECT:
      return Object.assign({}, state, { getStockProject: payload });
    case GET_LAND_ACQUISITION_OVERALL_REPORT:
      return Object.assign({}, state, {
        getLandAquisationOveralReport: payload,
      });
    case GET_BD_TASK_APPROVAL_FILTER_USER:
      return Object.assign({}, state, { getBdTaskApprovalFilterUser: payload });
    case GET_BD_TASK_APPROVAL_FILTER_TASK:
      return Object.assign({}, state, { getBdTaskApprovalFilterTask: payload });
    case GET_BD_TASK_APPROVAL_TASK_TO_ADD:
      return Object.assign({}, state, { getBdTaskApprovalTaskToAdd: payload });
    case GET_PROJECT_TASK_FOR_OWNER_UPDATE:
      return Object.assign({}, state, {
        getProjectTaskForOwnerUpdate: payload,
      });
    case GET_PROJECT_DROPPED_FILTER:
      return Object.assign({}, state, { getProjectDroppedFilter: payload });
    case GET_LIASON_DOCUMENT_TASK_PLAN_APPLICABLE:
      return Object.assign({}, state, {
        liasonDocumentTaskPlanApplicable: payload,
      });
    case GET_PROJECT_FLOW_LIST_EXPORT:
      return Object.assign({}, state, { projectFlowListExport: payload });
    case GET_APF_TASK_EXTRA_FILE:
      return Object.assign({}, state, { getApfTaskPlanExtraFile: payload });
    case GET_SUB_MENU:
      return Object.assign({}, state, { getSubMenu: payload });
    case GET_PROJECT_DOCUMENT_REPOSITORY:
      return Object.assign({}, state, {
        getProjectDocumentRepository: payload,
      });
    case GET_PROJECT_DOCUMENT_REPOSITORY_EXPORT:
      return Object.assign({}, state, {
        getProjectDocumentRepositoryExport: payload,
      });

    case GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILTER:
      return Object.assign({}, state, {
        getProjectDocumentRepositoryFilterProject: payload,
      });
    case GET_PROJECT_DOCUMENT_REPOSITORY_PROJECT_FILE_FILTER:
      return Object.assign({}, state, {
        getProjectDocumentRepositoryFilterProjectFile: payload,
      });
    case GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_DOCUMENT_TYPE:
      return Object.assign({}, state, {
        getProjectDocumentRepositoryFilterDocumentType: payload,
      });
    case PROJECT_FILE_MOVE:
      return Object.assign({}, state, {
        getProjectFileMove: payload,
      });
    case TO_PROJECT_MOVE_FILE:
      return Object.assign({}, state, {
        getProjectFileMoveTO: payload,
      });
    case GET_PROJECT_SUB_TASK:
      return Object.assign({}, state, {
        getBdProjectSubTask: payload,
      });
    case GET_PROJECT_SUB_TASK_EXPORT:
      return Object.assign({}, state, {
        getBdProjectSubTaskExport: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_KPI:
      return Object.assign({}, state, {
        getLiaisonProcessPlanKpi: payload,
      });
    case GET_LIAISON_PROCESS_PLAN_KPI_EXPORT:
      return Object.assign({}, state, {
        getLiaisonProcessPlanKpiExport: payload,
      });
      case GET_LIAISON_PROCESS_PLAN_EXPORT_DATA:
        return Object.assign({}, state, {
          getLiaisonProcessPlanDataExport: payload,
        });
        case GET_PROJECT_DOCUMENT_REPOSITORY_FILTER_PROJECT:
          return Object.assign({}, state, {
            getProjectDocumentRepositoryFilterProject1: payload,
          });
    default:
      return state;
  }
};
export default Report_reducer;
