import { ApiPaths, ApiService, SetAuthToken, ApiStatus, ErpApi } from '../../utils/ApiService';
import {
    GET_PROFITABLE_REPORTS, GET_LIASONPROCESSOWNER, GET_PROJECT_NAMES, GET_TASK_DELAYS, GET_PROFITABLE_REPORT_COUNT, GET_PENDING_TASK, GET_LIAISON_PROJECT_NAMES, GET_DASHBOARD_DATA, GET_USER_DASHBOARD_DATA, GET_USER_DASHBOARD_TASK, GET_CIVIL_PROCESS_DATA,

    GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA, GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA, GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA,
    GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA, SET_FUNNEL_ITEM_ID,
    GET_DASHBOARD_STOCK_ZONE_DATA, GET_DASHBOARD_STOCK_PROJECT_DATA, GET_DASHBOARD_STOCK_PROJECT_SITE_DATA,

    GET_DASHBOARD_STOCK_AGING_ZONE_DATA, GET_DASHBOARD_STOCK_AGING_PROJECT_DATA, GET_DASHBOARD_STOCK_AGING_FILE_DATA,

    GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA, GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA, GET_DASHBOARD_LAND_AQUIRED_FILE_DATA,

    GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA, GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA, GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA,

    GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA, GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA, GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA, GET_DASHBOARD_DATA_DEPARTMENT, GET_DASHBOARD_DEPARTMENT,

    GET_LIAISON_PROCESS_PLAN_REPORT, GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL, GET_MY_DASHBOARD_DATA_FILTER_USER, GET_DASHBOARD_CRM_PROJECT_OUTSTANDING, GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT,
    GET_DASHBOARD_CRM_SITE_OUTSTANDING, GET_DASHBOARD_CRM_SITE_OUTSTANDING_EXPORT,
    GET_DASHBOARD_CRM_PROJECT_TARGET, GET_DASHBOEAR_CRM_SITE_TARGET,
    GET_DASHBOARD_CRM_PROJECT_RECEIVED, GET_DASHBOARD_CRM_SITE_RECEIVED,
    GET_DASHBOARD_CRM_PROJECT_REALIZED, GET_DAHSBOARD_CRM_SITE_REALIZED,
    GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED, GET_DAHSBOARD_CRM_SITE_NOT_REALIZED, GET_DASHBOARD_DATA_PRE_SALES,
    GET_DASHBOARD_DATA_MARKETING_FUNNEL,

    GET_GRN_ON_TIME_REPORT,
    GET_GRN_ON_TIME_REPORT_EXPORT,
    GET_VENDOR_LEAD_TIME_REPORT,
    GET_VENDOR_LEAD_TIME_REPORT_EXPORT,
    GET_STOCK_PROJECT,
    GET_PURCHASE_INVOICE_ON_TIME_KPI,
    GET_PURCHASE_INVOICE_ON_TIME_KPI_EXPORT,
    GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT,
    GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT_EXPORT,
    GET_MATERIAL_QUANTITY_REJECTION_KPI,
    GET_MATERIAL_QUANTITY_REJECTION_KPI_EXPORT,
    GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT,
    GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT_EXPORT,
    GET_INDENT_PURCHASE_ORDER_KPI_REPORT_EXPORT,
    GET_INDENT_PURCHASE_ORDER_KPI_REPORT,
    GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME,
    GET_REGISTRATION_TURNAROUND_TIME_REPORT,
    GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT,
    GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER,
    GET_CIVIL_RESOURCE_REPORT, GET_ON_TIME_DELIVERY_KPI, GET_ON_TIME_DELIVERY_KPI_EXPORT,
    GET_ON_TIME_DELIVERY_KPI_REPORT, GET_ON_TIME_DELIVERY_KPI_REPORT_EXPORT,
    GET_PROCUREMENT_KPI_REPORT,
    GET_SALES_CRM_REPORT, GET_SALES_CRM_REPORT_EXPORT, GET_CRM_PROJECTS,
    GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL,
    GET_SCALE_TYPE,
    GET_CRM_SITE, GET_CRM_SITE_DIMENSION,

    GET_KPI_SALES_REPORT,
    GET_KPI_SALES_REPORT_EXPORT,
    GET_KPI_SALES_REPORT_EXECUTIVE_FILTER,
    GET_KPI_SALES_REPORT_DETAILS,
    GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER,
    GET_KPI_SALES_REPORT_DETAILS_EXPORT,
    GET_KPI_PRE_SALES_REPORT,
    GET_KPI_PRE_SALES_REPORT_EXPORT,
    GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER,
    GET_KPI_SALES_PRE_REPORT_DETAILS,
    GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER,
    GET_KPI_SALES_PRE_REPORT_DETAILS_EXPORT,
    GET_KPI_SALES_CRM_REPORT,
    GET_KPI_SALES_CRM_REPORT_EXPORT,
    GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER,
    GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER,
    GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER,

    GET_KPI_SALES_REPORT2,
    GET_KPI_SALES_REPORT2_EXPORT,
    GET_KPI_SALES_SUMMARY_REPORT,
    GET_KPI_SALES_SUMMARY_REPORT_EXPORT,
    GET_KPI_SALES_SUMMARY_REPORT_DETAILS,
    GET_KPI_SALES_SUMMARY_REPORT_DETAILS_EXPORT,
    GET_KPI_TELECALLING_REPORT,
    GET_KPI_TELECALLING_REPORT_EXPORT,
    GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL,
    GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL_EXPORT,
    GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL,
    GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_EXPORT,
    GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS,
    GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS_EXPORT,
    GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS,
    GET_CAMPAIGN_REPORT_EXPORT,
    GET_CAMPAIGN_REPORT,
    GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN,
    GET_KPI_SALES_REPORT_SITE_VISIT_DETAILS_EXPORT,
    GET_CAMPAIGN_PROJECT_REPORT,
    GET_CAMPAIGN_PROJECT_REPORT_EXPORT,
    GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT,
    GET_CAMPAIGN_ENQUIRY_REPORT,
    GET_CAMPAIGN_ENQUIRY_REPORT_EXPORT,
    GET_CAMPAIGN_BOOKING_REPORT_EXPORT,
    GET_CAMPAIGN_BOOKING_REPORT,
    GET_CAMPAIGN_BOOKING_CANCELLED_REPORT_EXPORT,
    GET_CAMPAIGN_BOOKING_CANCELLED_REPORT,
    GET_CAMPAIGN_SITE_VISIT_REPORT_EXPORT,
    GET_CAMPAIGN_SITE_VISIT_REPORT,
    GET_FINANCIAL_YEAR,
    GET_FINANCIAL_YEAR_SCALE_TYPE,
    GET_FINANCIAL_YEAR_SCALE_VALUE,
    GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS_EXPORT,
    GET_PRE_SALES_SITE_VISIT_EXPORT,
    GET_PRE_SALES_SITE_VISIT,
    GET_KPITELECALLING_FILTER_USER,
    GET_KPI_SALES_SUMMARY_FILTER_USER,
    GET_SALES_REPORT,
    GET_SALES_REPORT_EXPORT,
    GET_SALES_REPORT_SITE_VISIT_EXPORT,
    GET_SALES_REPORT_SITE_VISIT,
    GET_SALES_REPORT_PROSPECTIVE_CLIENT_EXPORT,
    GET_SALES_REPORT_PROSPECTIVE_CLIENT,
    GET_SALES_REPORT_FOLLOW_UP_EXPORT,
    GET_SALES_REPORT_FOLLOW_UP,
    GET_SALES_REPORT_BOOKING_EXPORT,
    GET_SALES_REPORT_BOOKING,
    GET_SALES_REPORT_CANCELLATION_EXPORT,
    GET_SALES_REPORT_CANCELLATION,
    GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT_EXPORT,
    GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT,
    GET_MONTH_WEEK,
    GET_SALES_REPORT_CP_MEETING_EXPORT,
    GET_SALES_REPORT_CP_MEETING,
    GET_SALES_REPORT_CLIENT_MEETING_EXPORT,
    GET_SALES_REPORT_CLIENT_MEETING,
    GET_SALES_REPORT_TEAM_EXPORT,
    GET_SALES_REPORT_TEAM,
    GET_SALES_REPORT_FILTER_TEAM,
    GET_SALES_REPORT_FILTER_USER,
    GET_SALES_REPORT_PLANNED_FOLLOW_UP,
    GET_SALES_REPORT_PLANNED_FOLLOW_UP_EXPORT,
    GET_SALES_REPORT_PLANNED_SITE_VISIT_EXPORT,
    GET_SALES_REPORT_PLANNED_SITE_VISIT,
    GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER1,
    GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER2,
    GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT,
    GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT,
    GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT

} from './types';
import { mainLoadingTrue, mainLoadingFalse, dashBoardLoadingTrue, dashBoardLoadingFalse } from '../User/action'
import { toast } from 'react-toastify';
import { getItem, setItem } from '../../utils/crypto';

const userLocal = getItem('enuseloc');
const LoginuserId = userLocal && Object.keys(userLocal).length > 0 ? userLocal['user_id'] : null;

export const getProfitableReports_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.PROFITABLE_REPORTS, data).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(profitableReportsSuccess(data.process))
                dispatch(profitableReportCountSuccess(data.totalCount))
            }
            else {
                dispatch(profitableReportsSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(profitableReportsSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const profitableReportsSuccess = (data) => {
    return {
        type: GET_PROFITABLE_REPORTS,
        payload: data
    }
}
export const profitableReportCountSuccess = (data) => {
    return {
        type: GET_PROFITABLE_REPORT_COUNT,
        payload: data
    }
}


export const getLiasonProcessOwner_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.LIASONPROCESSOWNER, data).then(response => {
            const { data } = response;
            if (!data.error) {
                dispatch(getLiasonProcessOwnerSuccess(data.user))
            }
            else {
                dispatch(getLiasonProcessOwnerSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getLiasonProcessOwnerSuccess([]))
                dispatch(mainLoadingFalse());
            }).finally(() => {
                dispatch(mainLoadingFalse());
            })
    }
}


export const getLiasonProcessOwnerSuccess = (data) => {
    return {
        type: GET_LIASONPROCESSOWNER,
        payload: data
    }
}


export const getProjectsNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETPROJECTS, data).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getProjectsNamesSuccess(data.project))
            }
            else {
                dispatch(getProjectsNamesSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getProjectsNamesSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getProjectsNamesSuccess = (data) => {
    return {
        type: GET_PROJECT_NAMES,
        payload: data
    }
}

export const getLiaisonProjectsNames_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETLIAISONPROJECTS, data).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getLiaisonProjectsNamesSuccess(data.project))
            }
            else {
                dispatch(getLiaisonProjectsNamesSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getLiaisonProjectsNamesSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getLiaisonProjectsNamesSuccess = (data) => {
    return {
        type: GET_LIAISON_PROJECT_NAMES,
        payload: data
    }
}


export const getTaskDelayReason_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETTASKDELAY, data).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getTaskDelayReasonSuccess(data.reason))
            }
            else {
                dispatch(getTaskDelayReasonSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getTaskDelayReasonSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getTaskDelayReasonSuccess = (data) => {
    return {
        type: GET_TASK_DELAYS,
        payload: data
    }
}


export const getPendingTasks_action = (data) => {
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GETPENDINGTASK, data).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getPendingTasksSuccess(data.tasks))
            }
            else {
                dispatch(getPendingTasksSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getPendingTasksSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getPendingTasksSuccess = (data) => {
    return {
        type: GET_PENDING_TASK,
        payload: data
    }
}

export const getDashboardData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "leadType": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ApiPaths.GETDASHBOARDDATA, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardDataSuccess(data.dashboardData))
                // dispatch(getBDLandAcquisitionDataSuccess(data.dashboardData.landAcquisitionData))
                // dispatch(getBDPlanApprovalDataSuccess(data.dashboardData.planApprovalData))
                // dispatch(getBDPlanApprovedDataSuccess(data.dashboardData.planApprovedData))
            }
            else {
                dispatch(getDashboardDataSuccess([]))
                // dispatch(getBDLandAcquisitionDataSuccess([]))
                // dispatch(getBDPlanApprovalDataSuccess([]))
                // dispatch(getBDPlanApprovedDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardDataSuccess([]))
                // dispatch(getBDLandAcquisitionDataSuccess([]))
                // dispatch(getBDPlanApprovalDataSuccess([]))
                // dispatch(getBDPlanApprovedDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_DATA,
        payload: data
    }
}


export const getDashboardDataPreSales_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectId": [],
        "fromDate": "",
        "toDate": "",
        "scaleType": "",
        "reportUserId": "",
        "iDisplayStart": "",
        "iDisplayLength": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_DATA_PRE_SALES, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardDataPreSalesSuccess(data.dashboardData))
                // dispatch(getBDLandAcquisitionDataSuccess(data.dashboardData.landAcquisitionData))
                // dispatch(getBDPlanApprovalDataSuccess(data.dashboardData.planApprovalData))
                // dispatch(getBDPlanApprovedDataSuccess(data.dashboardData.planApprovedData))
            }
            else {
                dispatch(getDashboardDataPreSalesSuccess([]))
                // dispatch(getBDLandAcquisitionDataSuccess([]))
                // dispatch(getBDPlanApprovalDataSuccess([]))
                // dispatch(getBDPlanApprovedDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardDataPreSalesSuccess([]))
                // dispatch(getBDLandAcquisitionDataSuccess([]))
                // dispatch(getBDPlanApprovalDataSuccess([]))
                // dispatch(getBDPlanApprovedDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardDataPreSalesSuccess = (data) => {
    return {
        type: GET_DASHBOARD_DATA_PRE_SALES,
        payload: data
    }
}




// _________SEPERATE CHART DATA FOR LAND ACQUISITION 

export const getDashboardLandAquiredFinancialYearData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "financialYear": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getBDLandAcquisitionDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getBDLandAcquisitionDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getBDLandAcquisitionDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardApplanApprovalFinancialYearData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "financialYear": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getBDPlanApprovalDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getBDPlanApprovalDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getBDPlanApprovalDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardApplanApprovedTargetActualFinancialYearData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "financialYear": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getBDPlanApprovedDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getBDPlanApprovedDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getBDPlanApprovedDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getBDLandAcquisitionDataSuccess = (dashboardData) => {
    return {
        type: GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA,
        payload: dashboardData
    }
}

export const getBDPlanApprovalDataSuccess = (dashboardData) => {
    return {
        type: GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA,
        payload: dashboardData
    }
}

export const getBDPlanApprovedDataSuccess = (dashboardData) => {
    return {
        type: GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA,
        payload: dashboardData
    }
}





export const getMyDashBoardData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "reportUserID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MY_DASHBOARD_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getMyDashBoardDataSuccess(data))
            }
            else {
                dispatch(getMyDashBoardDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getMyDashBoardDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getMyDashBoardDataSuccess = (data) => {
    return {
        type: GET_USER_DASHBOARD_DATA,
        payload: data
    }
}

export const getMyDashBoardTask_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "reportUserID": LoginuserId,
        "projectFundID": "",
        "status": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MY_DASHBOARD_TASK, initialPayload).then(response => {
            const { data } = response;

            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getMyDashBoardTaskSuccess(data))
            }
            else {
                dispatch(getMyDashBoardTaskSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getMyDashBoardTaskSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getMyDashBoardTaskSuccess = (data) => {

    return {
        type: GET_USER_DASHBOARD_TASK,
        payload: data
    }
}


export const getCivilProcessData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectId": "",
        "processId": [],
        "fromDate": "",
        "toDate": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": 10,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_CIVIL_PROCESS_DATA, initialPayload).then(response => {
            const { data } = response;

            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getCivilProcessDataSuccess(data?.dashboardData))
            }
            else {
                dispatch(getCivilProcessDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilProcessDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilProcessDataSuccess = (data) => {

    return {
        type: GET_CIVIL_PROCESS_DATA,
        payload: data
    }
}




// For MOU Funnel

export const getDashboardAquiredButPlanApprovalPendingZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardAquiredButPlanApprovalPendingZoneDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardAquiredButPlanApprovalPendingZoneDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardAquiredButPlanApprovalPendingZoneDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardAquiredButPlanApprovalPendingZoneDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA,
        payload: data
    }
}

export const getDashboardAquiredButPlanApprovalPendingProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardAquiredButPlanApprovalPendingProjectDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardAquiredButPlanApprovalPendingProjectDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardAquiredButPlanApprovalPendingProjectDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardAquiredButPlanApprovalPendingProjectDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA,
        payload: data
    }
}

export const getDashboardAquiredButPlanApprovalPendingFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardAquiredButPlanApprovalPendingFileDataSuccess(data))
            }
            else {
                dispatch(getDashboardAquiredButPlanApprovalPendingFileDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardAquiredButPlanApprovalPendingFileDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardAquiredButPlanApprovalPendingFileDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA,
        payload: data
    }
}






// For Plan Approval Funnel

export const getDashboardPlanApprovalAppliedZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardPlanApprovalAppliedZoneDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardPlanApprovalAppliedZoneDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardPlanApprovalAppliedZoneDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardPlanApprovalAppliedZoneDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA,
        payload: data
    }
}

export const getDashboardPlanApprovalAppliedProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardPlanApprovalAppliedProjectDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardPlanApprovalAppliedProjectDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardPlanApprovalAppliedProjectDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardPlanApprovalAppliedProjectDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA,
        payload: data
    }
}

export const getDashboardPlanApprovalAppliedFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardPlanApprovalAppliedFileDataDataSuccess(data))
            }
            else {
                dispatch(getDashboardPlanApprovalAppliedFileDataDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardPlanApprovalAppliedFileDataDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardPlanApprovalAppliedFileDataDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA,
        payload: data
    }
}

export const setFunnelItemID_action = (itemID) => {
    return {
        type: SET_FUNNEL_ITEM_ID,
        payload: itemID
    }
}


//  for sale inventory

export const getDashboardStockZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_ZONE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardStockZoneDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardStockZoneDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardStockZoneDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardStockZoneDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_STOCK_ZONE_DATA,
        payload: data
    }
}

export const getDashboardStockProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_PROJECT_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardStockProjectDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardStockProjectDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardStockProjectDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardStockProjectDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_STOCK_PROJECT_DATA,
        payload: data
    }
}

export const getDashboardStockProjectSiteData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_PROJECT_SITE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardStockProjectSiteDataSuccess(data))
            }
            else {
                dispatch(getDashboardStockProjectSiteDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardStockProjectSiteDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardStockProjectSiteDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_STOCK_PROJECT_SITE_DATA,
        payload: data
    }
}

export const getDashboardStockAgingZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "financialYear": "",
        "projectZoneID": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_AGING_ZONE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardStockAgingZoneDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardStockAgingZoneDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardStockAgingZoneDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardStockAgingZoneDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_STOCK_AGING_ZONE_DATA,
        payload: data
    }
}


export const getDashboardStockAgingProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "financialYear": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_AGING_PROJECT_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardStockAgingProjectDataSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardStockAgingProjectDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardStockAgingProjectDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardStockAgingProjectDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_STOCK_AGING_PROJECT_DATA,
        payload: data
    }
}

export const getDashboardStockAgingFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "projectID": [],
        "financialYear": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_STOCK_AGING_FILE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardStockAgingFileDataSuccess(data))
            }
            else {
                dispatch(getDashboardStockAgingFileDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardStockAgingFileDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardStockAgingFileDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_STOCK_AGING_FILE_DATA,
        payload: data
    }
}
export const getDashboardLandAquiredZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "financialYearMonth": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardLandAquiredZoneDataSuccess(data?.dashboardData))
            }
            else {
                dispatch(getDashboardLandAquiredZoneDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardLandAquiredZoneDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardLandAquiredZoneDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA,
        payload: data
    }
}
export const getDashboardLandAquiredProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "projectID": [],
        "financialYearMonth": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {

                dispatch(getDashboardLandAquiredProjectDataSuccess(data?.dashboardData))
            }
            else {
                dispatch(getDashboardLandAquiredProjectDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardLandAquiredProjectDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardLandAquiredProjectDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA,
        payload: data
    }
}

export const getDashboardLandAquiredFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "projectID": [],
        "financialYearMonth": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_LAND_AQUIRED_FILE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardLandAquiredFileDataSuccess(data))
            }
            else {
                dispatch(getDashboardLandAquiredFileDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardLandAquiredFileDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardLandAquiredFileDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_LAND_AQUIRED_FILE_DATA,
        payload: data
    }
}

export const getDashboardApplanApprovalZoneData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "financialYearMonth": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardApplanApprovalZoneDataSuccess(data?.dashboardData))
            }
            else {
                dispatch(getDashboardApplanApprovalZoneDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardApplanApprovalZoneDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardApplanApprovalZoneDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA,
        payload: data
    }
}
export const getDashboardApplanApprovalProjectData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "projectID": [],
        "financialYearMonth": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {

                dispatch(getDashboardApplanApprovalProjectDataSuccess(data?.dashboardData))
            }
            else {
                dispatch(getDashboardApplanApprovalProjectDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardApplanApprovalProjectDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardApplanApprovalProjectDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA,
        payload: data
    }
}
export const getDashboardApplanApprovalFileData_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectZoneID": [],
        "projectID": [],
        "financialYearMonth": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardApplanApprovalFileDataSuccess(data))
            }
            else {
                dispatch(getDashboardApplanApprovalFileDataSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardApplanApprovalFileDataSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardApplanApprovalFileDataSuccess = (data) => {
    return {
        type: GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA,
        payload: data
    }
}

export const getDashboardDataDepartment_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_DATA_DEPARTMENT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardDataDepartmentSuccess(data))
            }
            else {
                dispatch(getDashboardDataDepartmentSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardDataDepartmentSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardDataDepartmentSuccess = (data) => {
    return {
        type: GET_DASHBOARD_DATA_DEPARTMENT,
        payload: data
    }
}

export const getDashboardDepartment_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_DEPARTMENT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardDepartmentSuccess(data))
            }
            else {
                dispatch(getDashboardDepartmentSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardDepartmentSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardDepartmentSuccess = (data) => {
    return {
        type: GET_DASHBOARD_DEPARTMENT,
        payload: data
    }
}

export const getLiaisonProcessPlanReport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "processID": "",
        "processOwnerID": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "",
        "iDisplayLength": "10",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_LIAISON_PROCESS_PLAN_REPORT, initialPayload).then(response => {

            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getLiaisonProcessPlanReportSuccess(data))
            }
            else {
                dispatch(getLiaisonProcessPlanReportSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getLiaisonProcessPlanReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getLiaisonProcessPlanReportSuccess = (data) => {
    return {
        type: GET_LIAISON_PROCESS_PLAN_REPORT,
        payload: data
    }
}

export const getLiaisonProcessPlanReportDetail_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "processPlanID": "",
        "projectID": "",
        "projectFileID": "",
        "processID": "",
        "completionStatus": "",
        "applicabilityStatus": "",
        "processOwnerID": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getLiaisonProcessPlanReportDetailSuccess(data))
            }
            else {
                dispatch(getLiaisonProcessPlanReportDetailSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getLiaisonProcessPlanReportDetailSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getLiaisonProcessPlanReportDetailSuccess = (data) => {
    return {
        type: GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL,
        payload: data
    }
}

export const getMyDashBoardDataFilterUser_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MY_DASHBOARD_DATA_FILTER_USER, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getMyDashBoardDataFilterUserSuccess(data.data))
            }
            else {
                dispatch(getMyDashBoardDataFilterUserSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getMyDashBoardDataFilterUserSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getMyDashBoardDataFilterUserSuccess = (data) => {
    return {
        type: GET_MY_DASHBOARD_DATA_FILTER_USER,
        payload: data
    }
}

export const getDashboardCrmProjectOutstanding_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectId": [],
        "reportType": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_OUTSTANDING, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmProjectOutstandingSuccess(data))
            }
            else {
                dispatch(getDashboardCrmProjectOutstandingSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmProjectOutstandingSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getDashboardCrmProjectOutstandingSuccess = (data) => {
    return {
        type: GET_DASHBOARD_CRM_PROJECT_OUTSTANDING,
        payload: data
    }
}

export const getDashboardCrmProjectOutstandingExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectId": [],
        "reportType": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_OUTSTANDING, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmProjectOutstandingExportSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmProjectOutstandingExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmProjectOutstandingExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getDashboardCrmProjectOutstandingExportSuccess = (data) => {
    return {
        type: GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT,
        payload: data
    }
}

export const getDashboardCrmSiteOutstanding_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "reportType": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_SITE_OUTSTANDING, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmSiteOutstandingSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmSiteOutstandingSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmSiteOutstandingSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmSiteOutstandingSuccess = (data) => {
    return {
        type: GET_DASHBOARD_CRM_SITE_OUTSTANDING,
        payload: data
    }
}

export const getDashboardCrmSiteOutstandingExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "reportType": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_SITE_OUTSTANDING, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmProjectTarget_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectId": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_TARGET, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmProjectTargetSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmProjectTargetSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmProjectTargetSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmProjectTargetSuccess = (data) => {
    return {
        type: GET_DASHBOARD_CRM_PROJECT_TARGET,
        payload: data
    }
}

export const getDashboardCrmSiteTarget_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOEAR_CRM_SITE_TARGET, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmSiteTargetSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmSiteTargetSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmSiteTargetSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmSiteTargetSuccess = (data) => {
    return {
        type: GET_DASHBOEAR_CRM_SITE_TARGET,
        payload: data
    }
}

export const getDashboardCrmProjectReceived_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_RECEIVED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmProjectReceivedSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmSiteTargetSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmProjectReceivedSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmProjectReceivedSuccess = (data) => {
    return {
        type: GET_DASHBOARD_CRM_PROJECT_RECEIVED,
        payload: data
    }
}

export const getDashboardCrmSiteReceived_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_SITE_RECEIVED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmSiteReceivedSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmSiteReceivedSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmSiteReceivedSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmSiteReceivedSuccess = (data) => {
    return {
        type: GET_DASHBOARD_CRM_SITE_RECEIVED,
        payload: data
    }
}

export const getDashboardCrmProjectRealized_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_REALIZED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmProjectRealizedSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmProjectRealizedSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmProjectRealizedSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmProjectRealizedSuccess = (data) => {
    return {
        type: GET_DASHBOARD_CRM_PROJECT_REALIZED,
        payload: data
    }
}

export const getDashboardCrmSiteRealized_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DAHSBOARD_CRM_SITE_REALIZED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmSiteRealizedSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmSiteRealizedSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmSiteRealizedSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmSiteRealizedSuccess = (data) => {
    return {
        type: GET_DAHSBOARD_CRM_SITE_REALIZED,
        payload: data
    }
}

export const getDashboardCrmProjectTargetExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectId": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_TARGET, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}
export const getDashboardCrmSiteTargetExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOEAR_CRM_SITE_TARGET, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmProjectReceivedExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_RECEIVED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}
export const getDashboardCrmSiteReceivedExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_SITE_RECEIVED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmProjectRealizedExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_REALIZED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}
export const getDashboardCrmSiteRealizedExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DAHSBOARD_CRM_SITE_REALIZED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}

// -------

export const getDashboardCrmProjectNotRealized_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmProjectNotRealizedSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmProjectNotRealizedSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmProjectNotRealizedSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmProjectNotRealizedSuccess = (data) => {
    return {
        type: GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED,
        payload: data
    }
}

export const getDashboardCrmProjectNotRealizedExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmSiteNotRealized_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DAHSBOARD_CRM_SITE_NOT_REALIZED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardCrmSiteNotRealizedSuccess(data))
                return data;
            }
            else {
                dispatch(getDashboardCrmSiteNotRealizedSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardCrmSiteNotRealizedSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardCrmSiteNotRealizedSuccess = (data) => {
    return {
        type: GET_DAHSBOARD_CRM_SITE_NOT_REALIZED,
        payload: data
    }
}

export const getDashboardCrmSiteNotRealizedExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": [],
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DAHSBOARD_CRM_SITE_NOT_REALIZED, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
            })
    }
}


export const getDashboardDataMarketingFunnel_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "department": "",
        "projectId": [],
        "fromDate": "",
        "toDate": "",
        "leadType": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_DATA_MARKETING_FUNNEL, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardDataMarketingFunnelSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardDataMarketingFunnelSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardDataMarketingFunnelSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardDataMarketingFunnelSuccess = (dashboardData) => {
    return {
        type: GET_DASHBOARD_DATA_MARKETING_FUNNEL,
        payload: dashboardData
    }
}

// nikhil

export const getGRNOnTimeKPIReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_GRN_ON_TIME_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getGRNOnTimeKPIReportSuccess(data))
                return data;
            }
            else {
                dispatch(getGRNOnTimeKPIReportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getGRNOnTimeKPIReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getGRNOnTimeKPIReportSuccess = (data) => {
    return {
        type: GET_GRN_ON_TIME_REPORT,
        payload: data
    }
}

export const getGRNOnTimeKPIReportExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_GRN_ON_TIME_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getGRNOnTimeKPIReportExportSuccess(data))
                return data;
            }
            else {
                dispatch(getGRNOnTimeKPIReportExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getGRNOnTimeKPIReportExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getGRNOnTimeKPIReportExportSuccess = (data) => {
    return {
        type: GET_GRN_ON_TIME_REPORT_EXPORT,
        payload: data
    }
}


export const getVendorLeadTimeKPIReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_VENDOR_LEAD_TIME_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getVendorLeadTimeKPIReportSuccess(data))
                return data;
            }
            else {
                dispatch(getVendorLeadTimeKPIReportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getVendorLeadTimeKPIReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getVendorLeadTimeKPIReportSuccess = (data) => {
    return {
        type: GET_VENDOR_LEAD_TIME_REPORT,
        payload: data
    }
}


export const getVendorLeadTimeKPIReportExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_VENDOR_LEAD_TIME_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getVendorLeadTimeKPIReportExportSuccess(data))
                return data;
            }
            else {
                dispatch(getVendorLeadTimeKPIReportExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getVendorLeadTimeKPIReportExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getVendorLeadTimeKPIReportExportSuccess = (data) => {
    return {
        type: GET_VENDOR_LEAD_TIME_REPORT_EXPORT,
        payload: data
    }
}


export const getStockProject_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "statusID": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_STOCK_PROJECT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getStockProjectSuccess(data))
                return data;
            }
            else {
                dispatch(getStockProjectSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getStockProjectSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getStockProjectSuccess = (data) => {
    return {
        type: GET_STOCK_PROJECT,
        payload: data
    }
}






export const getPurchaseInvoiceOnTimeKPI_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //     "projectID":"",
        //     "fromDate":"",
        // "toDate": "",
        //     "sortOrder":"",
        //     "generalSearch":"",
        //     "iDisplayStart":"0",
        //     "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_PURCHASE_INVOICE_ON_TIME_KPI, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getPurchaseInvoiceOnTimeKPISuccess(data))
                return data;
            }
            else {
                dispatch(getPurchaseInvoiceOnTimeKPISuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getPurchaseInvoiceOnTimeKPISuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getPurchaseInvoiceOnTimeKPISuccess = (data) => {
    return {
        type: GET_PURCHASE_INVOICE_ON_TIME_KPI,
        payload: data
    }
}


export const getPurchaseInvoiceOnTimeKPIExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_PURCHASE_INVOICE_ON_TIME_KPI, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getPurchaseInvoiceOnTimeKPIExportSuccess(data))
                return data;
            }
            else {
                dispatch(getPurchaseInvoiceOnTimeKPIExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getPurchaseInvoiceOnTimeKPIExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getPurchaseInvoiceOnTimeKPIExportSuccess = (data) => {
    return {
        type: GET_PURCHASE_INVOICE_ON_TIME_KPI_EXPORT,
        payload: data
    }
}



export const getPurchaseInvoiceOnTimeKPIReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //     "projectID":"",
        //     "fromDate":"",
        // "toDate": "",
        //     "sortOrder":"",
        //     "generalSearch":"",
        //     "iDisplayStart":"0",
        //     "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getPurchaseInvoiceOnTimeKPIReportSuccess(data))
                return data;
            }
            else {
                dispatch(getPurchaseInvoiceOnTimeKPIReportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getPurchaseInvoiceOnTimeKPIReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getPurchaseInvoiceOnTimeKPIReportSuccess = (data) => {
    return {
        type: GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT,
        payload: data
    }
}


export const getPurchaseInvoiceOnTimeKPIReportExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getPurchaseInvoiceOnTimeKPIReportExportSuccess(data))
                return data;
            }
            else {
                dispatch(getPurchaseInvoiceOnTimeKPIReportExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getPurchaseInvoiceOnTimeKPIReportExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getPurchaseInvoiceOnTimeKPIReportExportSuccess = (data) => {
    return {
        type: GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT_EXPORT,
        payload: data
    }
}



export const getMaterialQuantityRejectionKPI_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //     "projectID":"",
        //     "fromDate":"",
        // "toDate": "",
        //     "sortOrder":"",
        //     "generalSearch":"",
        //     "iDisplayStart":"0",
        //     "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MATERIAL_QUANTITY_REJECTION_KPI, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getMaterialQuantityRejectionKPISuccess(data))
                return data;
            }
            else {
                dispatch(getMaterialQuantityRejectionKPISuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getMaterialQuantityRejectionKPISuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getMaterialQuantityRejectionKPISuccess = (data) => {
    return {
        type: GET_MATERIAL_QUANTITY_REJECTION_KPI,
        payload: data
    }
}


export const getMaterialQuantityRejectionKPIExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MATERIAL_QUANTITY_REJECTION_KPI, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getMaterialQuantityRejectionKPIExportSuccess(data))
                return data;
            }
            else {
                dispatch(getMaterialQuantityRejectionKPIExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getMaterialQuantityRejectionKPIExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getMaterialQuantityRejectionKPIExportSuccess = (data) => {
    return {
        type: GET_MATERIAL_QUANTITY_REJECTION_KPI_EXPORT,
        payload: data
    }
}




export const getMaterialQuantityRejectionKPIReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //     "projectID":"",
        //     "fromDate":"",
        // "toDate": "",
        //     "sortOrder":"",
        //     "generalSearch":"",
        //     "iDisplayStart":"0",
        //     "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getMaterialQuantityRejectionKPIReportSuccess(data))
                return data;
            }
            else {
                dispatch(getMaterialQuantityRejectionKPIReportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getMaterialQuantityRejectionKPIReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getMaterialQuantityRejectionKPIReportSuccess = (data) => {
    return {
        type: GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT,
        payload: data
    }
}


export const getMaterialQuantityRejectionKPIReportExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getMaterialQuantityRejectionKPIReportExportSuccess(data))
                return data;
            }
            else {
                dispatch(getMaterialQuantityRejectionKPIReportExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getMaterialQuantityRejectionKPIReportExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getMaterialQuantityRejectionKPIReportExportSuccess = (data) => {
    return {
        type: GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT_EXPORT,
        payload: data
    }
}

export const getIndentPurchaseOrderKPIReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        //     "projectID":"",
        //     "fromDate":"",
        // "toDate": "",
        //     "sortOrder":"",
        //     "generalSearch":"",
        //     "iDisplayStart":"0",
        //     "iDisplayLength":"-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_INDENT_PURCHASE_ORDER_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getIndentPurchaseOrderKPIReportSuccess(data))
                return data;
            }
            else {
                dispatch(getIndentPurchaseOrderKPIReportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getIndentPurchaseOrderKPIReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getIndentPurchaseOrderKPIReportSuccess = (data) => {
    return {
        type: GET_INDENT_PURCHASE_ORDER_KPI_REPORT,
        payload: data
    }
}


export const getIndentPurchaseOrderKPIReportExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_INDENT_PURCHASE_ORDER_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getIndentPurchaseOrderKPIReportExportSuccess(data))
                return data;
            }
            else {
                dispatch(getIndentPurchaseOrderKPIReportExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getIndentPurchaseOrderKPIReportExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getIndentPurchaseOrderKPIReportExportSuccess = (data) => {
    return {
        type: GET_INDENT_PURCHASE_ORDER_KPI_REPORT_EXPORT,
        payload: data
    }
}

export const getDashboardDataRegistrationTurnaroundTime_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectId": [],
        "fromDate": "",
        "toDate": "",
        "reportUserId": "",
        "reportType": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ApiPaths.GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                dispatch(getDashboardDataRegistrationTurnaroundTimeSuccess(data.dashboardData))
            }
            else {
                dispatch(getDashboardDataRegistrationTurnaroundTimeSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getDashboardDataRegistrationTurnaroundTimeSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getDashboardDataRegistrationTurnaroundTimeSuccess = (data) => {
    return {
        type: GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME,
        payload: data
    }
}

export const getRegistrationTurnaroundTimeReport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "fromDate": "",
        "toDate": "",
        "projectId": [],
        "reportUserId": "",
        "reportType": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "10",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ApiPaths.GET_REGISTRATION_TURNAROUND_TIME_REPORT, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                dispatch(getRegistrationTurnaroundTimeReportSuccess(data))
            }
            else {
                dispatch(getRegistrationTurnaroundTimeReportSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getRegistrationTurnaroundTimeReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getRegistrationTurnaroundTimeReportSuccess = (data) => {
    return {
        type: GET_REGISTRATION_TURNAROUND_TIME_REPORT,
        payload: data
    }
}

export const getRegistrationTurnaroundTimeReportFilterProject_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "fromDate": "",
        "toDate": "",
        "projectId": [],
        "reportUserId": "",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ApiPaths.GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                dispatch(getRegistrationTurnaroundTimeReportFilterProjectSuccess(data.dashboardData))
            }
            else {
                dispatch(getRegistrationTurnaroundTimeReportFilterProjectSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getRegistrationTurnaroundTimeReportFilterProjectSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getRegistrationTurnaroundTimeReportFilterProjectSuccess = (data) => {
    return {
        type: GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT,
        payload: data
    }
}

export const getRegistrationTurnaroundTimeReportFilterUser_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "fromDate": "",
        "toDate": "",
        "projectId": [],
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ApiPaths.GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                dispatch(getRegistrationTurnaroundTimeReportFilterUserSuccess(data?.dashboardData))
            }
            else {
                dispatch(getRegistrationTurnaroundTimeReportFilterUserSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getRegistrationTurnaroundTimeReportFilterUserSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getRegistrationTurnaroundTimeReportFilterUserSuccess = (data) => {
    return {
        type: GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER,
        payload: data
    }
}

export const getRegistrationTurnaroundTimeReportExport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "fromDate": "",
        "toDate": "",
        "projectId": [],
        "reportUserId": "",
        "reportType": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ApiPaths.GET_REGISTRATION_TURNAROUND_TIME_REPORT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                return data;
            }
            else {
                return []
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
                return [];
            })
    }
}

export const getCivilResourceReport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "taskID": "",
        "categoryID": "",
        "taskOwnerID": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": 10,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ErpApi.GET_CIVIL_RESOURCE_REPORT, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                dispatch(getCivilResourceReportSuccess(data))
            }
            else {
                dispatch(getCivilResourceReportSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilResourceReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResourceReportSuccess = (data) => {
    return {
        type: GET_CIVIL_RESOURCE_REPORT,
        payload: data
    }
}

export const getCivilResourceReportExport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "taskID": "",
        "categoryID": "",
        "taskOwnerID": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ErpApi.GET_CIVIL_RESOURCE_REPORT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
            else {
                return [];
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
                return [];
            })
    }
}

export const getOnTimeDeliveryKPI_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",

        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_ON_TIME_DELIVERY_KPI, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getOnTimeDeliveryKPISuccess(data))
                return data;
            }
            else {
                dispatch(getOnTimeDeliveryKPISuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getOnTimeDeliveryKPISuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResourceReportNonMaterial_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "taskID": "",
        "categoryID": "",
        "taskOwnerID": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": 10,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ErpApi.GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                dispatch(getCivilResourceReportNonMaterialSuccess(data))
            }
            else {
                dispatch(getCivilResourceReportNonMaterialSuccess([]))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCivilResourceReportNonMaterialSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCivilResourceReportNonMaterialSuccess = (data) => {
    return {
        type: GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL,
        payload: data
    }
}

export const getCivilResourceReportNonMaterialExport_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "taskID": "",
        "categoryID": "",
        "taskOwnerID": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": 0,
        "iDisplayLength": -1,
        ...data
    }

    return dispatch => {
        dispatch(mainLoadingTrue());
        dispatch(dashBoardLoadingTrue());
        return ApiService.post(ErpApi.GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL, initialPayload).then(response => {
            const { data } = response;
            // console.log('DashboardData', data.dashboardData);
            dispatch(mainLoadingFalse());
            dispatch(dashBoardLoadingFalse());
            if (!data.error) {
                return data;
            }
            else {
                return [];
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(mainLoadingFalse());
                return [];
            })
    }
}

export const getOnTimeDeliveryKPISuccess = (data) => {
    return {
        type: GET_ON_TIME_DELIVERY_KPI,
        payload: data
    }
}


export const getOnTimeDeliveryKPIExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_ON_TIME_DELIVERY_KPI, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getOnTimeDeliveryKPIReportExportSuccess(data))
                return data;
            }
            else {
                dispatch(getOnTimeDeliveryKPIReportExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getOnTimeDeliveryKPIReportExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getOnTimeDeliveryKPIReportExportSuccess = (data) => {
    return {
        type: GET_ON_TIME_DELIVERY_KPI_EXPORT,
        payload: data
    }
}

export const getOnTimeDeliveryKPIReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "reportType": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_ON_TIME_DELIVERY_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getOnTimeDeliveryKPIReportSuccess(data))
                return data;
            }
            else {
                dispatch(getOnTimeDeliveryKPIReportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getOnTimeDeliveryKPIReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getOnTimeDeliveryKPIReportSuccess = (data) => {
    return {
        type: GET_ON_TIME_DELIVERY_KPI_REPORT,
        payload: data
    }
}


export const getOnTimeDeliveryKPIReportExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_ON_TIME_DELIVERY_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getOnTimeDeliveryKPIReportsExportSuccess(data))
                return data;
            }
            else {
                dispatch(getOnTimeDeliveryKPIReportsExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getOnTimeDeliveryKPIReportsExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}


export const getOnTimeDeliveryKPIReportsExportSuccess = (data) => {
    return {
        type: GET_ON_TIME_DELIVERY_KPI_REPORT_EXPORT,
        payload: data
    }
}

export const getProcurmentKpiReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_PROCUREMENT_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getProcurmentKpiReportSuccess(data))
                return data;
            }
            else {
                dispatch(getProcurmentKpiReportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getProcurmentKpiReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getProcurmentKpiReportSuccess = (data) => {
    return {
        type: GET_PROCUREMENT_KPI_REPORT,
        payload: data
    }
}

export const getProcurmentKpiReportExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "fromDate": "",
        "toDate": "",
        "sortOrder": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_PROCUREMENT_KPI_REPORT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                return data;
            }
            else {
                return [];
            }
        })
            .catch(err => {
                console.log("err", err);
                dispatch(mainLoadingFalse());
                return [];
            })
    }
}

export const getSalesCrmReport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "generalSearch": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_SALES_CRM_REPORT, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getSalesCrmReportSuccess(data))
                return data;
            }
            else {
                dispatch(getSalesCrmReportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getSalesCrmReportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getSalesCrmReportSuccess = (data) => {
    return {
        type: GET_SALES_CRM_REPORT,
        payload: data
    }
}
export const getSalesCrmReportExport_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        // dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_SALES_CRM_REPORT, initialPayload).then(response => {
            const { data } = response;
            // dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getSalesCrmReportExportSuccess(data))
                return data;
            }
            else {
                dispatch(getSalesCrmReportExportSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getSalesCrmReportExportSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}
export const getSalesCrmReportExportSuccess = (data) => {
    return {
        type: GET_SALES_CRM_REPORT_EXPORT,
        payload: data
    }
}
export const getCrmProjects_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,

        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_CRM_PROJECTS, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getCrmProjectsSuccess(data))
                return data;
            }
            else {
                dispatch(getCrmProjectsSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCrmProjectsSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCrmProjectsSuccess = (data) => {
    return {
        type: GET_CRM_PROJECTS,
        payload: data
    }
}

export const getScaleType_action = (data) => {

    const initialPayload = {
        "userID": LoginuserId,

        ...data
    };

    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_SCALE_TYPE, initialPayload).then((response) => {
            const { data } = response;
            dispatch(mainLoadingFalse());

            if (!data.error) {
                dispatch(getScaleTypeSuccess(data));
            } else {
                dispatch(getScaleTypeSuccess([]));
            }
        })
            .catch((err) => {
                console.log("err", err);
                dispatch(getScaleTypeSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getScaleTypeSuccess = (data) => {
    return {
        type: GET_SCALE_TYPE,
        payload: data,
    };
}



export const getCrmSite_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "statusID": "",
        "generalSearch": "",
        "sortOrder": "",
        "iDisplayStart": "0",
        "iDisplayLength": "-1",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_CRM_SITE, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getCrmSiteSuccess(data))
                return data;
            }
            else {
                dispatch(getCrmSiteSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCrmSiteSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCrmSiteSuccess = (data) => {
    return {
        type: GET_CRM_SITE,
        payload: data
    }
}

export const getCrmSiteDimension_action = (data) => {
    const initialPayload = {
        "userID": LoginuserId,
        "projectID": "",
        "siteID": "",
        ...data
    }
    return dispatch => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_CRM_SITE_DIMENSION, initialPayload).then(response => {
            const { data } = response;
            dispatch(mainLoadingFalse());
            if (!data.error) {
                dispatch(getCrmSiteDimensionSuccess(data))
                return data;
            }
            else {
                dispatch(getCrmSiteDimensionSuccess({}))
            }
        })
            .catch(err => {
                console.log("err", err)
                dispatch(getCrmSiteDimensionSuccess([]))
                dispatch(mainLoadingFalse());
            })
    }
}

export const getCrmSiteDimensionSuccess = (data) => {
    return {
        type: GET_CRM_SITE_DIMENSION,
        payload: data
    }
}

export const getKpiSalesReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        projectID: "",
        salesExecutiveID: "",
        fromDate: "",
        toDate: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_REPORT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKpiSalesReportSuccess(data));
                    return data;
                } else {
                    dispatch(getKpiSalesReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKpiSalesReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKpiSalesReportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT,
        payload: data,
    };
};

//export action
export const getKpiSalesReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        projectID: "",
        siteID: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_REPORT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKpiSalesReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getKpiSalesReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKpiSalesReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKpiSalesReportExportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_EXPORT,
        payload: data,
    };
};

export const getKPISalesReportExecutiveFilter_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        fromDate: "",
        toDate: "",

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_EXECUTIVE_FILTER,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportExecutiveFilterSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportExecutiveFilterSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportExecutiveFilterSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportExecutiveFilterSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_EXECUTIVE_FILTER,
        payload: data,
    };
};

export const getKPISalesReportDetail_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_DETAILS,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportDetailSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportDetailSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportDetailSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportDetailSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_DETAILS,
        payload: data,
    };
};

export const getKPISalesReportDetailProjectFilter_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        fromDate: "",
        toDate: "",

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportDetailProjectFilterSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportDetailProjectFilterSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportDetailProjectFilterSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportDetailProjectFilterSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER,
        payload: data,
    };
};

export const getKPISalesReportDetailExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_DETAILS,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportDetailExport(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportDetailExport({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportDetailExport([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportDetailExport = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_DETAILS_EXPORT,
        payload: data,
    };
};

//Kpi  pre sales Report

export const getKPIPreSalesReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_PRE_REPORT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPIPreSalesReport(data));
                    return data;
                } else {
                    dispatch(getKPIPreSalesReport({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPIPreSalesReport([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPIPreSalesReport = (data) => {
    return {
        type: GET_KPI_PRE_SALES_REPORT,
        payload: data,
    };
};
//export
export const getKPIPreSalesReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_PRE_REPORT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPIPreSalesReportExport(data));
                    return data;
                } else {
                    dispatch(getKPIPreSalesReportExport({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPIPreSalesReportExport([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPIPreSalesReportExport = (data) => {
    return {
        type: GET_KPI_PRE_SALES_REPORT_EXPORT,
        payload: data,
    };
};
//filter
export const getKPIPreSalesReportExecutiveFilter_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPIPreSalesReportExecutiveFilter(data));
                    return data;
                } else {
                    dispatch(getKPIPreSalesReportExecutiveFilter({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPIPreSalesReportExecutiveFilter([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPIPreSalesReportExecutiveFilter = (data) => {
    return {
        type: GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER,
        payload: data,
    };
};

//details

export const getKPIPreSalesReportDetails_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_PRE_REPORT_DETAILS,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPIPreSalesReportDetails(data));
                    return data;
                } else {
                    dispatch(getKPIPreSalesReportDetails({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPIPreSalesReportDetails([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPIPreSalesReportDetails = (data) => {
    return {
        type: GET_KPI_SALES_PRE_REPORT_DETAILS,
        payload: data,
    };
};
//project Filter

export const getKPIPreSalesReportProjectFilter_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPIPreSalesReportProjectFilter(data));
                    return data;
                } else {
                    dispatch(getKPIPreSalesReportProjectFilter({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPIPreSalesReportProjectFilter([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPIPreSalesReportProjectFilter = (data) => {
    return {
        type: GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER,
        payload: data,
    };
};

//export Pre Sales report

export const getKPIPreSalesReportDetailsExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_PRE_REPORT_DETAILS,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPIPreSalesReportDetailsExport(data));
                    return data;
                } else {
                    dispatch(getKPIPreSalesReportDetailsExport({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPIPreSalesReportDetailsExport([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPIPreSalesReportDetailsExport = (data) => {
    return {
        type: GET_KPI_SALES_PRE_REPORT_DETAILS_EXPORT,
        payload: data,
    };
};

//report sales report crm

export const getKPISalesCrmReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_CRM_REPORT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesCrmReportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesCrmReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesCrmReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesCrmReportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_CRM_REPORT,
        payload: data,
    };
};

//filter
export const getKPISalesCrmReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_CRM_REPORT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesCrmReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesCrmReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesCrmReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesCrmReportExportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_CRM_REPORT_EXPORT,
        payload: data,
    };
};

//Report sales Crm Sales

export const getKPISalesCrmReportProjectFilter_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesCrmReportProjectFilterSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesCrmReportProjectFilterSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesCrmReportProjectFilterSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesCrmReportProjectFilterSuccess = (data) => {
    return {
        type: GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER,
        payload: data,
    };
};

//source Filter

export const getKPISalesCrmReportSourceFilter_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesCrmReportSourceFilterSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesCrmReportSourceFilterSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesCrmReportSourceFilterSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesCrmReportSourceFilterSuccess = (data) => {
    return {
        type: GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER,
        payload: data,
    };
};

export const getKPISalesCrmReportSalesExecutiveFilter_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesCrmReportSalesExecutiveFilterSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesCrmReportSalesExecutiveFilterSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesCrmReportSalesExecutiveFilterSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesCrmReportSalesExecutiveFilterSuccess = (data) => {
    return {
        type: GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER,
        payload: data,
    };
};

export const getKpiSalesReport2_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        projectID: "",
        salesExecutiveID: "",
        fromDate: "",
        toDate: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_REPORT2, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKpiSalesReport2Success(data));
                    return data;
                } else {
                    dispatch(getKpiSalesReport2Success({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKpiSalesReport2Success([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKpiSalesReport2Success = (data) => {
    return {
        type: GET_KPI_SALES_REPORT2,
        payload: data,
    };
};



export const getKpiSalesReport2Export_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_REPORT2, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKpiSalesReport2ExportSuccess(data));
                    return data;
                } else {
                    dispatch(getKpiSalesReport2ExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKpiSalesReport2ExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKpiSalesReport2ExportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT2_EXPORT,
        payload: data,
    };
};

// sales summnay report






export const getKPISalesSummaryReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_SUMMARY_REPORT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesSummaryReportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesSummaryReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesSummaryReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesSummaryReportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_SUMMARY_REPORT,
        payload: data,
    };
};






export const getKPISalesSummaryReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_SUMMARY_REPORT, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesSummaryReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesSummaryReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesSummaryReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesSummaryReportExportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_SUMMARY_REPORT_EXPORT,
        payload: data,
    };
};

//details

export const getKPISalesSummaryReportDetails_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_SUMMARY_REPORT_DETAILS, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesSummaryReportDetailsSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesSummaryReportDetailsSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesSummaryReportDetailsSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesSummaryReportDetailsSuccess = (data) => {
    return {
        type: GET_KPI_SALES_SUMMARY_REPORT_DETAILS,
        payload: data,
    };
};


export const getKPISalesSummaryReportDetailsExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_SUMMARY_REPORT_DETAILS, initialPayload)
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesSummaryReportDetailsExportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesSummaryReportDetailsExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesSummaryReportDetailsExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesSummaryReportDetailsExportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_SUMMARY_REPORT_DETAILS_EXPORT,
        payload: data,
    };
};
export const getKPITelecallingReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_TELECALLING_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPITelecallingReportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPITelecallingReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPITelecallingReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPITelecallingReportSuccess = (data) => {
    return {
        type: GET_KPI_TELECALLING_REPORT,
        payload: data,
    };
};






export const getKPITelecallingReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_TELECALLING_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPITelecallingReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPITelecallingReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPITelecallingReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPITelecallingReportExportSuccess = (data) => {
    return {
        type: GET_KPI_TELECALLING_REPORT_EXPORT,
        payload: data,
    };
};

export const getKPIPreSalesReportLeadAssignedDetail_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPIPreSalesReportLeadAssignedDetailSuccess(data));
                    return data;
                } else {
                    dispatch(getKPIPreSalesReportLeadAssignedDetailSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPIPreSalesReportLeadAssignedDetailSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPIPreSalesReportLeadAssignedDetailSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL,
        payload: data,
    };
};


export const getKPIPreSalesReportLeadAssignedDetailExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPIPreSalesReportLeadAssignedDetailExportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPITelecallingReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPIPreSalesReportLeadAssignedDetailExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPIPreSalesReportLeadAssignedDetailExportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL_EXPORT,
        payload: data,
    };
};





export const getKPISalesReportSiteVisitDetail_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportSiteVisitDetailSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportSiteVisitDetailSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportSiteVisitDetailSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportSiteVisitDetailSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL,
        payload: data,
    };
};

export const getKPISalesReportSiteVisitDetailExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportSiteVisitDetailSuccessExport(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportSiteVisitDetailSuccessExport({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportSiteVisitDetailSuccessExport([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportSiteVisitDetailSuccessExport = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_SITE_VISIT_DETAILS_EXPORT,
        payload: data,
    };
};


export const getKPISalesReportCrmBookingVisitDetail_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportCrmBookingDetailSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportCrmBookingDetailSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportCrmBookingDetailSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportCrmBookingDetailSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS,
        payload: data,
    };
};




export const getKPISalesReportCrmBookingVisitDetailExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportCrmBookingDetailExportSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportCrmBookingDetailExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportCrmBookingDetailExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportCrmBookingDetailExportSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS_EXPORT,
        payload: data,
    };
};





export const getKPISalesReportCrmCancellationDetail_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,

        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportCrmCancellationDetail(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportCrmCancellationDetail({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportCrmCancellationDetail([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportCrmCancellationDetail = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS,
        payload: data,
    };
};



export const getCampaignReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignReportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignReportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_REPORT,
        payload: data,
    };
};



export const getCampaignReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignReportExportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_REPORT_EXPORT,
        payload: data,
    };
};



export const getCampaignReportFilterCampaign_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        fromDate: "",
        toDate: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignReportFilterCampaignSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignReportFilterCampaignSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignReportFilterCampaignSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignReportFilterCampaignSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN,
        payload: data,
    };
};


export const getCampaignProjectReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_PROJECT_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignProjectReportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignProjectReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignProjectReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignProjectReportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_PROJECT_REPORT,
        payload: data,
    };
};



export const getCampaignProjectReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_PROJECT_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignProjectReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignProjectReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignProjectReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignProjectReportExportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_PROJECT_REPORT_EXPORT,
        payload: data,
    };
};


export const getCampaignProjectReportFilterProject_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignProjectReportFilterProjectSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignProjectReportFilterProjectSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignProjectReportFilterProjectSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignProjectReportFilterProjectSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT,
        payload: data,
    };
};


export const getCampaignEnquiryReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        sourceID: "",
        isQualifiedLead: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: "",
        iDisplayLength: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_ENQUIRY_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignEnquiryReportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignEnquiryReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignEnquiryReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignEnquiryReportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_ENQUIRY_REPORT,
        payload: data,
    };
};





export const getCampaignEnquiryReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        sourceID: "",
        isQualifiedLead: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: "",
        iDisplayLength: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_ENQUIRY_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignEnquiryReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignEnquiryReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignEnquiryReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignEnquiryReportExportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_ENQUIRY_REPORT_EXPORT,
        payload: data,
    };
};




export const getCampaignBookingReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        sourceID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: "",
        iDisplayLength: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_BOOKING_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignBookingReportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignBookingReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignBookingReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignBookingReportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_BOOKING_REPORT,
        payload: data,
    };
};



export const getCampaignBookingReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        sourceID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: "",
        iDisplayLength: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_BOOKING_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignBookingReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignBookingReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignBookingReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignBookingReportExportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_BOOKING_REPORT_EXPORT,
        payload: data,
    };
};





export const getCampaignBookingCancelledReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        sourceID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: "",
        iDisplayLength: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_BOOKING_CANCELLED_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignBookingCancelledReportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignBookingCancelledReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignBookingCancelledReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignBookingCancelledReportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_BOOKING_CANCELLED_REPORT,
        payload: data,
    };
};


export const getCampaignBookingCancelledReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        sourceID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: "",
        iDisplayLength: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_BOOKING_CANCELLED_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignBookingCancelledReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignBookingCancelledReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignBookingCancelledReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignBookingCancelledReportExportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_BOOKING_CANCELLED_REPORT_EXPORT,
        payload: data,
    };
};



export const getCampaignSiteVisitReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        sourceID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: "",
        iDisplayLength: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_SITE_VISIT_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignSiteVisitReportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignSiteVisitReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignSiteVisitReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignSiteVisitReportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_SITE_VISIT_REPORT,
        payload: data,
    };
};


export const getCampaignSiteVisitReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        campaignID: "",
        projectID: "",
        sourceID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: "",
        iDisplayLength: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_CAMPAIGN_SITE_VISIT_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getCampaignSiteVisitReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getCampaignSiteVisitReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getCampaignSiteVisitReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getCampaignSiteVisitReportExportSuccess = (data) => {
    return {
        type: GET_CAMPAIGN_SITE_VISIT_REPORT_EXPORT,
        payload: data,
    };
};



export const getFinancialYear_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_FINANCIAL_YEAR,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getFinancialYearSuccess(data));
                    return data;
                } else {
                    dispatch(getFinancialYearSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getFinancialYearSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getFinancialYearSuccess = (data) => {
    return {
        type: GET_FINANCIAL_YEAR,
        payload: data,
    };
};



export const getFinancialYearScaleType_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_FINANCIAL_YEAR_SCALE_TYPE,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getFinancialYearScaleTypeSuccess(data));
                    return data;
                } else {
                    dispatch(getFinancialYearScaleTypeSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getFinancialYearScaleTypeSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getFinancialYearScaleTypeSuccess = (data) => {
    return {
        type: GET_FINANCIAL_YEAR_SCALE_TYPE,
        payload: data,
    };
};



export const getFinancialYearScaleValue_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_FINANCIAL_YEAR_SCALE_VALUE,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getFinancialYearScaleValueSuccess(data));
                    return data;
                } else {
                    dispatch(getFinancialYearScaleValueSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getFinancialYearScaleValueSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getFinancialYearScaleValueSuccess = (data) => {
    return {
        type: GET_FINANCIAL_YEAR_SCALE_VALUE,
        payload: data,
    };
};

export const getKPISalesReportCrmCancellationDetailExport_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,

      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ApiService.post(
        ApiPaths.GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS,
        initialPayload
      )
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getKPISalesReportCrmCancellationDetailExport(data));
            return data;
          } else {
            dispatch(getKPISalesReportCrmCancellationDetailExport({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getKPISalesReportCrmCancellationDetailExport([]));
          dispatch(mainLoadingFalse());
        });
    };
  };

  export const getKPISalesReportCrmCancellationDetailExport = (data) => {
    return {
      type: GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS_EXPORT,
      payload: data,
    };
  };

  export const getKPIPreSalesSiteVisit_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,

      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ApiService.post(
        ApiPaths.GET_PRE_SALES_SITE_VISIT,
        initialPayload
      )
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getKPIPreSalesSiteVisitSuccess (data));
            return data;
          } else {
            dispatch(getKPIPreSalesSiteVisitSuccess ({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getKPIPreSalesSiteVisitSuccess ([]));
          dispatch(mainLoadingFalse());
        });
    };
  };

  export const getKPIPreSalesSiteVisitSuccess = (data) => {
    return {
      type: GET_PRE_SALES_SITE_VISIT,
      payload: data,
    };
  };



  export const getKPIPreSalesSiteVisitExport_action = (data) => {
    const initialPayload = {
      userID: LoginuserId,

      ...data,
    };
    return (dispatch) => {
      dispatch(mainLoadingTrue());
      return ApiService.post(
        ApiPaths.GET_PRE_SALES_SITE_VISIT,
        initialPayload
      )
        .then((response) => {
          const { data } = response;
          dispatch(mainLoadingFalse());
          if (!data.error) {
            dispatch(getKPIPreSalesSiteVisitExportSuccess (data));
            return data;
          } else {
            dispatch(getKPIPreSalesSiteVisitExportSuccess ({}));
          }
        })
        .catch((err) => {
          console.log("err", err);
          dispatch(getKPIPreSalesSiteVisitExportSuccess ([]));
          dispatch(mainLoadingFalse());
        });
    };
  };

  export const getKPIPreSalesSiteVisitExportSuccess = (data) => {
    return {
      type: GET_PRE_SALES_SITE_VISIT_EXPORT,
      payload: data,
    };
  };

  export const getKPITelecallingFilterUser_action = (data) => {

    const initialPayload = {
    "userID": LoginuserId,
    "fromDate": "",
    "toDate": "",
    ...data
    };

    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPITELECALLING_FILTER_USER, initialPayload).then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());

                if (!data.error) {
                    dispatch(getKPITelecallingFilterUserSuccess(data));
                } else {
                    dispatch(getKPITelecallingFilterUserSuccess([]));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPITelecallingFilterUserSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPITelecallingFilterUserSuccess = (data) => {
    return {
        type: GET_KPITELECALLING_FILTER_USER,
        payload: data,
    };
}



export const getKPISalesSummaryFilterUser_action = (data) => {

    const initialPayload = {
    "userID": LoginuserId,
    "fromDate": "",
    "toDate": "",
    ...data
    };

    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(ApiPaths.GET_KPI_SALES_SUMMARY_FILTER_USER, initialPayload).then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());

                if (!data.error) {
                    dispatch(getKPISalesSummaryFilterUserSuccess(data));
                } else {
                    dispatch(getKPISalesSummaryFilterUserSuccess([]));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesSummaryFilterUserSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesSummaryFilterUserSuccess = (data) => {
    return {
        type: GET_KPI_SALES_SUMMARY_FILTER_USER,
        payload: data,
    };
}


export const getSalesReport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        teamHeadID:"",
        sourceID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT,
        payload: data,
    };
};


export const getSalesReportExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        teamHeadID:"",
        sourceID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_EXPORT,
        payload: data,
    };
};

// ---


export const getSalesReportSiteVisit_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_SITE_VISIT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportSiteVisitSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportSiteVisitSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportSiteVisitSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportSiteVisitSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_SITE_VISIT,
        payload: data,
    };
};


export const getSalesReportSiteVisitExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_SITE_VISIT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportSiteVisitExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportSiteVisitExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportSiteVisitExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportSiteVisitExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_SITE_VISIT_EXPORT,
        payload: data,
    };
};







export const getSalesReportFollowUp_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_FOLLOW_UP,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportFollowUpSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportFollowUpSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportFollowUpSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportFollowUpSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_FOLLOW_UP,
        payload: data,
    };
};


export const getSalesReportFollowUpExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_FOLLOW_UP,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportFollowUpExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportFollowUpExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportFollowUpExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportFollowUpExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_FOLLOW_UP_EXPORT,
        payload: data,
    };
};








export const getSalesReportProspectiveClient_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_PROSPECTIVE_CLIENT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportProspectiveClientSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportProspectiveClientSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportProspectiveClientSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportProspectiveClientSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_PROSPECTIVE_CLIENT,
        payload: data,
    };
};


export const getSalesReportProspectiveClientExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_PROSPECTIVE_CLIENT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportProspectiveClientExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportProspectiveClientExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportProspectiveClientExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportProspectiveClientExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_PROSPECTIVE_CLIENT_EXPORT,
        payload: data,
    };
};









export const getSalesReportBooking_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_BOOKING,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportBookingSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportBookingSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportBookingSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportBookingSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_BOOKING,
        payload: data,
    };
};


export const getSalesReportBookingExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_BOOKING,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportBookingExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportBookingExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportBookingExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportBookingExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_BOOKING_EXPORT,
        payload: data,
    };
};









export const getSalesReportCancellation_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_CANCELLATION,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportCancellationSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportCancellationSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportCancellationSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportCancellationSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_CANCELLATION,
        payload: data,
    };
};


export const getSalesReportCancellationExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_CANCELLATION,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportCancellationExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportCancellationExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportCancellationExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportCancellationExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_CANCELLATION_EXPORT,
        payload: data,
    };
};







export const getSalesReportHotProspectiveClient_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportHotProspectiveClientSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportHotProspectiveClientSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportHotProspectiveClientSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportHotProspectiveClientSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT,
        payload: data,
    };
};


export const getSalesReportHotProspectiveClientExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportHotProspectiveClientExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportHotProspectiveClientExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportHotProspectiveClientExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportHotProspectiveClientExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT_EXPORT,
        payload: data,
    };
};

export const getMonthWeek_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        fromDate: "",
        toDate: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_MONTH_WEEK,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getMonthWeekSuccess(data));
                    return data;
                } else {
                    dispatch(getMonthWeekSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getMonthWeekSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getMonthWeekSuccess = (data) => {
    return {
        type: GET_MONTH_WEEK,
        payload: data,
    };
};



export const getSalesReportCPMeeting_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_CP_MEETING,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportCPMeetingSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportCPMeetingSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportCPMeetingSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportCPMeetingSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_CP_MEETING,
        payload: data,
    };
};


export const getSalesReportCPMeetingExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_CP_MEETING,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportCPMeetingExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportCPMeetingExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportCPMeetingExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportCPMeetingExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_CP_MEETING_EXPORT,
        payload: data,
    };
};



export const getSalesReportClientMeeting_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_CLIENT_MEETING,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportClientMeetingSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportClientMeetingSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportClientMeetingSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportClientMeetingSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_CLIENT_MEETING,
        payload: data,
    };
};


export const getSalesReportClientMeetingExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        teamHeadID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_CLIENT_MEETING,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportClientMeetingExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportClientMeetingExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportClientMeetingExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportClientMeetingExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_CLIENT_MEETING_EXPORT,
        payload: data,
    };
};






export const getSalesReportTeam_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        teamHeadID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_TEAM,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportTeamSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportTeamSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportTeamSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportTeamSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_TEAM,
        payload: data,
    };
};


export const getSalesReportTeamExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        teamHeadID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_TEAM,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportTeamExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportTeamExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportTeamExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportTeamExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_TEAM_EXPORT,
        payload: data,
    };
};


export const getSalesReportFilterTeam_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        teamHeadID: "",
        fromDate: "",
        toDate:"",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_FILTER_TEAM,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportFilterTeamSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportFilterTeamSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportFilterTeamSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportFilterTeamSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_FILTER_TEAM,
        payload: data,
    };
};



export const getSalesReportFilterUser_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        teamHeadID:"",
        fromDate: "",
        toDate: "",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_FILTER_USER,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportFilterUserSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportFilterUserSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportFilterUserSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportFilterUserSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_FILTER_USER,
        payload: data,
    };
};











export const getSalesReportPlannedFollowUp_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_PLANNED_FOLLOW_UP,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportPlannedFollowUpSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportPlannedFollowUpSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportPlannedFollowUpSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportPlannedFollowUpSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_PLANNED_FOLLOW_UP,
        payload: data,
    };
};


export const getSalesReportPlannedFollowUpExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_PLANNED_FOLLOW_UP,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportPlannedFollowUpExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportPlannedFollowUpExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportPlannedFollowUpExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportPlannedFollowUpExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_PLANNED_FOLLOW_UP_EXPORT,
        payload: data,
    };
};








export const getSalesReportPlannedSiteVisit_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: 10,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_PLANNED_SITE_VISIT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportPlannedSiteVisitSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportPlannedSiteVisitSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportPlannedSiteVisitSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportPlannedSiteVisitSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_PLANNED_SITE_VISIT,
        payload: data,
    };
};


export const getSalesReportPlannedSiteVisitExport_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID: "",
        projectID: "",
        fromDate: "",
        toDate: "",
        generalSearch: "",
        sortOrder: "",
        iDisplayStart: 0,
        iDisplayLength: -1,
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_SALES_REPORT_PLANNED_SITE_VISIT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getSalesReportPlannedSiteVisitExportSuccess(data));
                    return data;
                } else {
                    dispatch(getSalesReportPlannedSiteVisitExportSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getSalesReportPlannedSiteVisitExportSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getSalesReportPlannedSiteVisitExportSuccess = (data) => {
    return {
        type: GET_SALES_REPORT_PLANNED_SITE_VISIT_EXPORT,
        payload: data,
    };
};



export const getKPISalesReportCrmBookingDetailFilterProject_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID:"",
        projectID:"",
        fromDate:"",
        toDate:"",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportCrmBookingDetailFilterProjectSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportCrmBookingDetailFilterProjectSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportCrmBookingDetailFilterProjectSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportCrmBookingDetailFilterProjectSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT,
        payload: data,
    };
};

export const getKPISalesReportCrmCancellationDetailFilterProject_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID:"",
        projectID:"",
        fromDate:"",
        toDate:"",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportCrmCancellationDetailFilterProjectSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportCrmCancellationDetailFilterProjectSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportCrmCancellationDetailFilterProjectSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportCrmCancellationDetailFilterProjectSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT,
        payload: data,
    };
};


export const getKPISalesReportSiteVisitDetailFilterProject_action = (data) => {
    const initialPayload = {
        userID: LoginuserId,
        salesExecutiveID:"",
        projectID:"",
        fromDate:"",
        toDate:"",
        ...data,
    };
    return (dispatch) => {
        dispatch(mainLoadingTrue());
        return ApiService.post(
            ApiPaths.GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT,
            initialPayload
        )
            .then((response) => {
                const { data } = response;
                dispatch(mainLoadingFalse());
                if (!data.error) {
                    dispatch(getKPISalesReportSiteVisitDetailFilterProjectSuccess(data));
                    return data;
                } else {
                    dispatch(getKPISalesReportSiteVisitDetailFilterProjectSuccess({}));
                }
            })
            .catch((err) => {
                console.log("err", err);
                dispatch(getKPISalesReportSiteVisitDetailFilterProjectSuccess([]));
                dispatch(mainLoadingFalse());
            });
    };
};

export const getKPISalesReportSiteVisitDetailFilterProjectSuccess = (data) => {
    return {
        type: GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT,
        payload: data,
    };
};