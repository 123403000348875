export const GET_CIVIL_RESOURCE_PLANNING = 'GET_CIVIL_RESOURCE_PLANNING';
export const GET_CIVIL_PROJECT_NAMES = 'GET_CIVIL_PROJECT_NAMES';
export const GET_CIVIL_PROCESS_NAMES = 'GET_CIVIL_PROCESS_NAMES';


export const GET_CIVIL_TASK_NAMES = 'GET_CIVIL_TASK_NAMES';
export const GET_CIVIL_RESOURCE_NAMES = 'GET_CIVIL_RESOURCE_NAMES';
export const GET_CIVIL_RESOURCE_TYPE_PLANNING = 'GET_CIVIL_RESOURCE_TYPE_PLANNING';
export const GET_CIVIL_BUDGET_NAMES = 'GET_CIVIL_BUDGET_NAMES';
// export const SUCCESS_STATUS = 'SUCCESS_STATUS';
// export const OTP_SENT_SUCCESS = 'OTP_SENT_SUCCESS';
// export const OTP_VALID_SUCCESS = 'OTP_VALID_SUCCESS';
// export const OTP_VALID_FAILS = 'OTP_VALID_FAILS';

export const GET_CIVIL_PROJECT = "GET_CIVIL_PROJECT";
export const GET_CIVIL_PROJECT_PLANNIG_STAGE = "GET_CIVIL_PROJECT_PLANNIG_STAGE";
export const GET_CIVIL_PROJECT_TEAM_ROLE = "GET_CIVIL_PROJECT_TEAM_ROLE";
export const GET_CIVIL_PROJECT_PLAN_LIST = "GET_CIVIL_PROJECT_PLAN_LIST";

export const GET_CIVIL_PROJECT_PLAN_TEAM = "GET_CIVIL_PROJECT_PLAN_TEAM";
export const GET_CIVIL_PROJECT_SITE_LOCATION = "GET_CIVIL_PROJECT_SITE_LOCATION";
export const GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT = "GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT";
export const GET_CIVIL_WBS = "GET_CIVIL_WBS";
export const GET_CIVIL_PROJECT_PROCESS_PLAN = "GET_CIVIL_PROJECT_PROCESS_PLAN";
export const GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS = "GET_CIVIL_PROJECT_PROCESS_PLAN_FILTER_PROCESS";
export const GET_CIVIL_PROJECT_PROCESS_TASK = "GET_CIVIL_PROJECT_PROCESS_TASK";
export const GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK = "GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_FILTER_TASK";
export const GET_TASK_DEPENDENCY_TYPE = "GET_TASK_DEPENDENCY_TYPE";

export const GET_CIVIL_PROJECT_ROLE="GET_CIVIL_PROJECT_ROLE";
export const CREATE_CIVIL_PROJECT_ROLE="CREATE_CIVIL_PROJECT_ROLE";
export const UPDATE_CIVIL_PROJECT_ROLE="UPDATE_CIVIL_PROJECT_ROLE";

export const GET_CIVIL_PROJECT_PLAN_FILTER_STAGE = "GET_CIVIL_PROJECT_PLAN_FILTER_STAGE";
export const GET_CIVIL_PROJECT_PROCESS_TASK_PLAN = "GET_CIVIL_PROJECT_PROCESS_TASK_PLAN";
export const GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY = "GET_CIVIL_PROJECT_PROCESS_TASK_PLAN_LOCATION_QUANTITY";
export const GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY = "GET_CIVIL_PROJECT_TASK_MATERIAL_CATEGORY";
export const GET_CIVIL_MATERIAL = "GET_CIVIL_MATERIAL";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL = "GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL";
export const GET_CIVIL_PROJECT_ACTIVITY_MASTER = "GET_CIVIL_PROJECT_ACTIVITY_MASTER";
export const GET_CIVIL_PROJECT_TASK_UOM="GET_CIVIL_PROJECT_TASK_UOM";
export const GET_CIVIL_PROJECT_WBS = "GET_CIVIL_PROJECT_WBS";
export const GET_CIVIL_PROJECT_TASK="GET_CIVIL_PROJECT_TASK";
export const GET_CIVIL_PROCESS_M="GET_CIVIL_PROCESS_M";
export const GET_CIVIL_RESOURCE="GET_CIVIL_RESOURCE";
export const GET_CIVIL_RESOURCE_CATEGORY="GET_CIVIL_RESOURCE_CATEGORY";
export const GET_CIVIL_PROJECT_RESOURCE_1 = "GET_CIVIL_PROJECT_RESOURCE_1";
export const GET_CIVIL_OBJECT_PRODUCTIVITY="GET_CIVIL_OBJECT_PRODUCTIVITY";
export const GET_CIVIL_RESOURCE_ALL="GET_CIVIL_RESOURCE_ALL";
export const GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE = "GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE";
export const GET_CIVIL_PROJECT_ACTIVITY_DETAIL = "GET_CIVIL_PROJECT_ACTIVITY_DETAIL";
export const GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY = "GET_CIVIL_PROJECT_DEPENDABLE_ACTIVITY";
export const GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY="GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE_CATEGORY";
export const GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE="GET_CIVIL_PROJECT_RESOURCE_FILTER_RESOURCE";
export const GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY="GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE_CATEGORY";
export const GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE="GET_CIVIL_PROJECT_RESOURCE_PRODUCTIVITY_FILTER_RESOURCE";
export const GET_CIVIL_PROJECT_RESOURCE_EXPORT="GET_CIVIL_PROJECT_RESOURCE_EXPORT";
export const COPY_CIVIL_PROJECT_RESOURCE="COPY_CIVIL_PROJECT_RESOURCE";
export const GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT="GET_CIVIL_OBJECT_PRODUCTIVITY_EXPORT";
export const COPY_CIVIL_PROJECT_PRODUCTIVITY="COPY_CIVIL_PROJECT_PRODUCTIVITY";
export const GET_CIVIL_RESOURCE_EXPORT="GET_CIVIL_RESOURCE_EXPORT";
export const GET_CIVIL_PROJECT_PLAN_LIST_EXPORT="GET_CIVIL_PROJECT_PLAN_LIST_EXPORT";
export const GET_CIVIL_PROJECT_TASK_UOM_EXPORT="GET_CIVIL_PROJECT_TASK_UOM_EXPORT";
export const GET_CIVIL_PROCESS_M_EXPORT="GET_CIVIL_PROCESS_M_EXPORT";
export const GET_CIVIL_PROJECT_TASK_EXPORT="GET_CIVIL_PROJECT_TASK_EXPORT";
export const GET_CIVIL_PROJECT_ROLE_EXPORT="GET_CIVIL_PROJECT_ROLE_EXPORT";
export const GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT="GET_CIVIL_PROJECT_TASK_PLAN_RESOURCE_EXPORT";
export const GET_CIVIL_PROJECT_ACTIVITY_EXPORT="GET_CIVIL_PROJECT_ACTIVITY_EXPORT";
export const GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT="GET_CIVIL_PROJECT_TASK_QUANTITY_EXPORT";
export const GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT="GET_CIVIL_PROJECT_TASK_RESOURCE_EXPORT";
export const GET_CIVIL_PROJECT_BUDGET = "GET_CIVIL_PROJECT_BUDGET";
export const GET_CIVIL_PROJECT_WBS_DETAIL = "GET_CIVIL_PROJECT_WBS_DETAIL";
export const GET_CIVIL_PROJECT_PLAN_PENDING = "GET_CIVIL_PROJECT_PLAN_PENDING";
export const GET_CIVIL_PROJECT_SCHEDULE = "GET_CIVIL_PROJECT_SCHEDULE";
export const GET_CIVIL_RESOURCE_IDENTIFICATION = "GET_CIVIL_RESOURCE_IDENTIFICATION";
export const GET_CIVIL_MATERIAL_FOR_ACTIVITY="GET_CIVIL_MATERIAL_FOR_ACTIVITY";
export const GET_CIVIL_PROJECT_ACTIVITY_GANTT_CHART = "GET_CIVIL_PROJECT_ACTIVITY_GANTT_CHART";
export const GET_CIVIL_PROJECT_PLAN_DETAIL = "GET_CIVIL_PROJECT_PLAN_DETAIL";
export const GET_CIVIL_PROJECT_PLAN_FROM_TASK_PLAN_DETAIL = "GET_CIVIL_PROJECT_PLAN_FROM_TASK_PLAN_DETAIL";
export const GET_WORK_ORDER_PROCESS = "GET_WORK_ORDER_PROCESS";
export const GET_WORK_ORDER_PROCESS_EXPORT="GET_WORK_ORDER_PROCESS_EXPORT";
export const GET_WORK_ORDER_TASK="GET_WORK_ORDER_TASK";
export const GET_WORK_ORDER_TASK_EXPORT="GET_WORK_ORDER_TASK_EXPORT";
export const GET_PROFITABILITY_REPORT = "GET_PROFITABILITY_REPORT";

//civil-work-order
export const GET_WORK_ORDER_PROJECT="GET_WORK_ORDER_PROJECT";
export const GET_COMPANY_PROJECT_MAP="GET_COMPANY_PROJECT_MAP";
export const GET_VENDOR_TYPE="GET_VENDOR_TYPE";
export const GET_WORK_ORDER_FILTER_VENDOR="GET_WORK_ORDER_FILTER_VENDOR";
export const GET_WORK_ORDER="GET_WORK_ORDER";
export const GET_WORK_ORDER_EXPORT="GET_WORK_ORDER_EXPORT";
export const GET_WORK_ORDER_TERMS="GET_WORK_ORDER_TERMS";
export const GET_WORK_ORDER_DETAIL="GET_WORK_ORDER_DETAIL";
export const GET_WORK_ORDER_PROJECT_WBS="GET_WORK_ORDER_PROJECT_WBS";
export const GET_WORK_ORDER_PROJECT_ACTIVITY="GET_WORK_ORDER_PROJECT_ACTIVITY";
export const GET_WORK_ORDER_TASK_FOR_ITEM="GET_WORK_ORDER_TASK_FOR_ITEM";
export const GET_WORK_ORDER_ITEM="GET_WORK_ORDER_ITEM";
export const GET_WORK_ORDER_DETAIL_PRINT="GET_WORK_ORDER_DETAIL_PRINT";
export const GET_WORK_ORDER_FILTER_PROJECT="GET_WORK_ORDER_FILTER_PROJECT";

export const GET_WORK_ORDER_CATEGORY = "GET_WORK_ORDER_CATEGORY";
export const GET_WORK_ORDER_CATEGORY_EXPORT = "GET_WORK_ORDER_CATEGORY_EXPORT";
export const GET_CIVIL_PROJECT_ACTIVITY_PROGRESS = "GET_CIVIL_PROJECT_ACTIVITY_PROGRESS";
export const GET_VENDOR_MACHINE="GET_VENDOR_MACHINE";
export const GET_VENDOR_MACHINE_EXPORT="GET_VENDOR_MACHINE_EXPORT";
export const GET_VENDOR_MACHINE_DETAIL="GET_VENDOR_MACHINE_DETAIL";
export const GET_PROJECT_MACHINE_VENDOR_MAP="GET_PROJECT_MACHINE_VENDOR_MAP";
export const GET_PROJECT_MACHINE_VENDOR_MAP_EXPORT="GET_PROJECT_MACHINE_VENDOR_MAP_EXPORT";
export const GET_PROJECT_MACHINE_VENDOE_MAP_DETAIL="GET_PROJECT_MACHINE_VENDOE_MAP_DETAIL";
export const GET_WORK_ORDER_TASK_PLAN_RESOURCE = "GET_WORK_ORDER_TASK_PLAN_RESOURCE";
export const GET_ACTUAL_ENTRY_TASK_PLAN_RESOURCE = "GET_ACTUAL_ENTRY_TASK_PLAN_RESOURCE";
export const GET_ACTUAL_ENTRY_VENDOR_MACHINE="GET_ACTUAL_ENTRY_VENDOR_MACHINE";
export const GET_CIVIL_ACTUAL_ENTRY_MACHINE="GET_CIVIL_ACTUAL_ENTRY_MACHINE";
export const GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL = "GET_CIVIL_ACTUAL_ENTRY_MACHINE_DETAIL";
export const GET_ACTIVITY_QUALITY_CHECK_POINT="GET_ACTIVITY_QUALITY_CHECK_POINT";
export const GET_ACTIVITY_QUALITY_CHECK_POINT_EXPORT="GET_ACTIVITY_QUALITY_CHECK_POINT_EXPORT";
export const GET_ACTUAL_REJECTION_REASON="GET_ACTUAL_REJECTION_REASON";
export const GET_ACTUAL_REJECTION_REASON_EXPORT="GET_ACTUAL_REJECTION_REASON_EXPORT";
export const GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR="GET_CIVIL_ACTUAL_ENTRY_FILTER_VENDOR";
export const GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE="GET_CIVIL_ACTUAL_ENTRY_MACHINE_FILTER_MACHINE";
export const GET_ACTUAL_ENTRY_VENDOR_LABOUR = "GET_ACTUAL_ENTRY_VENDOR_LABOUR";
export const GET_CIVIL_ACTUAL_ENTRY_LABOUR = "GET_CIVIL_ACTUAL_ENTRY_LABOUR";
export const GET_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL = "GET_CIVIL_ACTUAL_ENTRY_LABOUR_DETAIL";
export const GET_ACTUAL_ENTRY_VENDOR_CONTRACT = "GET_ACTUAL_ENTRY_VENDOR_CONTRACT";
export const GET_CIVIL_ACTUAL_ENTRY_CONTRACT = "GET_CIVIL_ACTUAL_ENTRY_CONTRACT";
export const GET_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL = "GET_CIVIL_ACTUAL_ENTRY_CONTRACT_DETAIL";
export const GET_GST = "GET_GST";
export const GET_TDS = "GET_TDS";
export const GET_WORK_ORDER_FILTER_COMPANY = "GET_WORK_ORDER_FILTER_COMPANY";
export const GET_VENDOR_MACHINE_FILTER_VENDOR = "GET_VENDOR_MACHINE_FILTER_VENDOR";
export const GET_VENDOR_MACHINE_FILTER_MACHINE_TYPE = "GET_VENDOR_MACHINE_FILTER_MACHINE_TYPE";
export const GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_VENDOR = "GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_VENDOR";
export const GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_MACHINE_TYPE = "GET_PROJECT_MACHINE_VENDOR_MAP_FILTER_MACHINE_TYPE";
export const GET_MATERIAL="GET_MATERIAL";
export const GET_ITEM_GROUP = "GET_ITEM_GROUP";
export const GET_ITEM_GROUP_EXPORT = "GET_ITEM_GROUP_EXPORT";
export const GET_ASSET_TYPE = "GET_ASSET_TYPE";
export const GET_STOCK_UOM = "GET_STOCK_UOM";
export const GET_ASSET_GROUP = "GET_ASSET_GROUP";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE = "GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROJECT="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROJECT";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROCESS="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_PROCESS";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_TASK="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_TASK";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_RESOURCE="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_FILTER_RESOURCE";
export const GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT1="GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT1";
export const GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT2="GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT2";
export const GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT3="GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT3";
export const GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT4="GET_CIVIL_PROJECT_PLAN_FILTER_PROJECT4";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROJECT="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROJECT";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROCESS="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_PROCESS";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_TASK="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_TASK";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_RESOURCE="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_FILTER_RESOURCE";
export const GET_CIVIL_PROJECT_DRAWING="GET_CIVIL_PROJECT_DRAWING"
export const GET_CIVIL_PROJECT_DRAWING_FILTER="GET_CIVIL_PROJECT_DRAWING_FILTER"
export const GET_PROJECT_DOCUMENT_TYPE="GET_PROJECT_DOCUMENT_TYPE"

export const GET_CIVIL_WORK_INSPECTION="GET_CIVIL_WORK_INSPECTION";
export const GET_CIVIL_WORK_INSPECTION_EXPORT="GET_CIVIL_WORK_INSPECTION_EXPORT";
export const GET_CIVIL_WORK_INSPECTION_FILTER_PROJECT = "GET_CIVIL_WORK_INSPECTION_FILTER_PROJECT";
export const GET_CIVIL_WORK_INSPECTION_FILTER_PROCESS = "GET_CIVIL_WORK_INSPECTION_FILTER_PROCESS";
export const GET_CIVIL_WORK_INSPECTION_TASK_PLAN_FILTER_TASK = "GET_CIVIL_WORK_INSPECTION_TASK_PLAN_FILTER_TASK";
export const GET_CIVIL_WORK_INSPECTION_DETAIL="GET_CIVIL_WORK_INSPECTION_DETAIL"
export const GET_CIVIL_QC_TRAINING = "GET_CIVIL_QC_TRAINING";
export const GET_CIVIL_QC_TRAINING_EXPORT = "GET_CIVIL_QC_TRAINING_EXPORT";
export const GET_CIVIL_QC_TRAINING_ATTENDEE = "GET_CIVIL_QC_TRAINING_ATTENDEE";
export const GET_CIVIL_QC_TRAINING_DETAIL="GET_CIVIL_QC_TRAINING_DETAIL";
export const GET_CIVIL_QC_TRAINING_FILTER_PROJECT="GET_CIVIL_QC_TRAINING_FILTER_PROJECT";
export const GET_CIVIL_QC_TRAINING_FILTER_PROCESS="GET_CIVIL_QC_TRAINING_FILTER_PROCESS";
export const GET_CIVIL_QC_TRAINING_FILTER_TASK="GET_CIVIL_QC_TRAINING_FILTER_TASK";
export const GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAILS="GET_CIVIL_PROJECT_TASK_PLAN_MATERIAL_SCHEDULE_DETAILS";
export const GET_CIVIL_PROJECT_APPROVAL="GET_CIVIL_PROJECT_APPROVAL";
export const GET_CIVIL_PROJECT_BILL="GET_CIVIL_PROJECT_BILL";
export const GET_CIVIL_PROJECT_BILL_DETAIL = "GET_CIVIL_PROJECT_BILL_DETAIL";
export const GET_CIVIL_PROJECT_BILL_FILTER_PROJECT = "GET_CIVIL_PROJECT_BILL_FILTER_PROJECT";
export const GET_CIVIL_PROJECT_BILL_FILTER_COMPANY = "GET_CIVIL_PROJECT_BILL_FILTER_COMPANY";
export const GET_CIVIL_PROJECT_BILL_FILTER_VENDOR = "GET_CIVIL_PROJECT_BILL_FILTER_VENDOR";
export const GET_CIVIL_PROJECT_BILL_FILTER_CATEGORY = "GET_CIVIL_PROJECT_BILL_FILTER_CATEGORY";
export const GET_CIVIL_ACTUAL_ENTRY_FILTER_PROJECT= "GET_CIVIL_ACTUAL_ENTRY_FILTER_PROJECT";
export const GET_CIVIL_ACTUAL_ENTRY_FILTER_WBS= "GET_CIVIL_ACTUAL_ENTRY_FILTER_WBS";
export const GET_CIVIL_ACTUAL_ENTRY_FILTER_ACTIVITY= "GET_CIVIL_ACTUAL_ENTRY_FILTER_ACTIVITY";
export const GET_CIVIL_PROJECT_FOR_ACTUAL_ENTRY= "GET_CIVIL_PROJECT_FOR_ACTUAL_ENTRY";
export const GET_CIVIL_PROJECT_WBS_FOR_ACTUAL_ENTRY= "GET_CIVIL_PROJECT_WBS_FOR_ACTUAL_ENTRY";
export const GET_CIVIL_PROJECT_ACTIVITY_FOR_ACTUAL_ENTRY= "GET_CIVIL_PROJECT_ACTIVITY_FOR_ACTUAL_ENTRY";


export const GET_CIVIL_SETTING ="GET_CIVIL_SETTING";
export const GET_CIVIL_SETTING_EXPORT ="GET_CIVIL_SETTING_EXPORT";
export const GET_CIVIL_PROJECT_SETTING="GET_CIVIL_PROJECT_SETTING"
export const GET_CIVIL_PROJECT_SETTING_FILTER="GET_CIVIL_PROJECT_SETTING_FILTER";
export const GET_CIVIL_PROJECT_SETTING_DETAILS="GET_CIVIL_PROJECT_SETTING_DETAILS"
export const GET_CIVIL_PROJECT_SETTING_EXPORT="GET_CIVIL_PROJECT_SETTING_EXPORT";
export const GET_CIVIL_PROJECT_SETTING_FILTER_PROJECT="GET_CIVIL_PROJECT_SETTING_FILTER_PROJECT";
export const GET_CIVIL_PROJECT_SETTING_FILTER_CIVIL="GET_CIVIL_PROJECT_SETTING_FILTER_CIVIL";

export const GET_CIVIL_PROJECT_BILL_EXPORT="GET_CIVIL_PROJECT_BILL_EXPORT";
