import {
  GET_PROFITABLE_REPORTS,
  GET_LIASONPROCESSOWNER,
  GET_PROJECT_NAMES,
  GET_PENDING_TASK,
  GET_TASK_DELAYS,
  GET_PROFITABLE_REPORT_COUNT,
  GET_LIAISON_PROJECT_NAMES,
  GET_DASHBOARD_DATA,
  GET_USER_DASHBOARD_DATA,
  GET_USER_DASHBOARD_TASK,
  GET_CIVIL_PROCESS_DATA,
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA,
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA,
  GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA,
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA,
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA,
  GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA,
  SET_FUNNEL_ITEM_ID,
  GET_DASHBOARD_STOCK_ZONE_DATA,
  GET_DASHBOARD_STOCK_PROJECT_DATA,
  GET_DASHBOARD_STOCK_PROJECT_SITE_DATA,
  GET_DASHBOARD_STOCK_AGING_ZONE_DATA,
  GET_DASHBOARD_STOCK_AGING_PROJECT_DATA,
  GET_DASHBOARD_STOCK_AGING_FILE_DATA,
  GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA,
  GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA,
  GET_DASHBOARD_LAND_AQUIRED_FILE_DATA,
  GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA,
  GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA,
  GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA,
  GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA,
  GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA,
  GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA,
  GET_DASHBOARD_DATA_DEPARTMENT,
  GET_DASHBOARD_DEPARTMENT,
  GET_LIAISON_PROCESS_PLAN_REPORT,
  GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL,
  GET_MY_DASHBOARD_DATA_FILTER_USER,
  GET_DASHBOARD_CRM_PROJECT_OUTSTANDING,
  GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT,
  GET_DASHBOARD_CRM_SITE_OUTSTANDING,
  GET_DASHBOARD_CRM_PROJECT_TARGET,
  GET_DASHBOEAR_CRM_SITE_TARGET,
  GET_DASHBOARD_CRM_PROJECT_RECEIVED,
  GET_DASHBOARD_CRM_SITE_RECEIVED,
  GET_DASHBOARD_CRM_PROJECT_REALIZED,
  GET_DAHSBOARD_CRM_SITE_REALIZED,
  GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED,
  GET_DAHSBOARD_CRM_SITE_NOT_REALIZED,
  GET_DASHBOARD_DATA_PRE_SALES,
  GET_DASHBOARD_DATA_MARKETING_FUNNEL,
  GET_GRN_ON_TIME_REPORT,
  GET_GRN_ON_TIME_REPORT_EXPORT,
  GET_VENDOR_LEAD_TIME_REPORT,
  GET_VENDOR_LEAD_TIME_REPORT_EXPORT,
  GET_STOCK_PROJECT,
  GET_PURCHASE_INVOICE_ON_TIME_KPI,
  GET_PURCHASE_INVOICE_ON_TIME_KPI_EXPORT,
  GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT,
  GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT_EXPORT,
  GET_MATERIAL_QUANTITY_REJECTION_KPI,
  GET_MATERIAL_QUANTITY_REJECTION_KPI_EXPORT,
  GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT,
  GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT_EXPORT,
  GET_INDENT_PURCHASE_ORDER_KPI_REPORT,
  GET_INDENT_PURCHASE_ORDER_KPI_REPORT_EXPORT,
  GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME,
  GET_REGISTRATION_TURNAROUND_TIME_REPORT,
  GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT,
  GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER,
  GET_CIVIL_RESOURCE_REPORT,
  GET_ON_TIME_DELIVERY_KPI,
  GET_ON_TIME_DELIVERY_KPI_EXPORT,
  GET_ON_TIME_DELIVERY_KPI_REPORT,
  GET_ON_TIME_DELIVERY_KPI_REPORT_EXPORT,
  GET_PROCUREMENT_KPI_REPORT,
  GET_SALES_CRM_REPORT,
  GET_SALES_CRM_REPORT_EXPORT,
  GET_CRM_PROJECTS,
  GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL,
  GET_SCALE_TYPE,
  GET_CRM_SITE,
  GET_CRM_SITE_DIMENSION,
  GET_KPI_SALES_REPORT,
  GET_KPI_SALES_REPORT_EXPORT,
  GET_KPI_SALES_REPORT_EXECUTIVE_FILTER,
  GET_KPI_SALES_REPORT_DETAILS,
  GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER,
  GET_KPI_SALES_REPORT_DETAILS_EXPORT,
  GET_KPI_PRE_SALES_REPORT,
  GET_KPI_PRE_SALES_REPORT_EXPORT,
  GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER,
  GET_KPI_SALES_PRE_REPORT_DETAILS,
  GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER,
  GET_KPI_SALES_PRE_REPORT_DETAILS_EXPORT,
  GET_KPI_SALES_CRM_REPORT,
  GET_KPI_SALES_CRM_REPORT_EXPORT,
  GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER,
  GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER,
  GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER,
  GET_KPI_SALES_REPORT2,
  GET_KPI_SALES_REPORT2_EXPORT,
  GET_KPI_SALES_SUMMARY_REPORT,
  GET_KPI_SALES_SUMMARY_REPORT_EXPORT,
  GET_KPI_SALES_SUMMARY_REPORT_DETAILS,
  GET_KPI_SALES_SUMMARY_REPORT_DETAILS_EXPORT,
  GET_KPI_TELECALLING_REPORT,
  GET_KPI_TELECALLING_REPORT_EXPORT,
  GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL,
  GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL_EXPORT,
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL,
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_EXPORT,
  GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS,
  GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS_EXPORT,
  GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS,
  GET_CAMPAIGN_REPORT,
  GET_CAMPAIGN_REPORT_EXPORT,
  GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN,
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAILS_EXPORT,
  GET_CAMPAIGN_PROJECT_REPORT_FILTER_CAMPAIGN,
  GET_CAMPAIGN_PROJECT_REPORT_EXPORT,
  GET_CAMPAIGN_PROJECT_REPORT,
  GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT,
  GET_CAMPAIGN_ENQUIRY_REPORT_EXPORT,
  GET_CAMPAIGN_ENQUIRY_REPORT,
  GET_CAMPAIGN_BOOKING_REPORT_EXPORT,
  GET_CAMPAIGN_BOOKING_REPORT,
  GET_CAMPAIGN_BOOKING_CANCELLED_REPORT_EXPORT,
  GET_CAMPAIGN_BOOKING_CANCELLED_REPORT,
  GET_CAMPAIGN_SITE_VISIT_REPORT_EXPORT,
  GET_CAMPAIGN_SITE_VISIT_REPORT,
  GET_FINANCIAL_YEAR,
  GET_FINANCIAL_YEAR_SCALE_TYPE,
  GET_FINANCIAL_YEAR_SCALE_VALUE,
  GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS_EXPORT,
  GET_PRE_SALES_SITE_VISIT_EXPORT,
  GET_PRE_SALES_SITE_VISIT,
  GET_KPITELECALLING_FILTER_USER,
  GET_KPI_SALES_SUMMARY_FILTER_USER,
  GET_SALES_REPORT,
  GET_SALES_REPORT_EXPORT,
  GET_SALES_REPORT_SITE_VISIT_EXPORT,
  GET_SALES_REPORT_SITE_VISIT,
  GET_SALES_REPORT_PROSPECTIVE_CLIENT_EXPORT,
  GET_SALES_REPORT_PROSPECTIVE_CLIENT,
  GET_SALES_REPORT_FOLLOW_UP_EXPORT,
  GET_SALES_REPORT_FOLLOW_UP,
  GET_SALES_REPORT_BOOKING_EXPORT,
  GET_SALES_REPORT_BOOKING,
  GET_SALES_REPORT_CANCELLATION_EXPORT,
  GET_SALES_REPORT_CANCELLATION,
  GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT_EXPORT,
  GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT,
  GET_MONTH_WEEK,
  GET_SALES_REPORT_CLIENT_MEETING_EXPORT,
  GET_SALES_REPORT_CLIENT_MEETING,
  GET_SALES_REPORT_CP_MEETING_EXPORT,
  GET_SALES_REPORT_CP_MEETING,
  GET_SALES_REPORT_TEAM_EXPORT,
  GET_SALES_REPORT_TEAM,
  GET_SALES_REPORT_FILTER_TEAM,
  GET_SALES_REPORT_FILTER_USER,
  GET_SALES_REPORT_PLANNED_FOLLOW_UP,
  GET_SALES_REPORT_PLANNED_FOLLOW_UP_EXPORT,
  GET_SALES_REPORT_PLANNED_SITE_VISIT,
  GET_SALES_REPORT_PLANNED_SITE_VISIT_EXPORT,
  GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER1,
  GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER2,
  GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT,
  GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT,
  GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT
} from "./types";

const initialState = {
  profitableReports: [],
  profitableReportCount: 0,
  LiasonProcessOwners: [],
  getProjectNames: [],
  getTaskDelays: [],
  getPendingTasks: [],
  getLisionProjectNames: [],
  DashboardData: [],
  myDashboardData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  myDashboardTask: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  civilProcessProgressData: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getDashboardAcquiredButPlanApprovalPendingZoneData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardAcquiredButPlanApprovalPendingProjectData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardAcquiredButPlanApprovalPendingFileData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardPlanApprovalAppliedZoneData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardPlanApprovalAppliedProjectData: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardPlanApprovalAppliedFileData: {
    error: false,
    message: "",
    data: [],
  },
  // getFunnelID:{
  //     data:""
  // }
  getBDDashboardDataDepartment: {
    error: false,
    message: "",
    data: [],
  },
  getBDDashboardDepartment: {
    error: false,
    message: "",
    data: [],
  },
  getLiaisonProcessPlanReport: {
    error: false,
    message: "",
    data: {
      data: [{}],
      dataTotal: [{}],
    },
    totalCount: 0,
  },
  liaisonProcessPlanReportDetail: {
    error: false,
    message: "",
    data: [],
  },
  getMyDashboardDataFilterUser: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardCrmProjectOutstanding: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardCrmProjectOutstandingExport: {
    error: false,
    message: "",
    data: [],
  },
  getDashboardCrmSiteOutstanding: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmProjectTarget: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmSiteTarget: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmProjectReceived: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmSiteReceived: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmProjectNotRealized: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardCrmSiteNotRealized: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  DashboardDataPreSales: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataMarketingFunnel: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  //   nikhil
  getGRNOnTimeKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getGRNOnTimeKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorLeadTimeKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getVendorLeadTimeKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getStockProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseInvoiceOnTimeKPI: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseInvoiceOnTimeKPIExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseInvoiceOnTimeKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getPurchaseInvoiceOnTimeKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getMaterialQuantityRejectionKPI: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMaterialQuantityRejectionKPIExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getMaterialQuantityRejectionKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMaterialQuantityRejectionKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentPurchaseOrderKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getIndentPurchaseOrderKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getDashboardDataRegistrationTurnaroundTime: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getRegistrationTurnaroundTimeReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getRegistrationTurnaroundTimeReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getRegistrationTurnaroundTimeReportFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResourceReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOnTimeDeliveryKPIReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOnTimeDeliveryKPIReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOnTimeDeliveryKPI: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getOnTimeDeliveryKPIExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getProcurmentKpiReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesCrmReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesCrmReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmProjects: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilResourceReportNonMaterial: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getScaleType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSite: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSiteDimension: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getKpiSalesReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKpiSalesReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportExecutiveFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportDetailProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportExecutiveFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportDetails: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportDetailsExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReportProjectFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReportSourceFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesCrmReportSalesExecutiveFilter: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getKPISalesReport2: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReport2Export: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryReportDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryReportDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPITelecallingReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPITelecallingReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportLeadAssignedDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesReportLeadAssignedDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportSiteVisitDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmBookingDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmBookingDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmCancellationDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignReportFilterCampaign: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportSiteVisitDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignProjectReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignProjectReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignProjectReportFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignEnquiryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignEnquiryReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignBookingReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignBookingReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignBookingCancelledReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignBookingCancelledReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignSiteVisitReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCampaignSiteVisitReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getFinancialYear: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getFinancialYearScaleType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getFinancialYearScaleValue: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmCancellationDetailExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesSiteVisit: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPIPreSalesSiteVisitExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPITelecallingFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesSummaryFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportSiteVisit: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportSiteVisitExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportFollowUp: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportFollowUpExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportProspectiveClient: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportProspectiveClientExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportBooking: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportBookingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCancellation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCancellationExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportHotProspectiveClient: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportHotProspectiveClientExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getMonthWeek: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCPMeeting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportCPMeetingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportClientMeeting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportClientMeetingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportTeam: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportTeamExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportFilterTeam: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportFilterUser: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedFollowUp: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedFollowUpExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedSiteVisit: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSalesReportPlannedSiteVisitExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmBookingDetailFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportCrmCancellationDetailFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getKPISalesReportSiteVisitDetailFilterProject: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
};

const Report_reducer = (state = initialState, { type, payload }) => {
  // console.log(type, "payload", payload)
  switch (type) {
    case GET_PROFITABLE_REPORTS:
      return Object.assign({}, state, { profitableReports: payload });
    case GET_PROFITABLE_REPORT_COUNT:
      return Object.assign({}, state, { profitableReportCount: payload });
    case GET_LIASONPROCESSOWNER:
      return Object.assign({}, state, { LiasonProcessOwners: payload });
    case GET_PROJECT_NAMES:
      return Object.assign({}, state, { getProjectNames: payload });
    case GET_LIAISON_PROJECT_NAMES:
      return Object.assign({}, state, { getLisionProjectNames: payload });
    case GET_TASK_DELAYS:
      return Object.assign({}, state, { getTaskDelays: payload });
    case GET_PENDING_TASK:
      return Object.assign({}, state, { getPendingTasks: payload });
    case GET_DASHBOARD_DATA:
      return Object.assign({}, state, { DashboardData: payload });
    case GET_USER_DASHBOARD_DATA:
      return Object.assign({}, state, { myDashboardData: payload });
    case GET_USER_DASHBOARD_TASK:
      return Object.assign({}, state, { myDashboardTask: payload });
    case GET_CIVIL_PROCESS_DATA:
      return Object.assign({}, state, { civilProcessProgressData: payload });
    case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardAcquiredButPlanApprovalPendingZoneData: payload,
      });
    case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardAcquiredButPlanApprovalPendingProjectData: payload,
      });
    case GET_DASHBOARD_AQUIRED_BUT_PLAN_APPROVAL_PENDING_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardAcquiredButPlanApprovalPendingFileData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardPlanApprovalAppliedZoneData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardPlanApprovalAppliedProjectData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVAL_APPLIED_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardPlanApprovalAppliedFileData: payload,
      });
    case SET_FUNNEL_ITEM_ID:
      return Object.assign({}, state, { getFunnelID: payload });
    case GET_DASHBOARD_STOCK_ZONE_DATA:
      return Object.assign({}, state, { getDashboardStockZoneData: payload });
    case GET_DASHBOARD_STOCK_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardStockProjectData: payload,
      });
    case GET_DASHBOARD_STOCK_PROJECT_SITE_DATA:
      return Object.assign({}, state, {
        getDashboardStockProjectSiteData: payload,
      });
    case GET_DASHBOARD_STOCK_AGING_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardStockAgingZoneData: payload,
      });
    case GET_DASHBOARD_STOCK_AGING_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardStockAgingProjectData: payload,
      });
    case GET_DASHBOARD_STOCK_AGING_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardStockAgingFileData: payload,
      });
    case GET_DASHBOARD_LAND_AQUIRED_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardLandAquiredZoneData: payload,
      });
    case GET_DASHBOARD_LAND_AQUIRED_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardLandAquiredProjectData: payload,
      });
    case GET_DASHBOARD_LAND_AQUIRED_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardLandAquiredFileData: payload,
      });
    case GET_DASHBOARD_APPLAN_APPROVAL_ZONE_DATA:
      return Object.assign({}, state, {
        getDashboardApplanApprovalZoneData: payload,
      });
    case GET_DASHBOARD_APPLAN_APPROVAL_PROJECT_DATA:
      return Object.assign({}, state, {
        getDashboardApplanApprovalProjectData: payload,
      });
    case GET_DASHBOARD_APPLAN_APPROVAL_FILE_DATA:
      return Object.assign({}, state, {
        getDashboardApplanApprovalFileData: payload,
      });
    case GET_DASHBOARD_LAND_AQUIRED_FINANCIAL_YEAR_DATA:
      return Object.assign({}, state, {
        getBDDashboardLandAcquisitionData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVAL_FINANCIAL_YEAR_DATA:
      return Object.assign({}, state, {
        getBDDashboardPlanApprovalData: payload,
      });
    case GET_DASHBOARD_PLAN_APPROVED_TARGET_ACTUAL_FINANCIAL_YEAR_DATA:
      return Object.assign({}, state, {
        getBDDashboardPlanApprovedTargetActualData: payload,
      });
    case GET_DASHBOARD_DATA_DEPARTMENT:
      return Object.assign({}, state, {
        getBDDashboardDataDepartment: payload,
      });
    case GET_DASHBOARD_DEPARTMENT:
      return Object.assign({}, state, { getBDDashboardDepartment: payload });
    case GET_LIAISON_PROCESS_PLAN_REPORT:
      return Object.assign({}, state, { getLiaisonProcessPlanReport: payload });
    case GET_LIAISON_PROCESS_PLAN_REPORT_DETAIL:
      return Object.assign({}, state, {
        liaisonProcessPlanReportDetail: payload,
      });
    case GET_MY_DASHBOARD_DATA_FILTER_USER:
      return Object.assign({}, state, {
        getMyDashboardDataFilterUser: payload,
      });
    case GET_DASHBOARD_CRM_PROJECT_OUTSTANDING:
      return Object.assign({}, state, {
        getDashboardCrmProjectOutstanding: payload,
      });
    case GET_DASHBOARD_CRM_PROJECT_OUTSTANDING_EXPORT:
      return Object.assign({}, state, {
        getDashboardCrmProjectOutstandingExport: payload,
      });
    case GET_DASHBOARD_CRM_SITE_OUTSTANDING:
      return Object.assign({}, state, {
        getDashboardCrmSiteOutstanding: payload,
      });
    case GET_DASHBOARD_CRM_PROJECT_TARGET:
      return Object.assign({}, state, {
        getDashboardCrmProjectTarget: payload,
      });
    case GET_DASHBOEAR_CRM_SITE_TARGET:
      return Object.assign({}, state, { getDashboardCrmSiteTarget: payload });
    case GET_DASHBOARD_CRM_PROJECT_RECEIVED:
      return Object.assign({}, state, {
        getDashboardCrmProjectReceived: payload,
      });
    case GET_DASHBOARD_CRM_SITE_RECEIVED:
      return Object.assign({}, state, { getDashboardCrmSiteReceived: payload });
    case GET_DASHBOARD_CRM_PROJECT_REALIZED:
      return Object.assign({}, state, {
        getDashboardCrmProjectRealized: payload,
      });
    case GET_DAHSBOARD_CRM_SITE_REALIZED:
      return Object.assign({}, state, { getDashboardCrmSiteRealized: payload });
    case GET_DASHBOARD_CRM_PROJECT_NOT_REALIZED:
      return Object.assign({}, state, {
        getDashboardCrmProjectNotRealized: payload,
      });
    case GET_DAHSBOARD_CRM_SITE_NOT_REALIZED:
      return Object.assign({}, state, {
        getDashboardCrmSiteNotRealized: payload,
      });
    case GET_DASHBOARD_DATA_PRE_SALES:
      return Object.assign({}, state, { DashboardDataPreSales: payload });
    case GET_DASHBOARD_DATA_MARKETING_FUNNEL:
      return Object.assign({}, state, {
        getDashboardDataMarketingFunnel: payload,
      });
    // nikhil
    case GET_GRN_ON_TIME_REPORT:
      return Object.assign({}, state, { getGRNOnTimeKPIReport: payload });

    case GET_GRN_ON_TIME_REPORT_EXPORT:
      return Object.assign({}, state, { getGRNOnTimeKPIReportExport: payload });

    case GET_VENDOR_LEAD_TIME_REPORT:
      return Object.assign({}, state, { getVendorLeadTimeKPIReport: payload });

    case GET_VENDOR_LEAD_TIME_REPORT_EXPORT:
      return Object.assign({}, state, {
        getVendorLeadTimeKPIReportExport: payload,
      });

    case GET_STOCK_PROJECT:
      return Object.assign({}, state, { getStockProject: payload });

    case GET_PURCHASE_INVOICE_ON_TIME_KPI:
      return Object.assign({}, state, { getPurchaseInvoiceOnTimeKPI: payload });

    case GET_PURCHASE_INVOICE_ON_TIME_KPI_EXPORT:
      return Object.assign({}, state, {
        getPurchaseInvoiceOnTimeKPIExport: payload,
      });

    case GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT:
      return Object.assign({}, state, {
        getPurchaseInvoiceOnTimeKPIReport: payload,
      });

    case GET_PURCHASE_INVOICE_ON_TIME_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getPurchaseInvoiceOnTimeKPIReportExport: payload,
      });

    case GET_MATERIAL_QUANTITY_REJECTION_KPI:
      return Object.assign({}, state, {
        getMaterialQuantityRejectionKPI: payload,
      });

    case GET_MATERIAL_QUANTITY_REJECTION_KPI_EXPORT:
      return Object.assign({}, state, {
        getMaterialQuantityRejectionKPIExport: payload,
      });

    case GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT:
      return Object.assign({}, state, {
        getMaterialQuantityRejectionKPIReport: payload,
      });

    case GET_MATERIAL_QUANTITY_REJECTION_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getMaterialQuantityRejectionKPIReportExport: payload,
      });
    case GET_INDENT_PURCHASE_ORDER_KPI_REPORT:
      return Object.assign({}, state, {
        getIndentPurchaseOrderKPIReport: payload,
      });

    case GET_INDENT_PURCHASE_ORDER_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getIndentPurchaseOrderKPIReportExport: payload,
      });
    case GET_DASHBOARD_DATA_REGISTRATION_TURN_AROUND_TIME:
      return Object.assign({}, state, {
        getDashboardDataRegistrationTurnaroundTime: payload,
      });
    case GET_REGISTRATION_TURNAROUND_TIME_REPORT:
      return Object.assign({}, state, {
        getRegistrationTurnaroundTimeReport: payload,
      });
    case GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getRegistrationTurnaroundTimeReportFilterProject: payload,
      });
    case GET_REGISTRATION_TURNAROUND_TIME_REPORT_FILTER_USER:
      return Object.assign({}, state, {
        getRegistrationTurnaroundTimeReportFilterUser: payload,
      });
    case GET_CIVIL_RESOURCE_REPORT:
      return Object.assign({}, state, { getCivilResourceReport: payload });
    case GET_CIVIL_RESOURCE_REPORT_NON_MATERIAL:
      return Object.assign({}, state, {
        getCivilResourceReportNonMaterial: payload,
      });
    case GET_ON_TIME_DELIVERY_KPI:
      return Object.assign({}, state, { getOnTimeDeliveryKPI: payload });
    case GET_ON_TIME_DELIVERY_KPI_EXPORT:
      return Object.assign({}, state, { getOnTimeDeliveryKPIExport: payload });
    case GET_ON_TIME_DELIVERY_KPI_REPORT:
      return Object.assign({}, state, { getOnTimeDeliveryKPIReport: payload });
    case GET_ON_TIME_DELIVERY_KPI_REPORT_EXPORT:
      return Object.assign({}, state, {
        getOnTimeDeliveryKPIReportExport: payload,
      });
    case GET_PROCUREMENT_KPI_REPORT:
      return Object.assign({}, state, { getProcurmentKpiReport: payload });
    case GET_SALES_CRM_REPORT:
      return Object.assign({}, state, { getSalesCrmReport: payload });
    case GET_SALES_CRM_REPORT_EXPORT:
      return Object.assign({}, state, { getSalesCrmReportExport: payload });
    case GET_CRM_PROJECTS:
      return Object.assign({}, state, { getCrmProjects: payload });
    case GET_SCALE_TYPE:
      return Object.assign({}, state, { getScaleType: payload });
    case GET_CRM_SITE:
      return Object.assign({}, state, { getCrmSite: payload });
    case GET_CRM_SITE_DIMENSION:
      return Object.assign({}, state, { getCrmSiteDimension: payload });
    case GET_KPI_SALES_REPORT:
      return Object.assign({}, state, { getKpiSalesReport: payload });
    case GET_KPI_SALES_REPORT_EXPORT:
      return Object.assign({}, state, { getKpiSalesReportExport: payload });

    case GET_KPI_SALES_REPORT_EXECUTIVE_FILTER:
      return Object.assign({}, state, {
        getKPISalesReportExecutiveFilter: payload,
      });

    case GET_KPI_SALES_REPORT_DETAILS:
      return Object.assign({}, state, { getKPISalesReportDetail: payload });

    case GET_KPI_SALES_REPORT_DETAILS_PROJECT_FILTER:
      return Object.assign({}, state, {
        getKPISalesReportDetailProjectFilter: payload,
      });

    case GET_KPI_SALES_REPORT_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesReportDetailExport: payload,
      });

    case GET_KPI_PRE_SALES_REPORT:
      return Object.assign({}, state, { getKPIPreSalesReport: payload });

    case GET_KPI_PRE_SALES_REPORT_EXPORT:
      return Object.assign({}, state, { getKPIPreSalesReportExport: payload });
    case GET_KPI_PRE_SALES_REPORT_EXECUTIVE_FILTER:
      return Object.assign({}, state, {
        getKPIPreSalesReportExecutiveFilter: payload,
      });
    case GET_KPI_SALES_PRE_REPORT_DETAILS:
      return Object.assign({}, state, { getKPIPreSalesReportDetails: payload });
    case GET_KPI_PRE_SALES_REPORT_DETAILS_PROJECT_FILTER:
      return Object.assign({}, state, {
        getKPIPreSalesReportProjectFilter: payload,
      });

    case GET_KPI_SALES_PRE_REPORT_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPIPreSalesReportDetailsExport: payload,
      });

    case GET_KPI_SALES_CRM_REPORT:
      return Object.assign({}, state, { getKPISalesCrmReport: payload });
    case GET_KPI_SALES_CRM_REPORT_EXPORT:
      return Object.assign({}, state, { getKPISalesCrmReportExport: payload });
    case GET_KPI_SALES_CRM_REPORT_PROJECT_FILTER:
      return Object.assign({}, state, {
        getKPISalesCrmReportProjectFilter: payload,
      });
    case GET_KPI_SALES_CRM_REPORT_SOURCE_FILTER:
      return Object.assign({}, state, {
        getKPISalesCrmReportSourceFilter: payload,
      });

    case GET_KPI_SALES_CRM_REPORT_SALES_EXECUTIVE_FILTER:
      return Object.assign({}, state, {
        getKPISalesCrmReportSalesExecutiveFilter: payload,
      });
    case GET_KPI_SALES_REPORT2:
      return Object.assign({}, state, { getKPISalesReport2: payload });
    case GET_KPI_SALES_REPORT2_EXPORT:
      return Object.assign({}, state, { getKPISalesReport2Export: payload });
    case GET_KPI_SALES_SUMMARY_REPORT:
      return Object.assign({}, state, { getKPISalesSummaryReport: payload });
    case GET_KPI_SALES_SUMMARY_REPORT_EXPORT:
      return Object.assign({}, state, {
        getKPISalesSummaryReportExport: payload,
      });
    case GET_KPI_SALES_SUMMARY_REPORT_DETAILS:
      return Object.assign({}, state, {
        getKPISalesSummaryReportDetail: payload,
      });
    case GET_KPI_SALES_SUMMARY_REPORT_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesSummaryReportDetailExport: payload,
      });
    case GET_KPI_TELECALLING_REPORT:
      return Object.assign({}, state, {
        getKPITelecallingReport: payload,
      });
    case GET_KPI_TELECALLING_REPORT_EXPORT:
      return Object.assign({}, state, {
        getKPITelecallingReportExport: payload,
      });

    case GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL:
      return Object.assign({}, state, {
        getKPIPreSalesReportLeadAssignedDetail: payload,
      });
    case GET_KPI_SALES_REPORT_LEAD_ASSIGNED_DETAIL_EXPORT:
      return Object.assign({}, state, {
        getKPIPreSalesReportLeadAssignedDetailExport: payload,
      });

    case GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL:
      return Object.assign({}, state, {
        getKPISalesReportSiteVisitDetail: payload,
      });

    case GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS:
      return Object.assign({}, state, {
        getKPISalesReportCrmBookingDetail: payload,
      });
    case GET_KPI_SALES_REPORT_CRM_BOOKING_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesReportCrmBookingDetailExport: payload,
      });
    case GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS:
      return Object.assign({}, state, {
        getKPISalesReportCrmCancellationDetail: payload,
      });
    case GET_CAMPAIGN_REPORT:
      return Object.assign({}, state, {
        getCampaignReport: payload,
      });
    case GET_CAMPAIGN_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignReportExport: payload,
      });
    case GET_CAMPAIGN_REPORT_FILTER_CAMPAIGN:
      return Object.assign({}, state, {
        getCampaignReportFilterCampaign: payload,
      });
    case GET_KPI_SALES_REPORT_SITE_VISIT_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesReportSiteVisitDetailExport: payload,
      });
    case GET_CAMPAIGN_PROJECT_REPORT:
      return Object.assign({}, state, {
        getCampaignProjectReport: payload,
      });
    case GET_CAMPAIGN_PROJECT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignProjectReportExport: payload,
      });
    case GET_CAMPAIGN_PROJECT_REPORT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getCampaignProjectReportFilterProject: payload,
      });
    case GET_CAMPAIGN_ENQUIRY_REPORT:
      return Object.assign({}, state, {
        getCampaignEnquiryReport: payload,
      });
    case GET_CAMPAIGN_ENQUIRY_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignEnquiryReportExport: payload,
      });
    case GET_CAMPAIGN_BOOKING_REPORT:
      return Object.assign({}, state, {
        getCampaignBookingReport: payload,
      });
    case GET_CAMPAIGN_BOOKING_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignBookingReportExport: payload,
      });
    case GET_CAMPAIGN_BOOKING_CANCELLED_REPORT:
      return Object.assign({}, state, {
        getCampaignBookingCancelledReport: payload,
      });
    case GET_CAMPAIGN_BOOKING_CANCELLED_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignBookingCancelledReportExport: payload,
      });
    case GET_CAMPAIGN_SITE_VISIT_REPORT:
      return Object.assign({}, state, {
        getCampaignSiteVisitReport: payload,
      });
    case GET_CAMPAIGN_SITE_VISIT_REPORT_EXPORT:
      return Object.assign({}, state, {
        getCampaignSiteVisitReportExport: payload,
      });
    case GET_FINANCIAL_YEAR:
      return Object.assign({}, state, {
        getFinancialYear: payload,
      });
    case GET_FINANCIAL_YEAR_SCALE_TYPE:
      return Object.assign({}, state, {
        getFinancialYearScaleType: payload,
      });
    case GET_FINANCIAL_YEAR_SCALE_VALUE:
      return Object.assign({}, state, {
        getFinancialYearScaleValue: payload,
      });
    case GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAILS_EXPORT:
      return Object.assign({}, state, {
        getKPISalesReportCrmCancellationDetailExport: payload,
      });

    case GET_PRE_SALES_SITE_VISIT:
      return Object.assign({}, state, {
        getKPIPreSalesSiteVisit: payload,
      });

    case GET_PRE_SALES_SITE_VISIT_EXPORT:
      return Object.assign({}, state, {
        getKPIPreSalesSiteVisitExport: payload,
      });

    case GET_KPITELECALLING_FILTER_USER:
      return Object.assign({}, state, { getKPITelecallingFilterUser: payload });

    case GET_KPI_SALES_SUMMARY_FILTER_USER:
      return Object.assign({}, state, {
        getKPISalesSummaryFilterUser: payload,
      });
    // case DISPLAY_MESSAGE:
    //     return Object.assign({}, state, { messageInfo: payload });

    // case DISPLAY_ERROR:
    //     return Object.assign({}, state, { error: payload.error });

    // case SUCCESS_STATUS:
    //     return Object.assign({}, state, { status: payload.status });

    // case OTP_SENT_SUCCESS:
    //     return Object.assign({}, state, { otp_sent: true });

    // case OTP_VALID_FAILS:
    //     return Object.assign({}, state, { valid_otp: false });

    // case OTP_VALID_SUCCESS:
    //         return Object.assign({}, state, { valid_otp: true });
    case GET_SALES_REPORT:
      return Object.assign({}, state, { getSalesReport: payload });
    case GET_SALES_REPORT_EXPORT:
      return Object.assign({}, state, { getSalesReportExport: payload });
    case GET_SALES_REPORT_SITE_VISIT:
      return Object.assign({}, state, { getSalesReportSiteVisit: payload });
    case GET_SALES_REPORT_SITE_VISIT_EXPORT:
      return Object.assign({}, state, {
        getSalesReportSiteVisitExport: payload,
      });
    case GET_SALES_REPORT_FOLLOW_UP:
      return Object.assign({}, state, { getSalesReportFollowUp: payload });
    case GET_SALES_REPORT_FOLLOW_UP_EXPORT:
      return Object.assign({}, state, {
        getSalesReportFollowUpExport: payload,
      });
    case GET_SALES_REPORT_PROSPECTIVE_CLIENT:
      return Object.assign({}, state, {
        getSalesReportProspectiveClient: payload,
      });
    case GET_SALES_REPORT_PROSPECTIVE_CLIENT_EXPORT:
      return Object.assign({}, state, {
        getSalesReportProspectiveClientExport: payload,
      });
    case GET_SALES_REPORT_BOOKING:
      return Object.assign({}, state, { getSalesReportBooking: payload });
    case GET_SALES_REPORT_BOOKING_EXPORT:
      return Object.assign({}, state, { getSalesReportBookingExport: payload });
    case GET_SALES_REPORT_CANCELLATION:
      return Object.assign({}, state, { getSalesReportCancellation: payload });
    case GET_SALES_REPORT_CANCELLATION_EXPORT:
      return Object.assign({}, state, {
        getSalesReportCancellationExport: payload,
      });
    case GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT:
      return Object.assign({}, state, {
        getSalesReportHotProspectiveClient: payload,
      });
    case GET_SALES_REPORT_HOT_PROSPECTIVE_CLIENT_EXPORT:
      return Object.assign({}, state, {
        getSalesReportHotProspectiveClientExport: payload,
      });
    case GET_MONTH_WEEK:
      return Object.assign({}, state, { getMonthWeek: payload });
    case GET_SALES_REPORT_CP_MEETING:
      return Object.assign({}, state, { getSalesReportCPMeeting: payload });
    case GET_SALES_REPORT_CP_MEETING_EXPORT:
      return Object.assign({}, state, {
        getSalesReportCPMeetingExport: payload,
      });
    case GET_SALES_REPORT_CLIENT_MEETING:
      return Object.assign({}, state, { getSalesReportClientMeeting: payload });
    case GET_SALES_REPORT_CLIENT_MEETING_EXPORT:
      return Object.assign({}, state, {
        getSalesReportClientMeetingExport: payload,
      });
    case GET_SALES_REPORT_TEAM:
      return Object.assign({}, state, {
        getSalesReportTeam: payload,
      });
    case GET_SALES_REPORT_TEAM_EXPORT:
      return Object.assign({}, state, {
        getSalesReportTeamExport: payload,
      });
    case GET_SALES_REPORT_FILTER_TEAM:
      return Object.assign({}, state, {
        getSalesReportFilterTeam: payload,
      });
    case GET_SALES_REPORT_FILTER_USER:
      return Object.assign({}, state, {
        getSalesReportFilterUser: payload,
      });
      case GET_SALES_REPORT_PLANNED_FOLLOW_UP:
        return Object.assign({}, state, {
          getSalesReportPlannedFollowUp: payload,
        });
        case GET_SALES_REPORT_PLANNED_FOLLOW_UP_EXPORT:
          return Object.assign({}, state, {
            getSalesReportPlannedFollowUpExport: payload,
          });
          case GET_SALES_REPORT_PLANNED_SITE_VISIT:
            return Object.assign({}, state, {
              getSalesReportPlannedSiteVisit: payload,
            });
            case GET_SALES_REPORT_PLANNED_SITE_VISIT_EXPORT:
              return Object.assign({}, state, {
                getSalesReportPlannedSiteVisitExport: payload,
              });
              case GET_KPI_SALES_REPORT_CRM_BOOKING_DETAIL_FILTER_PROJECT:
                return Object.assign({}, state, {
                  getKPISalesReportCrmBookingDetailFilterProject: payload,
                });
                case GET_KPI_SALES_REPORT_CRM_CANCELLATION_DETAIL_FILTER_PROJECT:
                  return Object.assign({}, state, {
                    getKPISalesReportCrmCancellationDetailFilterProject: payload,
                  });
                  case GET_KPI_SALES_REPORT_SITE_VISIT_DETAIL_FILTER_PROJECT:
                    return Object.assign({}, state, {
                      getKPISalesReportSiteVisitDetailFilterProject: payload,
                    });
    default:
      return state;
  }
};

export default Report_reducer;
