// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {ErpApiService, ErpApi, BaseUrl} from '../../utils/ApiService';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBwu7Kj26VW6wQ_kWqGm9OV_oRRlsHqpHs",
  authDomain: "kns-infra-bd.firebaseapp.com",
  databaseURL: "https://kns-infra-bd.firebaseio.com",
  projectId: "kns-infra-bd",
  storageBucket: "kns-infra-bd.appspot.com",
  messagingSenderId: "473818967397",
  appId: "1:473818967397:web:2c90af6a41eaac0173858c",
  measurementId: "G-2XWSLJP4DP"
};

const firebaseApp = initializeApp(firebaseConfig);
// console.log('firebaseApp', firebaseApp)
const messaging = getMessaging(firebaseApp);
// console.log('messaging', messaging)

export const fetchToken = async(userID) => {
  // console.log('fetchToken::', userID)
    return getToken(messaging, {vapidKey: 'BM-GPTincBUjo6M5VAqdh1uomnTHrsRC_NG8G3GBXErUwnUZVYinCeAfSeLp34G6imC3L_kdAyCzsbspE6UnHKo'}).then(async (currentToken) => {
      if (currentToken) {
        // console.log('current token for client1111: ', currentToken);
        localStorage.setItem('firebaseKey', currentToken);
        if(true){
          await ErpApiService.post(BaseUrl+ "v2/" + ErpApi.UPDATE_FIREBASE_KEY, {userID :userID, firebaseKey : currentToken})
        }
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        // console.log('No registration token available. Request permission to generate one.');
        // shows on the UI that permission is required 
      }
    }).catch((err) => {
      // console.log('An error occurred while retrieving token. ', err);
      // catch error while creating client token
    });
  }
  
  export const onMessageListener = () =>

    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        // console.log("in onMessageListener",payload)
        resolve(payload);
      });
  });